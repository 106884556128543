// import Search from "../assets/search-normal.png";
// import Line from "../assets/Line 1.png";
// import Menu from "../assets/menu.png";
// import NavBarLogo from "../assets/navbar-logo.png";
// import User from "../assets/user.png";
// import { ReactComponent as BoxArrowRight } from "bootstrap-icons/icons/box-arrow-right.svg";
// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import MobileDropdownNavbar from "../../src/components/MobileDropdownNavbar";
// import LogoutButton from "../components/utils/LogoutButton";

// const Navbar = () => {
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
//   const [userEmail, setUserEmail] = useState("");
//   // Default to placeholder image
//   const [userPhoto, setUserPhoto] = useState(User);
//   const navigateTo = useNavigate();

//   // Get user credentials from local storage
//   useEffect(() => {
//     const authData = localStorage.getItem("auth");
//     if (authData) {
//       const { email, photo } = JSON.parse(authData);
//       setUserEmail(email || "");
//       // Use user photo or default
//       setUserPhoto(photo ? photo : User);
//     }
//   }, []);

//   // // Log user out
//   // const logout = () => {
//   //   localStorage.removeItem("auth");
//   //   navigateTo("/");
//   // };

//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   const toggleMobileNav = () => {
//     setIsMobileNavOpen(!isMobileNavOpen);
//   };

//   return (
//     <>
//       <div className="desktop-navbar hidden md:flex h-[30px] items-center  pl-[3%] pr-[8%]  mb-16">
//         <div className="flex items-center mr-[5%] mt-10">
//           <div className="w-[55px] h-[42px] mr-[19px]">
//             <img src={NavBarLogo} alt="Joatech Logo" />
//           </div>
//           <h3 className="gilroy-heavy text-[24px] leading-[28px] tracking-[-0.2px]">
//             Joatech
//           </h3>
//         </div>
//         <div className="navbar-right flex items-center gap-[16px] ml-auto mt-10">
//           <div className="relative">
//             <div
//               className="flex items-center cursor-pointer"
//               onClick={() => toggleDropdown()}
//             >
//               {/* <span className="text-darkGreen3 mr-2">{userEmail}</span> */}
//               <div className="user w-10 h-10">
//                 <img
//                   src={userPhoto}
//                   alt="user icon"
//                   className="w-10 h-10 rounded-full object-cover"
//                 />
//               </div>
//             </div>

//             {/* User Dropdown Card */}
//             <div
//               className={`absolute right-0 mt-2 w-64 rounded-lg shadow-md  ring-black ring-opacity-5 bg-dGrey3 transition-transform transform ${
//                 isDropdownOpen ? "scale-100 opacity-100" : "scale-95 opacity-0"
//               }`}
//               style={{ zIndex: 50 }}
//             >
//               <div className="py-4 flex flex-col items-center">
//                 {/* User Image */}
//                 <img
//                   src={userPhoto}
//                   alt="user"
//                   className="w-16 h-16 rounded-full mb-3 object-cover"
//                 />
//                 {/* User Greeting */}
//                 <p className="text-lg font-semibold text-dash5">Hi,</p>
//                 <p className="text-sm text-gray-700 text-darkGreen3">
//                   {userEmail}
//                 </p>
//               </div>
//               <hr className="my-2 border-grey3" />
//               <div
//                 className="py-2 px-4 flex items-center cursor-pointer hover:bg-gray-100"
//                 // onClick={() => logout()}
//               >
//                 {/* Logout Icon */}
//                 <BoxArrowRight className="mr-3 text-sm  text-dash5" />
//                 <span className="text-lg text-darkGreen3">
//                   {" "}
//                   <LogoutButton />
//                 </span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Mobile Navbar */}
//       <div className="mobile-navbar md:hidden h-[60px] border-b-[1px] border-darkGrey1 flex gap-[16px] py-[13px] px-[24px]">
//         <div className="search-bar flex-1 bg-dGrey2 h-full rounded-[8px] flex justify-between items-center px-[12px]">
//           <input
//             type="text"
//             className="bg-dGrey2 outline-none font-[12px] leading-[19.2px] w-[90%] truncate"
//             placeholder="Search product"
//           />
//           <img src={Search} alt="search icon" />
//         </div>
//         <div className="flex-1 flex justify-between items-center">
//           <img src={Line} alt="line" className="flex justify-end" />
//           <div
//             className="user w-10 h-10 cursor-pointer"
//             onClick={() => toggleMobileNav()}
//           >
//             <img src={Menu} alt="menu icon" />
//           </div>
//         </div>
//       </div>
//       {isMobileNavOpen && <MobileDropdownNavbar />}
//     </>
//   );
// };

// export default Navbar;

import React, { useState, useEffect } from "react";
import Search from "../assets/search-normal.png";
import Line from "../assets/Line 1.png";
import Menu from "../assets/menu.png";
import NavBarLogo from "../assets/navbar-logo.png";
import User from "../assets/user.png";
import { ReactComponent as BoxArrowRight } from "bootstrap-icons/icons/box-arrow-right.svg";
import LogoutButton from "../components/utils/LogoutButton";
import MobileDropdownNavbar from "../../src/components/MobileDropdownNavbar";

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userPhoto, setUserPhoto] = useState(User);

  // Get user credentials from local storage
  useEffect(() => {
    const authData = localStorage.getItem("auth");
    if (authData) {
      const { email, photo } = JSON.parse(authData);
      setUserEmail(email || "");
      setUserPhoto(photo || User);
    }
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <>
      <div className="desktop-navbar hidden md:flex h-[30px] items-center pl-[3%] pr-[8%] mb-16">
        <div className="flex items-center mr-[5%] mt-10">
          <img
            src={NavBarLogo}
            alt="Joatech Logo"
            className="w-[55px] h-[42px] mr-[19px]"
          />
          <h3 className="gilroy-heavy text-[24px] leading-[28px] tracking-[-0.2px]">
            Joatech
          </h3>
        </div>
        <div className="navbar-right flex items-center gap-[16px] ml-auto mt-10">
          <div className="relative">
            <div
              className="flex items-center cursor-pointer"
              onClick={toggleDropdown}
            >
              <div className="user w-10 h-10">
                <img
                  src={userPhoto}
                  alt="user icon"
                  className="w-10 h-10 rounded-full object-cover"
                />
              </div>
            </div>
            {isDropdownOpen && (
              <div
                className="absolute right-0 mt-2 w-64 rounded-lg shadow-md ring-black ring-opacity-5 bg-dGrey3 transition-transform transform scale-100 opacity-100"
                style={{ zIndex: 50 }}
              >
                <div className="py-4 flex flex-col items-center">
                  <img
                    src={userPhoto}
                    alt="user"
                    className="w-16 h-16 rounded-full mb-3 object-cover"
                  />
                  <p className="text-lg font-semibold text-dash5">Hi,</p>
                  <p className="text-sm text-gray-700 text-darkGreen3">
                    {userEmail}
                  </p>
                </div>
                <hr className="my-2 border-grey3" />
                <div className="py-2 px-4 flex items-center cursor-pointer hover:bg-gray-100">
                  <BoxArrowRight className="mr-3 text-sm text-dash5" />
                  <LogoutButton />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mobile-navbar md:hidden h-[60px] border-b-[1px] border-darkGrey1 flex gap-[16px] py-[13px] px-[24px]">
        <div className="search-bar flex-1 bg-dGrey2 h-full rounded-[8px] flex justify-between items-center px-[12px]">
          <input
            type="text"
            className="bg-dGrey2 outline-none font-[12px] leading-[19.2px] w-[90%] truncate"
            placeholder="Search product"
          />
          <img src={Search} alt="search icon" />
        </div>
        <div className="flex-1 flex justify-between items-center">
          <img src={Line} alt="line" className="flex justify-end" />
          <div
            className="user w-10 h-10 cursor-pointer"
            onClick={toggleMobileNav}
          >
            <img src={Menu} alt="menu icon" />
          </div>
        </div>
      </div>
      {isMobileNavOpen && <MobileDropdownNavbar />}
    </>
  );
};

export default Navbar;





import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as ChatLeftTextFill } from "bootstrap-icons/icons/chat-left-text-fill.svg";

import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { handleError } from "../../components/utils/AuthenticationCheck";


import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import CustomStyles from "../../components/utils/CustomStyles";
import formatDate from "../../components/utils/DateFormatter";

const ListHelpCenter = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCustomerType, setSelectedCustomerType] = useState("");

  const navigateTo = useNavigate();

  // ==========================- fetch all customers =================
  useEffect(() => {
    const fetchAllCustomerList = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }
        // console.log("i am token", token);
        const response = await axios.get(ApiServices.getAllTicket, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        // console.log("this is all ticket", response.data);
        const { status, message, data } = response.data;
        if (status === 200) {
          setTickets(data);
          toast.success(message, { toastId: "customer-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchAllCustomerList();
  }, []);
  // ================================== end of list of customer ==========================================

  //filter data by name
  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  //selct user type
  const handleCustomerTypeSelect = (type) => {
    setSelectedCustomerType(type);
    setIsDropdownOpen(false);
  };

  const showTicket = (ticket) => {
    // console.log("this is show ticket", ticket);
    navigateTo("/admin/help/details", { state: { ticket } });
  };

  const updateTicket = (ticket) => {
    // console.log("this is update ticket", ticket);
    navigateTo("/admin/help/update", { state: { ticket } });
  };

  const addMessageToTicket = (ticket) => {
    console.log("ok i am here reply to my message now", ticket);
    navigateTo("/admin/help/message/add", { state: { ticket } });
  };
 
  const columns = [

    {
      name: "Actions",
      cell: (row) => (
        <div className="flex justify-content-start">
          <Link
            onClick={() => showTicket(row)}
            state={{ tickets: row }}
            className="mx-2 my-2 group"
          >
            <Eye className="w-5 h-5 text-darkGreen3" />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Details
            </div>
          </Link>
          <Link

            onClick={() => updateTicket(row)}
            state={{ tickets: row }}
            className="mx-2 my-2 group"
          >
            <PencilSquare className="w-5 h-5 text-dash4" />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Update
            </div>
          </Link>
          <Link

            onClick={() => addMessageToTicket(row)}
            state={{ tickets: row }}
            className="mx-2 my-2 group"
          >
            <ChatLeftTextFill className="w-5 h-5 text-orange1" />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Add Message
            </div>
          </Link>
        </div>
      ),
      width: "150px",
    },

    {
      name: "Name",
      sortable: true,
      width: "200px",
      cell: (row) => (
        <div>
          <span className="block font-bold">
            {row.user_name ? row.user_name.toUpperCase(): "NOT AVAILABLE"}
          </span>
          <span
            className={`px-3 py-0.5 rounded-md text-xs ${row.user_type === "admin"
              ? "bg-orange1 text-dGrey3" // Color for admin
              : row.user_type === "vendor"
                ? "bg-darkGreen3 text-dGrey3" // Color for vendor
                : row.user_type === "user"
                  ? "bg-dash5  text-dGrey3" // Color for user
                  : "bg-orange2 text-dGrey3" // Default color
              }`}
          >
            {row.user_type ? row.user_type : "NOT AVAILABLE"}
          </span>
        </div>
      ),
    },

    {
      name: "Status",
      sortable: true,
      width: "130px",
      cell: (row) => (
        <div>

          <span
            className={`px-3 py-0.5 rounded-md text-xs ${row.status === "open"
              ? "bg-darkGreen3 text-dGrey3" // Color for open status
              : row.status === "pending"
                ? "bg-dash5 text-dGrey3" // Color for pending status
                : row.status === "closed"
                  ? "bg-red1 text-dGrey3" // Color for closed vendor status
                  : "bg-gray-200 text-gray-500" // Default for other statuses
              }`}
          >
            {row.status ? row.status : "NOT AVAILABLE"}
          </span>
        </div>
      ),
    },
    {
      name: "Priority",
      sortable: true,
      width: "110px",
      cell: (row) => (
        <div>

          <span
            className={`px-3 py-0.5 rounded-md text-xs ${row.priority === "high"
              ? "text-red1 " // Color for open status
              : row.priority === "medium"
                ? "text-dash5 " // Color for pending status
                : row.priority === "low"
                  ? "text-darkGreen3"  // Color for closed vendor status
                  : "text-black1" // Default for other statuses
              }`}
          >
            {row.priority ? row.priority : "NOT AVAILABLE"}
          </span>
        </div>
      ),
    },

    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      width: "450px",
    },

  ];



  const filteredItems = tickets.filter((item) => {
    const { user_info } = item; 

    const matchesFilterText =
      user_info.name.toLowerCase().includes(filterText.toLowerCase()) ||
      user_info.email.toLowerCase().includes(filterText.toLowerCase()) ||
      item.description.toLowerCase().includes(filterText.toLowerCase());

    const matchesCustomerType =
      !selectedCustomerType || (user_info.user_type && user_info.user_type.toLowerCase() === selectedCustomerType.toLowerCase());

    return matchesFilterText && matchesCustomerType;
  });


  const data = filteredItems.map((tickets) => ({
    user_name: tickets.user_info?.name || "NOT AVAILABLE",
    user_type: tickets.user_info?.user_type || "NOT AVAILABLE",
    email: tickets.user_info?.email || "NOT AVAILABLE",
    description: tickets.description || "NOT AVAILABLE",
    status: tickets.status || "NOT AVAILABLE",
    ...tickets,
    created_at: tickets.created_at,
  }));



  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className=" sm:rounded-lg py-6 w-full">
          {/* <h1>Customer List</h1> */}
          <section
            className="w-full pl-[3%] pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg ">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Help Center
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1 ">List</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap items-center justify-between gap-4">
            {/* Search Input Field */}
            <div className="relative w-full md:w-auto flex-grow ">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>

            {/* <div className="relative w-full md:w-auto flex-shrink-0">
              <div>
                <Link
                  to="/admin/customer/transactions/history"
                  className="bg-orange1 inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-3 text-sm font-semibold text-lGrey3 "
                >
                  Customer Transaction History
                </Link>
              </div>
            </div> */}
            {/* Select Options Button */}
            <div className="relative w-full md:w-auto flex-shrink-0">
              <div>
                <button
                  type="button"
                  className="bg-darkGreen3 inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-3 text-sm font-semibold text-dGrey3"
                  id="menu-button"
                  aria-expanded={isDropdownOpen}
                  aria-haspopup="true"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  Select User Type
                  <svg
                    className="-mr-1 h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              {isDropdownOpen && (
                <div
                  className="absolute right-0 z-10 mt-2 w-full md:w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-lGrey shadow-lg ring-1 ring-black1 ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                >
                  <div
                    className="py-1 rounded-md"
                    role="none"
                    style={{ backgroundColor: "white" }}
                  >
                    <button
                      onClick={() => handleCustomerTypeSelect("admin")}
                      className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-white hover:text-darkGreen3"
                      role="menuitem"
                    >
                      Administrator
                    </button>
                    <button
                      onClick={() => handleCustomerTypeSelect("vendor")}
                      className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-white hover:text-darkGreen3"
                      role="menuitem"
                    >
                      Vendor
                    </button>
                    <button
                      onClick={() => handleCustomerTypeSelect("user")}
                      className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-white hover:text-darkGreen3"
                      role="menuitem"
                    >
                      End User
                    </button>
                    <div className="relative w-full md:w-auto flex-shrink-0">
                      <button
                        type="button"
                        className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-white hover:text-darkGreen3"
                        onClick={() => handleCustomerTypeSelect("")} // Reset filter
                      >
                        All Users
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              data={sortedData}
              pagination
              highlightOnHover
              noDataComponent={<div>No ticket found for this user type </div>}
              customStyles={CustomStyles}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default ListHelpCenter;


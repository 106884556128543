import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";

const FulfiledList = () => {
  const [fulfiledItems, setFulfiledItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const navigateTo = useNavigate();

  //format date
  const formatDate = (dateString) => {
    // Check if dateString is null or undefined
    if (!dateString) {
      return "Not Available";
    }
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const fetchFulfiledList = async () => {
      setLoading(true);
      try {
        // const authData = JSON.parse(localStorage.getItem("auth"));
        // const accessToken = authData.accessToken;
        const response = await axios.get(
          ApiServices.getFullfilled,
          {},
          {
            headers: {
              // logintoken: accessToken,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        //console.log(response);
        setFulfiledItems(response.data);
        if (response.data.status === "300") {
          toast.error(response.data.message);
          return;
        }
        if (response.data.status === "302") {
          localStorage.removeItem("auth");
          toast.error(response.data.message);
          navigateTo("/");
        }
        if (response.data.status === "400") {
          toast.error(response.data.message);
          navigateTo("/admin/fulfiled");
        }
      } catch (error) {
        console.error("Error fetching categorys list:", error.message);
        // toast.error("Error fetching peoduct list.");
      } finally {
        setLoading(false);
      }
    };

    fetchFulfiledList();
  }, []);

  //==================================== end of delete product =================================================

  //filter data by name
  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const columns = [
    // {
    //   name: "Date",
    //   selector: (row) => formatDate(row.created_at),
    //   sortable: true,
    // },
    {
      name: "Image",
      selector: (row) => {
        //console.log("Image URL:", row.image); // Debugging line
        return (
          <img
            src={row.image || "https://via.placeholder.com/50"}
            alt={row.name}
            style={{
              width: "50px",
              height: "50px",
              marginTop: "7px",
              marginBottom: "7px",
            }}
          />
        );
      },
      sortable: false,
      width: "80px",
    },
    {
      name: "Product Name",
      selector: (row) => (row.name ? row.name.toUpperCase() : ""),
      sortable: true,
      width: "200px",
    },

    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
      width: "100px",
    },
    {
      name: "Created By",
      selector: (row) => row.createdBy,
      sortable: true,
      width: "300px",
    },
    {
      name: "Product Owner",
      selector: (row) => row.productOwner,
      sortable: true,
      width: "200px",
    },
    {
      name: "Sold Date",
      selector: (row) => row.soldDate,
      sortable: true,
      width: "200px",
    },

    // {
    //   name: "Edit",
    //   cell: (row) => (
    //     <div className="d-flex justify-content-end buttons">
    //       <Link
    //         to="/category/edit"
    //         state={{ id: row.id }}
    //         className="btn btn-info btn-sm"
    //         onClick={(e) => {
    //           navigateTo("/category/edit", {
    //             replace: true,
    //             state: { id: row.id },
    //           });
    //         }}
    //         style={{ marginLeft: "5px" }}
    //         data-toggle="tooltip"
    //         data-placement="bottom"
    //         title="Edit category"
    //       >
    //         <PencilSquare className="w-5 h-5 text-dash4 mr-3" />
    //       </Link>
    //     </div>
    //   ),
    //   width: "80px",
    // },
  ];

  const filteredItems = fulfiledItems.filter((item) => {
    for (let key in item) {
      if (
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(filterText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  const data = filteredItems.map((fulfiledItems) => ({
    // created_at: formatDate(categorys.created_at),
    image: fulfiledItems.image,
    // createdBy: category.createdBy ? category.createdBy.toUpperCase() : "",
    name: fulfiledItems.name ? fulfiledItems.name.toUpperCase() : "",
    code: fulfiledItems.code ? fulfiledItems.code.toUpperCase() : "",
    createdBy: fulfiledItems.createdBy
      ? fulfiledItems.createdBy.toUpperCase()
      : "",
    productOwner: fulfiledItems.productOwner
      ? fulfiledItems.productOwner.toUpperCase()
      : "",
    soldDate: fulfiledItems.soldDate,
    id: fulfiledItems.id,
  }));

  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <h1>Fulfilled Items</h1>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
            {/* <Link
              to="/admin/return/items"
              className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className="flex justify-center sm:justify-start mx-2">
                <PlusSquare className="mr-2" />
                Return Items
              </span>
            </Link> */}
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              data={sortedData}
              pagination
              highlightOnHover
              noDataComponent={<div>No fulfiled found</div>}
              customStyles={CustomStyles}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default FulfiledList;

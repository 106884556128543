import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as PlusSquare } from "bootstrap-icons/icons/plus-square.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";
import formatDate from "../../components/utils/DateFormatter";
import { handleError } from "../../components/utils/AuthenticationCheck";

const AdminActLog = () => {
    const [adminLog, setAdminLog] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [confirmationLoading, setConfirmationLoading] = useState(false);

    const navigateTo = useNavigate();

    useEffect(() => {
        const fetchAllAdminActList = async () => {
            setLoading(true);
            try {
                const authData = JSON.parse(localStorage.getItem("auth"));
                const token = authData?.token;
                if (!token) {
                    throw new Error("No access token found.");
                }
                const response = await axios.get(ApiServices.getAdminActivityLog, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                });
                //  console.log(response.data);
                const { status, message, data } = response.data;
                const activities = data.activities;
                // console.log("this is all adminLog", activities);
                if (status === 200 && Array.isArray(activities)) {
                    setAdminLog(activities);
                    toast.success(message, { toastId: "activity-log-fetch-successfully" });
                } else {
                    handleError({ response: { status, data: { message } } }, navigateTo);
                }
            } catch (error) {
                handleError(error, navigateTo);
            } finally {
                setLoading(false);
            }
        };

        fetchAllAdminActList();
    }, []);


    const handleFilter = (e) => {
        const currentFilterText = e.target.value;
        setFilterText(currentFilterText);
        setResetPaginationToggle(!resetPaginationToggle);
        // console.log("Current filter text:", currentFilterText); 
    };


    const columns = [
        {
            name: "Date",
            selector: (row) => (row.created_at ? row.created_at : "NOT AVAILABLE"),
            sortable: true,
            width: "190px",
        },
        {
            name: "Action",
            selector: (row) => (row.action ? row.action : "NOT AVAILABLE"),
            sortable: true,
            width: "400px",
        },
        {
            name: "Status",
            selector: (row) => (row.status ? row.status : "NOT AVAILABLE"),
            sortable: true,
            width: "150px",
        },

    ];

    const filteredItems = Array.isArray(adminLog) ? adminLog.filter((item) => {
        const formattedDate = formatDate(item.created_at).toLowerCase();
        const lowerFilterText = filterText.toLowerCase();
        // console.log("Checking item:", item);
        // console.log("Formatted Date:", formattedDate);
        // console.log("Filtering item:", item); 
        // console.log("Filter Text:", lowerFilterText);
        return (
            (item.action && item.action.toLowerCase().includes(lowerFilterText)) ||
            (item.status && item.status.toLowerCase().includes(lowerFilterText)) ||
            (formattedDate.includes(lowerFilterText))
        );
    }) : [];


    const data = filteredItems.map((adminLog) => ({
        created_at: formatDate(adminLog.created_at),
        action: adminLog.action ? adminLog.action : "NOT AVAILABLE",
        status: adminLog.status ? adminLog.status : "NOT AVAILABLE",
        user_id: adminLog.user_id,
    }));

    const sortedData = [...data].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));


    return (
        <div>
            <MainLayouts>
                <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
                    <section
                        className="w-full  pr-[3%] pt-3 my-3"
                        style={{ backgroundColor: "#F7F7F7" }}
                    >
                        <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                            <ol className="list-none p-0 inline-flex mb-2">
                                <li className="flex items-center">
                                    <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                                        Admin Activity
                                    </p>
                                    <ChevronRightIcon />
                                </li>
                                <li className="flex items-center">
                                    <p className="text-blue-500 hover:text-blue-600 ">List</p>
                                </li>
                            </ol>
                        </nav>
                    </section>
                    <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
                        <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
                            <input
                                type="search"
                                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                placeholder="Search"
                                value={filterText}
                                onChange={handleFilter}
                            />
                        </div>

                    </div>
                    {loading ? (
                        <Spinner loading={loading} />
                    ) : (
                        <DataTable
                            columns={columns}
                            data={sortedData}
                            pagination
                            striped
                            responsive
                            highlightOnHover
                            noDataComponent={<div>No admin activity log found</div>}
                            customStyles={CustomStyles}
                        />
                    )}
                </div>
            </MainLayouts>
        </div>
    );
};

export default AdminActLog;

import React, { useState } from "react";
import Navbar from "../Layout/Navbar";
import Footer from "../Layout/Footer";
import { ReactComponent as ChevronDownIcon } from "bootstrap-icons/icons/chevron-down.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LogoutButton from "../components/utils/LogoutButton";
import PrivateRoute from "../AuthProvider/PrivateRoute";

const SidebarSection = ({ title, links, isOpen, onToggle }) => {
  const location = useLocation();
  

  return (
    <div className="rounded-lg">
      <button
        className="flex justify-between w-full px-4 py-2 text-left bg-gray-200 rounded-t-lg focus:outline-none"
        onClick={onToggle}
      >
        <span className="text-black2">{title}</span>
        <ChevronDownIcon
          className={`transform transition-transform ${isOpen ? "rotate-180" : "rotate-0"
            }`}
        />
      </button>
      {isOpen && (
        <div className="p-4 bg-white rounded-b-lg">
          <ul>
            {links.map(({ to, label }, index) => (
              <li key={`${to}-${index}`}>
                {/* <PrivateRoute> */}
                <Link
                  className={`${location.pathname === to
                    ? "text-darkGreen3 font-bold" // active link style
                    : "text-grey2 hover:text-dash5"
                    } inline-flex items-center w-full mt-1 text-base transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline`}
                  to={to}
                >
                  <span>{label}</span>
                </Link>
                {/* </PrivateRoute> */}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const MainLayouts = ({ children }) => {
  const [openSections, setOpenSections] = useState({
    product: false,
    orders: false,
    settings: false,
    adminManagement: false,
    billsManagement: false,
    accountSettings: false,
    customerManagement: false,
  });

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const location = useLocation()
  const IsDashBoard = location.pathname === '/admin/dashboard'

  const toggleSection = (section) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <>
      <Navbar />
      {/* sidebar here */}
      <div
        className="w-full pl-[3%] pr-[3%] pt-3 mt-25"
        style={{
          backgroundColor: "#F7F7F7",
        }}
      >
        <nav className="bg-gray-200 py-0 px-4 rounded-lg">
          <ol className="list-none p-0 inline-flex">
            <li className="flex items-center">
              <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                Admin
              </p>
              <ChevronRightIcon />
            </li>
            <li className="flex items-center">
              <p className="text-orange1">Dashboard</p>
            </li>
          </ol>
        </nav>
      </div>

      <div
        className="flex overflow-hidden rounded-lg pl-[3%] pr-[3%]"
        style={{ backgroundColor:  "#F7F7F7" }}
      >
        <div className="hidden md:flex md:flex-shrink-0 rounded-2xl border border-grey3 my-4 h-[600px] mb-8">
          <div className="flex flex-col w-64">
            <div className="flex flex-col flex-grow pt-5 overflow-y-auto">
              <div className="flex flex-col flex-grow px-4 mt-2">
                <aside className="flex-1 space-y-1">
                  <p className="block p-2 text-xl font-medium tracking-tighter text-darkGreen3 transition duration-500 ease-in-out transform cursor-pointer hover:text-gray-900 mx-auto mb-3">
                    Dashboard
                  </p>
                  <hr className="border border-lGrey" />
                  <SidebarSection
                    title="Customer "
                    links={[{ to: "/admin/customer", label: "Customers" }]}
                    isOpen={openSections.customerManagement}
                    onToggle={() => toggleSection("customerManagement")}
                  />
                  <hr className="border border-lGrey" />
                  <SidebarSection
                    title="Product"
                    links={[
                      { to: "/admin/products", label: "Vendors Product" },
                      {
                        to: "/admin/products/stock/list",
                        label: "Stock Product",
                      },
                      { to: "/admin/store/list", label: "Vendor Store" },
                      {
                        to: "/admin/product/property/list",
                        label: "Property",
                      },
                      // {
                      //   to: "/admin/product/property/list",
                      //   label: "Property",
                      // },
                      // { to: "/admin/category/list", label: "Categories" },
                    ]}
                    isOpen={openSections.product}
                    onToggle={() => toggleSection("product")}
                  />
                  <hr className="border border-lGrey" />
                  <SidebarSection
                    title="Orders "
                    links={[
                      { to: "/admin/order/list", label: "Orders" },
                      { to: "/admin/return/items", label: "Return" },
                      { to: "/admin/order/fulfiled", label: "Fulfilled" },
                      // { to: "/admin/help/pending", label: "Help Pending" },
                      // { to: "/admin/help/center", label: "Help Center" },
                    ]}
                    isOpen={openSections.orders}
                    onToggle={() => toggleSection("orders")}
                  />
                  <hr className="border border-lGrey" />

                  <SidebarSection
                    title="Admin "
                    links={[
                      {
                        to: "/admin/view/list",
                        label: "Admins",
                      },
                      {
                        to: "/admin/permission/list",
                        label: "Permissions",
                      },
                      { to: "/admin/roles/list", label: "View Roles" },

                      {
                        to: "/admin/vendor/list",
                        label: "Vendor",
                      },

                       { to: "/admin/activity/log", label: "View Activity" },
                    ]}
                    isOpen={openSections.adminManagement}
                    onToggle={() => toggleSection("adminManagement")}
                  />
                  <hr className="border border-lGrey" />
                  <SidebarSection
                    title="Bills "
                    links={[
                      {
                        to: "/admin/product/list",
                        label: "Airtime",
                      },
                      {
                        to: "/admin/billers/list",
                        label: "Billers",
                      },
                      {
                        to: "/admin/switchers/list",
                        label: "Switchers",
                      },
                      {
                        to: "/admin/product/all/list",
                        label: "Products",
                      },
                      {
                        to: "/admin/bills/search",
                        label: "Search Bills ",
                      },
                      // {
                      //   to: "/admin/bill/list",
                      //   label: "Bills Transactions",
                      // },
                      // {
                      //   to: "/admin/bill/list",
                      //   label: "Bills Transactions",
                      // },
                      // { to: "/admin/faq/list", label: "FAQ" },
                      // {
                      //   to: "/admin/password/change",
                      //   label: "Change Password",
                      // },
                    ]}
                    isOpen={openSections.billsManagement}
                    onToggle={() => toggleSection("billsManagement")}
                  />
                  <hr className="border border-lGrey" />
                  <div className="rounded-lg">
                    <SidebarSection
                      title="Settings"
                      links={[
                        { to: "/admin/category/list", label: "Categories" },
                        { to: "/admin/help/list", label: "Help Center" },

                        {
                          to: "/admin/account/2fa/update/status",
                          label: " 2FA Status",
                        },
                        {
                          to: "/admin/account/notification/status",
                          label: "Notification",
                        },
                        {
                          to: "/admin/password/change",
                          label: "Change Password",
                        },
                      ]}
                      isOpen={openSections.accountSettings}
                      onToggle={() => toggleSection("accountSettings")}
                    />
                    <hr className="border border-lGrey" />
                    <div className="rounded-lg mx-4 mt-2">
                      <LogoutButton />
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-1 w-full overflow-hidden mb-8">
          <main className="relative flex-1 overflow-y-auto focus:outline-none">
            <div className="py-4">
              <div
                className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8 ml-[3%] pr-[8%]"
                style={{ backgroundColor: !IsDashBoard ?"white" :''}}
              >
                {/* main content here */}
                <div className="container-fluid">
                  <main>{children}</main>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MainLayouts;

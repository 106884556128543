import React, { useState, useEffect } from "react";
import MainLayouts from "../../../Layout/MainLayouts";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../../components/utils/AuthenticationCheck";
import axios from "axios";
import ApiServices from "../../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import CustomStyles from "../../../components/utils/CustomStyles";
import formatDate from "../../../components/utils/DateFormatter";
import CurrencyFormatter from "../../../components/utils/CurrencyFormatter";
import Spinner from "../../spinner/Spinner";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";

const SearchBills = () => {
    const [loading, setLoading] = useState(false);
    const [filterRequestId, setFilterRequestId] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [tvProducts, setTvProducts] = useState([]);
    const [filteredTvProducts, setFilteredTvProducts] = useState([]);
    const [selectedProductBillsType, setSelectedProductBillsType] =
        useState("tv");

    const [start_date, setStartDate] = useState("");
    const [start_time, setStartTime] = useState("");
    const [end_date, setEndDate] = useState("");
    const [end_time, setEndTime] = useState("");


    const navigateTo = useNavigate();

    const airtimeColumns = [
        {
            name: "Request Id",
            selector: (row) => (row.request_id ? row.request_id : "NOT AVAILABLE"),
            sortable: true,
            width: "auto",
        },
        {
            name: "Phone Number",
            selector: (row) =>
                row.phone_number ? row.phone_number : "NOT AVAIALABLE",
            sortable: true,
            width: "auto",
        },
        {
            name: "Amount",
            selector: (row) => (row.amount ? CurrencyFormatter(row.amount) : "0.00"),
            sortable: true,
            width: "auto",
        },
        {
            name: "Network",
            selector: (row) =>
                row.network ? row.network.toUpperCase() : "NOT AVAILABLE",
            sortable: true,
            width: "auto",
        },
        {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            cell: (row) => {
                // Determine the background color based on the status value
                let backgroundColor;
                let textColor;

                switch (row.status) {
                    case "1":
                        backgroundColor = "bg-darkGreen3"; // Successful
                        textColor = "text-dGrey3";
                        break;
                    case "2":
                        backgroundColor = "bg-dash3"; // Failed
                        textColor = "text-dGrey3";
                        break;
                    case "0":
                    case "3":
                        backgroundColor = "bg-dash5"; // Pending
                        textColor = "text-dGrey3";
                        break;
                    default:
                        backgroundColor = "bg-grey1";
                        textColor = "text-dGrey3";
                }

                return (
                    <div className={`p-1 rounded ${backgroundColor} ${textColor}`}>
                        {row.status === "1"
                            ? "Successful"
                            : row.status === "2"
                                ? "Failed"
                                : "Pending"}
                    </div>
                );
            },
            width: "120px",
        },

        {
            name: "Created At",
            selector: (row) =>
                row.created_at ? formatDate(row.created_at) : "NOT AVAILABLE",
            sortable: true,
            width: "auto",
        },
    ];

    const dataColumns = [
        {
            name: "Request Id",
            selector: (row) => (row.request_id ? row.request_id : "NOT AVAILABLE"),
            sortable: true,
            width: "auto",
        },
        {
            name: "Phone Number",
            selector: (row) =>
                row.phone_number ? row.phone_number : "NOT AVAIALABLE",
            sortable: true,
            width: "auto",
        },
        {
            name: "Amount",
            selector: (row) => (row.amount ? CurrencyFormatter(row.amount) : "0.00"),
            sortable: true,
            width: "auto",
        },
        {
            name: "Network",
            selector: (row) =>
                row.network ? row.network.toUpperCase() : "NOT AVAILABLE",
            sortable: true,
            width: "auto",
        },
        {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            cell: (row) => {
                // Determine the background color based on the status value
                let backgroundColor;
                let textColor;

                switch (row.status) {
                    case "1":
                        backgroundColor = "bg-darkGreen3"; // Successful
                        textColor = "text-dGrey3";
                        break;
                    case "2":
                        backgroundColor = "bg-dash3"; // Failed
                        textColor = "text-dGrey3";
                        break;
                    case "0":
                    case "3":
                        backgroundColor = "bg-dash5"; // Pending
                        textColor = "text-dGrey3";
                        break;
                    default:
                        backgroundColor = "bg-grey1";
                        textColor = "text-dGrey3";
                }

                return (
                    <div className={`p-1 rounded ${backgroundColor} ${textColor}`}>
                        {row.status === "1"
                            ? "Successful"
                            : row.status === "2"
                                ? "Failed"
                                : "Pending"}
                    </div>
                );
            },
            width: "120px",
        },

        {
            name: "Created At",
            selector: (row) =>
                row.created_at ? formatDate(row.created_at) : "NOT AVAILABLE",
            sortable: true,
            width: "auto",
        },
    ];

    const discoColumns = [
        {
            name: "Request Id",
            selector: (row) => (row.request_id ? row.request_id : "NOT AVAILABLE"),
            sortable: true,
            width: "auto",
        },
        {
            name: "Customer Name",
            selector: (row) => (row.name ? row.name.toUpperCase() : "NOT AVAIALABLE"),
            sortable: true,
            width: "auto",
        },
        {
            name: "Meter Number",
            selector: (row) => (row.meterNo ? row.meterNo : "NOT AVAIALABLE"),
            sortable: true,
            width: "auto",
        },
        {
            name: "Type",
            selector: (row) => (row.type ? row.type.toUpperCase() : "NOT AVAILABLE"),
            sortable: true,
            width: "auto",
        },
        {
            name: "Amount",
            selector: (row) => (row.amount ? CurrencyFormatter(row.amount) : "0.00"),
            sortable: true,
            width: "auto",
        },

        {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            cell: (row) => {
                // Determine the background color based on the status value
                let backgroundColor;
                let textColor;

                switch (row.status) {
                    case "1":
                        backgroundColor = "bg-darkGreen3"; // Successful
                        textColor = "text-dGrey3";
                        break;
                    case "2":
                        backgroundColor = "bg-dash3"; // Failed
                        textColor = "text-dGrey3";
                        break;
                    case "0":
                    case "3":
                        backgroundColor = "bg-dash5"; // Pending
                        textColor = "text-dGrey3";
                        break;
                    default:
                        backgroundColor = "bg-grey1";
                        textColor = "text-dGrey3";
                }

                return (
                    <div className={`p-1 rounded ${backgroundColor} ${textColor}`}>
                        {row.status === "1"
                            ? "Successful"
                            : row.status === "2"
                                ? "Failed"
                                : "Pending"}
                    </div>
                );
            },
            width: "120px",
        },

        {
            name: "Created At",
            selector: (row) =>
                row.created_at ? formatDate(row.created_at) : "NOT AVAILABLE",
            sortable: true,
        },
    ];

    const tvColumns = [
        {
            name: "Request Id",
            selector: (row) => (row.request_id ? row.request_id : "NOT AVAILABLE"),
            sortable: true,
            // width: "200px",
            width: "auto",
        },
        {
            name: "Customer Name",
            selector: (row) =>
                row.customerName ? row.customerName.toUpperCase() : "NOT AVAIALABLE",
            sortable: true,
            width: "auto",
        },
        {
            name: "Smart Card No",
            selector: (row) =>
                row.smartcardNumber ? row.smartcardNumber : "NOT AVAIALABLE",
            sortable: true,
            width: "auto",
        },
        {
            name: "Type",
            selector: (row) => (row.type ? row.type.toUpperCase() : "NOT AVAILABLE"),
            sortable: true,
            width: "auto",
        },
        {
            name: "Amount",
            selector: (row) => (row.amount ? CurrencyFormatter(row.amount) : "0.00"),
            sortable: true,
            width: "auto",
        },

        {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            cell: (row) => {
                // Determine the background color based on the status value
                let backgroundColor;
                let textColor;

                switch (row.status) {
                    case "1":
                        backgroundColor = "bg-darkGreen3"; // Successful
                        textColor = "text-dGrey3";
                        break;
                    case "2":
                        backgroundColor = "bg-dash3"; // Failed
                        textColor = "text-dGrey3";
                        break;
                    case "0":
                    case "3":
                        backgroundColor = "bg-dash5"; // Pending
                        textColor = "text-dGrey3";
                        break;
                    default:
                        backgroundColor = "bg-grey1";
                        textColor = "text-dGrey3";
                }

                return (
                    <div className={`p-1 rounded ${backgroundColor} ${textColor}`}>
                        {row.status === "1"
                            ? "Successful"
                            : row.status === "2"
                                ? "Failed"
                                : "Pending"}
                    </div>
                );
            },
            width: "120px",
        },

        {
            name: "Created At",
            selector: (row) =>
                row.created_at ? formatDate(row.created_at) : "NOT AVAILABLE",
            sortable: true,
            width: "auto",
        },
    ];

    const fetchBillProductList = async () => {
        setLoading(true);

        const formattedStartDateTime =
            start_date && start_time
                ? new Date(`${start_date}T${start_time}`)
                    .toISOString()
                    .slice(0, 19)
                    .replace("T", " ")
                : undefined;

        const formattedEndDateTime =
            end_date && end_time
                ? new Date(`${end_date}T${end_time}`)
                    .toISOString()
                    .slice(0, 19)
                    .replace("T", " ")
                : undefined;

        const formData = {
            product: selectedProductBillsType,
            request_id: filterRequestId || undefined,
            status: filterStatus || undefined,
            start_date: formattedStartDateTime,
            end_date: formattedEndDateTime,
        };

        // console.log("Fetching with payload:", formData);

        try {
            const authData = JSON.parse(localStorage.getItem("auth"));
            const token = authData?.token;
            const actualToken = token.split("|")[1];

            const response = await axios.post(ApiServices.searchBills, formData, {
                headers: {
                    Authorization: `Bearer ${actualToken}`,
                    token: token,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });

            const { status, message, data } = response.data;
            if (status === 200) {
                setTvProducts(data);
                toast.success(message);
            } else {
                handleError({ response: { status, data: { message } } }, navigateTo);
            }
        } catch (error) {
            handleError(error, navigateTo);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            new Date(`${start_date}T${start_time}`) >
            new Date(`${end_date}T${end_time}`)
        ) {
            toast.error("Start date must be before end date");
            return;
        }

        await fetchBillProductList();
    };

    useEffect(() => {
        const filteredProducts = tvProducts.filter((product) => {
          const matchesRequestId = filterRequestId
            ? product.request_id.includes(filterRequestId)
            : true;
          const matchesStatus = filterStatus
            ? filterStatus === "0" || filterStatus === "3" 
              ? product.status === "0" || product.status === "3" 
              : product.status.toString() === filterStatus 
            : true; 

          return matchesRequestId && matchesStatus;
        });

        setFilteredTvProducts(filteredProducts);
    }, [filterRequestId, filterStatus, tvProducts]);

    const getColumns = () => {
        switch (selectedProductBillsType) {
            case "airtime":
                return airtimeColumns;
            case "data":
                return dataColumns; // Ensure these arrays are filled
            case "disco":
                return discoColumns; // Ensure these arrays are filled
            case "tv":
                return tvColumns; // Ensure these arrays are filled
            default:
                return [];
        }
    };


    

    return (
      <div>
        <MainLayouts>
          <div className="relative overflow-x-auto sm:rounded-lg py-3 w-full">
            <section
              className="w-full pr-[3%] pt-3 my-3"
              style={{ backgroundColor: "#F7F7F7" }}
            >
              <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                <ol className="list-none p-0 inline-flex mb-2">
                  <li className="flex items-center">
                    <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                      Bills
                    </p>
                    <ChevronRightIcon />
                  </li>
                  <li className="flex items-center">
                    <p className="text-orange1"> Search Bills</p>
                  </li>
                </ol>
              </nav>
            </section>
          </div>
          <div className="relative overflow-x-auto sm:rounded-lg py-2 w-full">
            <section className="gray_background">
              <div className="container px-1 pb-2 mx-auto">
                <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                  <form
                    onSubmit={handleSubmit}
                    className="grid grid-cols-1 sm:grid-cols-5 gap-6 items-end"
                  >
                    <div className="w-full mb-2">
                      <label
                        htmlFor="product"
                        className="block mb-1 text-sm font-medium"
                      >
                        Product
                      </label>
                      <select
                        value={selectedProductBillsType}
                        onChange={(e) =>
                          setSelectedProductBillsType(e.target.value)
                        }
                        className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                      >
                        <option value="" disabled>
                          Select Product
                        </option>
                        <option value="airtime">Airtime</option>
                        <option value="data">Data</option>
                        <option value="disco">Disco</option>
                        <option value="tv">TV</option>
                      </select>
                    </div>
                    <div className="w-full mb-1">
                      <label
                        htmlFor="start_datetime"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Start Date & Time
                      </label>
                      <input
                        type="datetime-local"
                        name="start_datetime"
                        id="start_datetime"
                        value={
                          start_date && start_time
                            ? `${start_date}T${start_time}`
                            : ""
                        }
                        onChange={(e) => {
                          const [date, time] = e.target.value.split("T");
                          setStartDate(date);
                          setStartTime(time);
                        }}
                        className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                      />
                    </div>
                    <div className="w-full mb-1">
                      <label
                        htmlFor="end_datetime"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        End Date & Time
                      </label>
                      <input
                        type="datetime-local"
                        name="end_datetime"
                        id="end_datetime"
                        value={
                          end_date && end_time ? `${end_date}T${end_time}` : ""
                        }
                        onChange={(e) => {
                          const [date, time] = e.target.value.split("T");
                          setEndDate(date);
                          setEndTime(time);
                        }}
                        className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                      />
                    </div>
                    <div className="w-full mb-2">
                      <label
                        htmlFor="request_id"
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Request ID
                      </label>
                      <input
                        type="text"
                        id="request_id"
                        value={filterRequestId}
                        onChange={(e) => setFilterRequestId(e.target.value)}
                        className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        placeholder="Enter Request ID"
                      />
                    </div>
                    <div className="w-full mb-2">
                      <label
                        htmlFor="status"
                        className="block mb-1 text-sm font-medium"
                      >
                        Status
                      </label>

                      <select
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                        className="border border-dGrey1 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                      >
                        <option value="" disabled>
                          Select Status
                        </option>
                        <option value="0">Pending</option>
                        <option value="1">Successful</option>
                        <option value="2">Failed</option>
                      </select>
                    </div>
                    <div className="col-span-full mt-4">
                      <button
                        type="submit"
                        className="w-full bg-darkGreen3 text-dGrey1 rounded-lg py-2"
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </section>

            {loading ? (
              <Spinner loading={loading} />
            ) : (
              <DataTable
                columns={getColumns()}
                data={filteredTvProducts}
                customStyles={CustomStyles}
                progressPending={loading}
                pagination
                striped
                responsive
                highlightOnHover
                noDataComponent="No Products Found"
              />
            )}
          </div>
        </MainLayouts>
      </div>
    );
};

export default SearchBills;

import React, { useEffect, useState } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import Spinner from "../spinner/Spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";

import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { handleError } from "../../components/utils/AuthenticationCheck";


const HelpCenterUpdate = () => {
    const [ticket_id, setTicketId] = useState("");
    const [description, setDescription] = useState("");
    const [user_type, setUserType] = useState("");
    const [create_at, setCreatedAt] = useState("");
    const [status, setStatus] = useState("");
    const [priority, setPriority] = useState("");
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);

    const navigateTo = useNavigate();
    const location = useLocation();
    const tickets = location.state.tickets;

    useEffect(() => {
        if (tickets) {
            console.log("this is the ticket details i want to update", tickets);
            setTicketId(tickets.ticket_id);
            setDescription(tickets.description);
            setUserType(tickets.user_type);
            setCreatedAt(tickets.created_at);
            setStatus(tickets.status);
            setPriority(tickets.priority);
            setMessages(tickets.messages || []); // Set messages from the ticket
            setLoading(false);
        } else {
            console.error("No ticket data available");
            setLoading(false);
        }
    }, [tickets]);

    //api call here
    const handleSubmit = async (e) => {
        e.preventDefault();

        const validateForm = (priority = "") => {
            if (!priority && !status) {
                toast.error("At least one field must be filled out.");
                return false;
            }
            return true;
        };

        if (!validateForm()) return;

        setLoading(true);



        const formData = {
            ticket_id,
            priority,
            status,
        };
        console.log("form data for help center ticket update", formData);
        try {
            const authData = JSON.parse(localStorage.getItem("auth"));
            const token = authData?.token;
            if (!token) {
                throw new Error("No access token found.");
            }

            const response = await axios.put(ApiServices.updateTicket, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });

            const { status, message } = response.data;
            if (status === 200) {
                navigateTo("/admin/help/list");
                toast.success(message);
                setStatus("");
                setPriority("");

            } else {
                handleError({ response: { status, data: { message } } }, navigateTo);
            }
        } catch (error) {
            handleError(error, navigateTo);
        } finally {
            setLoading(false);
        }
    };

    return (
        <MainLayouts>
            <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
                {loading ? (
                    <Spinner loading={loading} />
                ) : (
                    <section className="text-gray-600 body-font">
                        <section
                            className="w-full pl-[3%] pr-[3%] pt-3 my-3"
                            style={{ backgroundColor: "#F7F7F7" }}
                        >
                            <nav className="bg-gray-200 py-0 px-4 rounded-lg ">
                                <ol className="list-none p-0 inline-flex mb-2">
                                    <li className="flex items-center">
                                        <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                                            Help Center
                                        </p>
                                        <ChevronRightIcon />
                                    </li>
                                    <li className="flex items-center">
                                        <p className="text-orange1 ">Update</p>
                                    </li>
                                </ol>
                            </nav>
                        </section>
                        <div className="container px-1 py-1 mx-auto">
                            <div className="flex flex-wrap -m-2">
                                <div className="p-2 w-full">
                                    <div className="h-full flex border-grey3 border items-center p-4 rounded-lg">
                                        <div className="flex-grow">
                                            <h2 className="text-dash title-font font-medium">
                                                {tickets.user_info?.name || "Not Available"}
                                            </h2>
                                            <p className="text-gray-500">
                                                <span className="text-dash">User Type: </span>
                                                {tickets.user_info?.user_type || "Not Available"}
                                            </p>
                                            <p className="text-gray-500">
                                                <span className="text-dash">Email: </span>
                                                {tickets.user_info?.email || "Not Available"}
                                            </p>
                                            <p className="text-gray-500">
                                                <span className="text-dash">Description: </span>
                                                {tickets.description || "Not Available"}
                                            </p>
                                            <p className="text-gray-500">
                                                <span className="text-dash">Status: </span>
                                                {tickets.status || "Not Available"}
                                            </p>
                                            <p className="text-black1">
                                                <span className="text-dash">Date: </span>
                                                {tickets.created_at
                                                    ? new Date(tickets.created_at).toLocaleDateString('en-US', {
                                                        weekday: 'long',
                                                        year: 'numeric',
                                                        month: 'short',
                                                        day: 'numeric',
                                                    })
                                                    : "Not Available"}
                                            </p>
                                        </div>
                                        <Link
                                            to="/admin/help/list"
                                            className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                        >
                                            <span className="flex justify-center sm:justify-start mx-2">
                                                <ArrowLeftSquare className="mr-2" />
                                                Back
                                            </span>
                                        </Link>
                                    </div>
                                </div>

                                <div className="px-2 w-full">
                                    <section className="gray_background">
                                        <div className="mx-auto">
                                            <div className="lg:w-full md:w-full bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                                                <form onSubmit={handleSubmit}>
                                                    <input
                                                        type="hidden"
                                                        name="ticket_id"
                                                        value={ticket_id}
                                                    />
                                                    <div className="mb-5">
                                                        <label
                                                            htmlFor="email"
                                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                        >
                                                            Priority
                                                        </label>
                                                        <select
                                                            id="priority"
                                                            value={priority}
                                                            onChange={(e) => setPriority(e.target.value)}
                                                            className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                        >
                                                            <option value="" disabled>
                                                                Select priority
                                                            </option>
                                                            <option value="low">Low</option>
                                                            <option value="high">High</option>
                                                        </select>
                                                    </div>
                                                    <div className="w-full mb-4">
                                                        <label
                                                            htmlFor="status"
                                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                        >
                                                            Status
                                                        </label>
                                                        <select
                                                            id="status"
                                                            value={status}
                                                            onChange={(e) => setStatus(e.target.value)}
                                                            className="bg-gray-50 border border-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                        >
                                                            <option value="" disabled>
                                                                Select status
                                                            </option>
                                                            <option value="open">Open</option>
                                                            <option value="closed">Closed</option>
                                                        </select>
                                                    </div>

                                                    <button
                                                        type="submit"
                                                        className={` mb-3 text-white bg-darkGreen3 text-dGrey3 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded font-medium w-full my-1 ${loading ? "btn-loading" : "btn-primary"
                                                            }`}
                                                        disabled={loading}
                                                    >
                                                        {loading ? "Loading..." : "Update Ticket"}
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </div>
        </MainLayouts>
    );
};

export default HelpCenterUpdate;

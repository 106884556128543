// import React, { useState, useEffect } from "react";
// import MainLayouts from "../../../Layout/MainLayouts";
// import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
// import Spinner from "../../spinner/Spinner";
// import { handleError } from "../../../components/utils/AuthenticationCheck";
// import axios from "axios";
// import ApiServices from "../../../api/ApiServices";
// import DataTable from "react-data-table-component";
// import CustomStyles from "../../../components/utils/CustomStyles";
// import { useNavigate } from "react-router-dom";

// const Switcher = () => {
//     const [products, setProducts] = useState([]);
//     const [switches, setSwitches] = useState([]);
//     const [billers, setBillers] = useState([]); 
//     const [loading, setLoading] = useState(true);
//     const [filterText, setFilterText] = useState("");
//     const navigateTo = useNavigate();

//     // Fetch products and switches
//     useEffect(() => {
//         const fetchData = async () => {
//             setLoading(true);
//             try {
//                 const authData = JSON.parse(localStorage.getItem("auth"));
//                 const token = authData?.token?.split("|")[1];
//                 if (!token) throw new Error("No access token found.");

//                 const response = await axios.post(ApiServices.getSwitchers, {}, {
//                     headers: {
//                         Authorization: `Bearer ${token}`,
//                         "Content-Type": "application/json",
//                     },
//                     withCredentials: true,
//                 });

//                 const { status, data } = response.data;
//                 if (status === 200) {
//                     setProducts(data.products);
//                     // Set switches from the response
//                     //ned to ask mr isaac something here about the endpoint i shuld use for tghe billers
//                     // setSwitches(data.switches); 
//                 } else {
//                     handleError({ response: { status, message: data.message } }, navigateTo);
//                 }
//             } catch (error) {
//                 handleError(error, navigateTo);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchData();
//     }, []);



//     //het all biller here
//     useEffect(() => {
//         const fetchBilerList = async () => {
//             setLoading(true);
//             try {
//                 const authData = JSON.parse(localStorage.getItem("auth"));
//                 const token = authData?.token;
//                 if (!token) {
//                     throw new Error("No access token found.");
//                 }
//                 // console.log("i am token", token);
//                 // Extract the actual token part
//                 const actualToken = token.split("|")[1];

//                 const response = await axios.post(
//                     ApiServices.getBillers,
//                     {},
//                     {
//                         headers: {
//                             Authorization: `Bearer ${actualToken}`,
//                             token: token,
//                             "Content-Type": "application/json",
//                         },
//                         withCredentials: true,
//                     }
//                 );

//                 const { status, message, data } = response.data;
//                 // console.log("this is all billers", response.data);
//                 // setBillers(data);
//                 if (status === 200) {
//                     setBillers(data);
//                     // toast.success(message, { toastId: "billers-fetch-success" });
//                 } else {
//                     handleError({ response: { status, data: { message } } }, navigateTo);
//                 }
//             } catch (error) {
//                 handleError(error, navigateTo);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchBilerList();
//     }, []);

//     // Filter products by name
//     const handleFilter = (e) => {
//         setFilterText(e.target.value);
//     };


//     const uniqueSwitches = Array.from(new Set(switches.map(switchItem => switchItem.billerId)))
//         .map(id => switches.find(switchItem => switchItem.billerId === id));

//     // Columns definition
//     const columns = [
//         {
//             name: "Product Name",
//             selector: (row) => (row.name ? row.name.toUpperCase() : "NOT AVAILABLE"),
//             sortable: true,
//             width: "300px",
//         },
//         {
//             name: "Current Biller",
//             selector: (row) => (row.biller ? row.biller.toUpperCase() : "NOT AVAILABLE"),
//             sortable: true,
//             width: "300px",
//         },
//         {
//             name: "Switch To Available Biller",
//             cell: (row) => (
//                 <div className="flex flex-col">
//                     <select
//                         className="mx-2 my-2 px-6 py-2 text-black1 border rounded-md bg-dGrey3"
//                         defaultValue={row.biller_id} // Set the default value to the current biller
//                     // onChange={(e) => handleBillerChange(row.id, e.target.value)} // Uncomment to handle change
//                     >
//                         {uniqueSwitches.map(switchItem => (
//                             <option key={switchItem.billerId} value={switchItem.billerId}>
//                                 {switchItem.biller}
//                             </option>
//                         ))}
//                     </select>
//                     <button
//                         type="button"
//                         className={`text-dGrey3 bg-darkGreen3 border-0 py-2 px-6 mb-3 focus:outline-none hover:bg-indigo-600 rounded font-medium ${loading ? "btn-loading" : "btn-primary"}`}
//                         disabled={loading}
//                     >
//                         {loading ? "Loading..." : "Switch"}
//                     </button>
//                 </div>
//             ),
//             width: "400px",
//         },
//     ];

//     // Filtered and sorted data
//     const filteredItems = products.filter((item) =>
//         Object.values(item).some((value) =>
//             typeof value === "string" && value.toLowerCase().includes(filterText.toLowerCase())
//         )
//     );

//     const data = filteredItems.map((product) => ({
//         name: product.name || "NOT AVAILABLE",
//         biller: product.biller || "NOT AVAILABLE", // Current biller from the product
//         biller_id: product.biller_id, // Use this for dropdown's default value
//         id: product.id,
//     }));

//     return (
//         <MainLayouts>
//             <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
//                 <section className="w-full pr-[3%] pt-3 my-3" style={{ backgroundColor: "#F7F7F7" }}>
//                     <nav className="bg-gray-200 py-0 px-4 rounded-lg">
//                         <ol className="list-none p-0 inline-flex mb-2">
//                             <li className="flex items-center">
//                                 <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">Switchers</p>
//                                 <ChevronRightIcon />
//                             </li>
//                             <li className="flex items-center">
//                                 <p className="text-orange1">All</p>
//                             </li>
//                         </ol>
//                     </nav>
//                 </section>
//                 <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
//                     <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
//                         <input
//                             type="search"
//                             className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
//                             placeholder="Search"
//                             value={filterText}
//                             onChange={handleFilter}
//                         />
//                     </div>
//                 </div>
//                 {loading ? (
//                     <Spinner loading={loading} />
//                 ) : (
//                     <DataTable
//                         columns={columns}
//                         data={data}
//                         pagination
//                         striped
//                         responsive
//                         highlightOnHover
//                         noDataComponent={<div>No products found</div>}
//                         customStyles={CustomStyles}
//                     />
//                 )}
//             </div>
//         </MainLayouts>
//     );
// };

// export default Switcher;

import React, { useState, useEffect } from "react";
import MainLayouts from "../../../Layout/MainLayouts";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../../spinner/Spinner";
import { handleError } from "../../../components/utils/AuthenticationCheck";
import axios from "axios";
import { toast } from "react-toastify";
import ApiServices from "../../../api/ApiServices";
import DataTable from "react-data-table-component";
import CustomStyles from "../../../components/utils/CustomStyles";
import { useNavigate } from "react-router-dom";

const Switcher = () => {
    const [products, setProducts] = useState([]);
    const [billers, setBillers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterText, setFilterText] = useState("");
    const [switchLoading, setSwitchLoading] = useState({});
    const [switchError, setSwitchError] = useState(null);
    const [switchSuccess, setSwitchSuccess] = useState(null);
    const navigateTo = useNavigate();
    const [selectedBillers, setSelectedBillers] = useState({});

    const fetchData = async () => {
        setLoading(true);
        try {
            const authData = JSON.parse(localStorage.getItem("auth"));
            const token = authData?.token?.split("|")[1];
            if (!token) throw new Error("No access token found.");

            // Fetch products
            const productsResponse = await axios.post(ApiServices.getSwitchers, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });

            if (productsResponse.data.status === 200) {
                setProducts(productsResponse.data.data.products);
            } else {
                handleError(productsResponse.data, navigateTo);
            }

            // Fetch all billers
            const billersResponse = await axios.post(ApiServices.getBillers, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });

            if (billersResponse.data.status === 200) {
                setBillers(billersResponse.data.data);
            } else {
                handleError(billersResponse.data, navigateTo);
            }
        } catch (error) {
            handleError(error, navigateTo);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [navigateTo]);

    const handleSwitch = async (productId) => {
        const selectedBillerId = selectedBillers[productId];
        if (!selectedBillerId) {
            setSwitchError("Please select a biller.");
            return;
        }

        setSwitchLoading(prev => ({ ...prev, [productId]: true }));
        setSwitchError(null);
        setSwitchSuccess(null);
        try {
            const authData = JSON.parse(localStorage.getItem("auth"));
            const token = authData?.token?.split("|")[1];
            if (!token) throw new Error("No access token found.");

            const response = await axios.post(ApiServices.switch, {
                id: productId,
                biller_id: selectedBillerId,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            });

            if (response.data.status === 200) {
                setSwitchSuccess(toast.success(response.data.message));
                await fetchData(); // Now this is accessible
            } else {
                throw new Error(toast.error(response.data.message));
            }
        } catch (error) {
            setSwitchError(error.message || "An error occurred while switching.");
        } finally {
            setSwitchLoading(false);
        }
    };

    const handleFilter = (e) => {
        setFilterText(e.target.value);
    };

    //get unique id  even if biller ename show more than one
    const uniqueBillers = Array.from(new Set(billers.map(biller => biller.id)))
        .map(id => billers.find(biller => biller.id === id));

    const columns = [
        {
            name: "Product Name",
            selector: (row) => (row.name ? row.name.toUpperCase() : "NOT AVAILABLE"),
            sortable: true,
            width: "300px",
        },
        {
            name: "Current Biller",
            selector: (row) => (row.biller ? row.biller.toUpperCase() : "NOT AVAILABLE"),
            sortable: true,
            width: "300px",
        },
        {
            name: "Switch To Available Biller",
            cell: (row) => (
                <div className="flex flex-col">
                    <select
                        className="mx-2 my-2 px-6 py-2 text-black1 border rounded-md bg-dGrey3"
                        defaultValue={row.biller_id}
                        onChange={(e) => {
                            setSelectedBillers(prev => ({
                                ...prev,
                                [row.id]: e.target.value
                            }));
                        }}
                    >
                        {uniqueBillers.map(biller => (
                            <option key={biller.id} value={biller.id}>
                                {biller.name}
                            </option>
                        ))}
                    </select>
                    <button
                        type="button"
                        className={`text-dGrey3 bg-darkGreen3 border-0 py-2 px-6 mb-3 focus:outline-none hover:bg-indigo-600 rounded font-medium ${switchLoading[row.id] ? "btn-loading" : "btn-primary"}`}
                        onClick={() => handleSwitch(row.id)}
                        disabled={switchLoading[row.id]} // Disable if loading for this specific product
                    >
                        {switchLoading[row.id] ? "Switching..." : "Switch"}
                    </button>
                    {/* {switchError && <p className="text-red1">{switchError}</p>}
                    {switchSuccess && <p className="text-darkGreen3">{switchSuccess}</p>} */}
                </div>
            ),
            width: "400px",
        },
    ];

    const filteredItems = products.filter((item) =>
        Object.values(item).some((value) =>
            typeof value === "string" && value.toLowerCase().includes(filterText.toLowerCase())
        )
    );

    const data = filteredItems.map((product) => ({
        name: product.name || "NOT AVAILABLE",
        biller: product.biller || "NOT AVAILABLE",
        biller_id: product.biller_id,
        id: product.id,
    }));

    return (
        <MainLayouts>
            <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
                <section className="w-full pr-[3%] pt-3 my-3" style={{ backgroundColor: "#F7F7F7" }}>
                    <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                        <ol className="list-none p-0 inline-flex mb-2">
                            <li className="flex items-center">
                                <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">Switchers</p>
                                <ChevronRightIcon />
                            </li>
                            <li className="flex items-center">
                                <p className="text-orange1">All</p>
                            </li>
                        </ol>
                    </nav>
                </section>
                <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
                    <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
                        <input
                            type="search"
                            className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            placeholder="Search"
                            value={filterText}
                            onChange={handleFilter}
                        />
                    </div>
                </div>
                {loading ? (
                    <Spinner loading={loading} />
                ) : (
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        striped
                        responsive
                        highlightOnHover
                        noDataComponent={<div>No products found</div>}
                        customStyles={CustomStyles}
                    />
                )}
            </div>
        </MainLayouts>
    );
};

export default Switcher;


// import React, { useState,useEffect } from "react";
// import MainLayouts from "../../Layout/MainLayouts";
// import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
// import axios from "axios";
// import ApiServices from "../../api/ApiServices";
// import { toast } from "react-toastify";
// import CreateProductForm from "./productComponet/CreateProductForm";
// import CreateProductStockForm from "./productComponet/CreateProductStockForm";
// import AddImagesForm from "./productComponet/AddImagesForm";
// import { useNavigate } from "react-router-dom";

// const AddProductStock = () => {
//     const [step, setStep] = useState(1);
//     const [formData, setFormData] = useState({
//         is_serialized: true,
//         name: "",
//         description: "",
//         category_id: "",
//         sub_category_id: "",
//         product_category_id: "",
//         store_id: "",
//         price: "",
//         batch_number: "",
//         stock_id: null,
//         images: [],
//         product_id: "",
//         product_category: null,
//     });
//     const [loading, setLoading] = useState(false);
//     const navigateTo = useNavigate();

//     // Log formData every time it changes
//     useEffect(() => {
//         console.log("Updated form data:", formData);
//     }, [formData]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         if (name === "price") {
//             setFormData(prev => ({ ...prev, [name]: value === "" ? "" : parseFloat(value) || "" }));
//         } else if (name === "batch_number") {
//             setFormData(prev => ({ ...prev, [name]: value === "" ? undefined : parseInt(value, 10) }));
//         } else {
//             setFormData(prev => ({ ...prev, [name]: value }));
//         }
//         // console.log("Updated form data:", formData);
//     };

//     const handleNext = async () => {
//         console.log("Form Data before step submission:", formData);
//         try {
//             setLoading(true);
//             if (step === 1) {
//                 await handleProductCreation();
//             } else if (step === 2) {
//                 await handleStockCreation();
//             } else if (step === 3) {
//                 await handleImageAddition();
//             }
//         } catch (error) {
//             toast.error(error.response?.data?.message || 'An error occurred while processing.');
//         } finally {
//             console.log("Loading ended.");
//             setLoading(false);
//         }
//     };

//     const handleApiCall = async (url, data) => {
//         const authData = JSON.parse(localStorage.getItem("auth"));
//         const token = authData?.token;
//         return await axios.post(url, data, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 "Content-Type": "application/json",
//             },
//         });
//     };

//     const handleProductCreation = async () => {
//         const productData = {
//             is_serialized: formData.is_serialized,
//             category_id: formData.category_id,
//             sub_category_id: formData.sub_category_id,
//             product_category_id: formData.product_category_id,
//             name: formData.name,
//             description: formData.description,
//         };
//         console.log("Step 1 - Product Creation Data:", productData);

//         // Check if necessary fields are filled
//         if (!formData.name || !formData.category_id || !formData.sub_category_id || !formData.product_category_id) {
//             toast.error("Please fill in all required fields.");
//             return;
//         }

//         try {
//             const response = await handleApiCall(ApiServices.addProducts, productData);
//             if (response.status === 200) {
//                 toast.success('Product created successfully!');
//                 setFormData(prev => ({ ...prev, product_id: response.data.product_id }));
//                 setStep(2);
//             } else {
//                 toast.error(response.data.message);
//             }
//         } catch (error) {
//             toast.error(error.response?.data?.message || 'Failed to create product');
//         }
//     };

//     const handleStockCreation = async () => {
//         // if (!formData.price || parseFloat(formData.price) <= 0) {
//         //     toast.error("Price must be a positive number.");
//         //     return;
//         // }

//         // if (!formData.store_id) {
//         //     toast.error("Store  is required.");
//         //     return;
//         // }

//         const stockData = {
//             store_id: formData.store_id,
//             product_id: formData.product_id,
//             price: parseFloat(formData.price),
//             description: formData.description,
//             batch_number: formData.batch_number || null,
//         };

//         console.log("Step 2 - Product  stock Creation Data:", stockData);
//         try {
//             const response = await handleApiCall(ApiServices.createProductTock, stockData);
//             if (response.status === 200) {
//                 toast.success('Product stock created successfully!');
//                 setFormData(prev => ({
//                     ...prev,
//                     stock_id: response.data.data.stock_id, // Save stock_id here
//                     product_category: response.data.data.product_category,
//                 }));
//                 setStep(3);
//             } else {
//                 toast.error(response.data.message);
//             }
//         } catch (error) {
//             toast.error(error.response?.data?.message || 'Failed to create stock product');
//         }
//     };

//     const handleImageAddition = async () => {
//         const imageData = {
//             stock_id: formData.stock_id,
//             images: formData.images,
//         };

//         console.log("Step 3 - Product image uplaod Data:", imageData);

//         formData.images.forEach((image, index) => {
//             console.log(`Image ${index + 1}:`, image); // Log each image in the array
//         });

//         try {
//             const response = await handleApiCall(ApiServices.addImages, imageData);
//             if (response.status === 200) {
//                 toast.success('Images added successfully!');
//                 navigateTo("/admin/products/stock/list");
//             } else {
//                 toast.error(response.data.message);
//             }
//         } catch (error) {
//             toast.error(error.response?.data?.message || 'Failed to add images');
//         }
//     };

//     return (
//         <MainLayouts>
//             <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
//                 <section className="w-full pr-[3%] pt-3 my-3" style={{ backgroundColor: "#F7F7F7" }}>
//                     <nav className="bg-gray-200 py-0 px-4 rounded-lg">
//                         <ol className="list-none p-0 inline-flex mb-2">
//                             <li className="flex items-center">
//                                 <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">Product Stock</p>
//                                 <ChevronRightIcon />
//                             </li>
//                             <li className="flex items-center">
//                                 <p className="text-orange1">Create</p>
//                             </li>
//                         </ol>
//                     </nav>
//                 </section>

//                 <section className="gray_background">
//                     <div className="container px-1 pb-2 mx-auto">
//                         <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
//                             {step === 1 && (
//                                 <CreateProductForm
//                                     formData={formData}
//                                     setFormData={setFormData}
//                                     handleChange={handleChange}
//                                     handleNext={handleNext}
//                                     loading={loading}
//                                 />
//                             )}
//                             {step === 2 && (
//                                 <CreateProductStockForm
//                                     formData={formData}
//                                     handleChange={handleChange}
//                                     handleNext={handleNext}
//                                     createStock={handleStockCreation}
//                                 />
//                             )}
//                             {step === 3 && (
//                                 <AddImagesForm
//                                     formData={formData}
//                                     handleChange={handleChange}
//                                     handleNext={handleNext}
//                                 />
//                             )}
//                         </div>
//                     </div>
//                 </section>
//             </div>
//         </MainLayouts>
//     );
// };

// export default AddProductStock;


import React, { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import CreateProductForm from "./productComponet/CreateProductForm";
import CreateProductStockForm from "./productComponet/CreateProductStockForm";
import AddImagesForm from "./productComponet/AddImagesForm";
import { useNavigate } from "react-router-dom";

const AddProductStock = () => {
    const [step, setStep] = useState(1);
    const [stockId, setStockId] = useState(null);
    const [formData, setFormData] = useState({
        is_serialized: true,
        name: "",
        description: "",
        category_id: "",
        sub_category_id: "",
        product_category_id: "",
        store_id: "",
        price: "",
        batch_number: "",
        stock_id: null,
        images: [],
        product_id: "",
        product_category: null,
    });


    const [loading, setLoading] = useState(false);
    const navigateTo = useNavigate();

    // Log formData every time it changes
    useEffect(() => {
        console.log("Updated form data:", formData);
    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "price") {
            setFormData(prev => ({ ...prev, [name]: value === "" ? "" : parseFloat(value) || "" }));
        } else if (name === "batch_number") {
            setFormData(prev => ({ ...prev, [name]: value === "" ? undefined : parseInt(value, 10) }));
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleNext = async () => {
        console.log("Form Data before step submission:", formData);

        console.log("Form Data before step submission strp:", step);
        try {
            setLoading(true);
            if (step === 1) {
                await handleProductCreation();
            } else if (step === 2) {
                step = step + 1;
                await handleStockCreation();
            } else if (step === 3) {
                await handleImageAddition();
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'An error occurred while processing.');
        } finally {
            console.log("Loading ended.");
            setLoading(false);
        }
    };

    const handleApiCall = async (url, data) => {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        return await axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
    };

    const handleProductCreation = async () => {
        const productData = {
            is_serialized: formData.is_serialized,
            category_id: formData.category_id,
            sub_category_id: formData.sub_category_id,
            product_category_id: formData.product_category_id,
            name: formData.name,
            description: formData.description,
        };
        console.log("Step 1 - Product Creation Data:", productData);

        // Check if necessary fields are filled
        if (!formData.name || !formData.category_id || !formData.sub_category_id || !formData.product_category_id) {
            toast.error("Please fill in all required fields.");
            return;
        }

        try {
            const response = await handleApiCall(ApiServices.addProducts, productData);
            if (response.status === 200) {
                toast.success('Product created successfully!');
                setFormData(prev => ({ ...prev, product_id: response.data.product_id }));
                setStep(2);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to create product');
        }
    };



    const [productCategory, setProductCategory] = useState("");

    const handleStockCreation = async (stock_id, product_category) => {

        console.log("handnext", "handle")
        console.log("stock_id", stock_id)
        console.log("product_category", product_category)

        //     const stockData = {
        //         store_id: formData.store_id,
        //         product_id: formData.product_id,
        //         price: parseFloat(formData.price),
        //         description: formData.description,
        //         batch_number: formData.batch_number || null,
        //     };

        //     console.log("Step 2 - Product Stock Creation Data:", stockData);
        //     try {
        //         const response = await handleApiCall(ApiServices.createProductStock, stockData);
        //         if (response.status === 200) {
        //             toast.success('Product stock created successfully!');
        //             setFormData(prev => ({
        //                 ...prev,
        //                 stock_id: response.data.data.stock_id, // Save stock_id here
        //                 product_category: response.data.data.product_category,
        //             }));
        //             setStep(3);
        //         } else {
        //             toast.error(response.data.message);
        //         }
        //     } catch (error) {
        //         toast.error(error.response?.data?.message || 'Failed to create stock product');
        //     }

        // Update formData with stock_id and product_category
        setFormData((prevData) => ({
            ...prevData,
            stock_id,
            product_category,
        }));
        setStockId(stock_id);  // Use stock_id 
        setProductCategory(product_category);
        console.log("Received Stock ID from Child:", stock_id);
        // Move to the next step or handle as needed
        setStep(3);
    };
    useEffect(() => {
        console.log("Updated stock ID:", stockId); // Log whenever stockId changes
    }, [stockId]);

    const handleImageAddition = async () => {
        const imageData = {
            stock_id: formData.stock_id,
            images: formData.images,
        };

        console.log("Step 3 - Product Image Upload Data:", imageData);

        try {
            const response = await handleApiCall(ApiServices.addImages, imageData);
            if (response.status === 200) {
                toast.success('Images added successfully!');
                navigateTo("/admin/products/stock/list");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to add images');
        }
    };

    return (
        <MainLayouts>
            <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
                <section className="w-full pr-[3%] pt-3 my-3" style={{ backgroundColor: "#F7F7F7" }}>
                    <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                        <ol className="list-none p-0 inline-flex mb-2">
                            <li className="flex items-center">
                                <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">Product Stock</p>
                                <ChevronRightIcon />
                            </li>
                            <li className="flex items-center">
                                <p className="text-orange1">Create</p>
                            </li>
                        </ol>
                    </nav>
                </section>

                <section className="gray_background">
                    <div className="container px-1 pb-2 mx-auto">
                        <div className="lg:w-full md:w-full bg-white rounded-lg py-3 px-2 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md bg-dGrey3">
                            {step === 1 && (
                                <CreateProductForm
                                    formData={formData}
                                    setFormData={setFormData}
                                    handleChange={handleChange}
                                    handleNext={handleNext}
                                    loading={loading}
                                />
                            )}
                            {/* {step === 2 && (
                                <CreateProductStockForm
                                    formData={formData}
                                    handleChange={handleChange}
                                    handleNext={handleNext}
                                    createStock={handleStockCreation}
                                />
                            )} */}
                            {step === 2 && (
                                <CreateProductStockForm
                                    formData={formData}
                                    handleNext={handleNext}
                                    createStock={handleStockCreation} // Only the createStock function is passed
                                />
                            )}

                            {step === 3 && (
                                <AddImagesForm
                                    formData={formData}
                                    handleChange={handleChange}
                                    handleNext={handleNext}
                                />
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </MainLayouts>
    );
};

export default AddProductStock;

import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as EyeFill } from "bootstrap-icons/icons/eye-fill.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as ArrowLeftCircleLeftFill } from "bootstrap-icons/icons/arrow-left-circle-fill.svg";
import { ReactComponent as ArrowRightCircleLeftFill } from "bootstrap-icons/icons/arrow-right-circle-fill.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { ReactComponent as PlusSquare } from "bootstrap-icons/icons/plus-square.svg";
import { ReactComponent as PersonFillLock } from "bootstrap-icons/icons/person-fill-lock.svg";
import { ReactComponent as PersonAdd } from "bootstrap-icons/icons/person-add.svg";
import { ReactComponent as PersonDashFill } from "bootstrap-icons/icons/person-dash-fill.svg";
import { ReactComponent as CardList } from "bootstrap-icons/icons/card-list.svg";
// import { ReactComponent as PersonFillLock } from "bootstrap-icons/icons/person-fill-lock.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import DefaultAvatar from "../../components/utils/DefaultAvatar";
import { handleError } from "../../components/utils/AuthenticationCheck";

const AdminList = () => {
  const [admins, setAdmin] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [adminsPerPage] = useState(4); // Set to 2 to display 2 card per pge for now will make it 10
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const navigateTo = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return "Not Available";
    const date = new Date(dateString);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  useEffect(() => {
    const fetchAdminList = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        // console.log("thi is token", token);
        if (!token) {
          throw new Error("No access token found.");
        }

        const response = await axios.get(ApiServices.getAdmin, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        const { status, message, data } = response.data;
        // console.log("this is admin data", data);

        if (status === 200) {
          setAdmin(data || []);
          toast.success(message, { toastId: "admin-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchAdminList();
  }, []);

  // ==================================== Reset admin password =================================================

  // Define function to reset admin password
  const handleAdminResetPaswword = async (adminId) => {
    // console.log("Admin id:", adminId);
    const formData = {
      admin_id: adminId,
    };
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      console.log("token is here", token);
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.post(ApiServices.resetPassword, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      const { status, message } = response.data;

      if (status === 200) {
        toast.success(message);
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  const handleAdminResetPaswwordConfirmation = (adminId) => {
    setConfirmationLoading(true);
    Swal.fire({
      title: "Are you sure want to reset password?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, Reset Password!",
    }).then((result) => {
      setConfirmationLoading(false);
      if (result.isConfirmed) {
        handleAdminResetPaswword(adminId);
      }
    });
  };

  // ==================================== end of Reset admin password =================================================

  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setCurrentPage(1); // Reset to first page when filtering
  };

  const filteredItems = admins.filter((item) => {
    for (let key in item) {
      if (
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(filterText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  const indexOfLastAdmin = currentPage * adminsPerPage;
  const indexOfFirstAdmin = indexOfLastAdmin - adminsPerPage;
  const currentAdmins = filteredItems.slice(
    indexOfFirstAdmin,
    indexOfLastAdmin
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const hasNextPage = () => indexOfLastAdmin < filteredItems.length;
  const hasPrevPage = () => currentPage > 1;

  function truncateString(str, num) {
    if (str.length <= num) return str;
    let lastIndex = str.substr(0, num).lastIndexOf(" ");
    return str.substr(0, lastIndex) + "...";
  }

  return (
    <div>
      <MainLayouts>
        <div className="relative sm:rounded-lg py-6 w-full">
          <div
            className="w-full pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Admin
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">List</p>
                </li>
              </ol>
            </nav>
          </div>

          {/* <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>

            <div>
              <Link
                to="/admin/create"
                className="m-1 block sm:w-auto text-dGrey3 bg-darkGreen3 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                <span className="flex justify-center sm:justify-start mx-2">
                  <PlusSquare className="mr-2" />
                  Create Admin
                </span>
              </Link>
            </div>
          </div> */}

          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
            <Link
              to="/admin/create"
              className="m-1 w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className="flex justify-center sm:justify-start mx-2">
                <PlusSquare className="mr-2" />
                Create Admin
              </span>
            </Link>
          </div>

          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <section className="text-gray-600 body-font">
              <div className="container px-2 py-2 mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 ">
                  {currentAdmins.length > 0 ? (
                    currentAdmins.map((admin, adm) => (
                      <div key={adm} className="p-1">
                        {/* <div className=" border-darkGreen3 border border-gray-200 p-6 rounded-lg bg-white"> */}
                        <div className="border border-darkGreen3 border-r-4 border-r-darkGreen3 p-4 rounded-lg bg-white">
                          <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                            {admin.profile_photo ? (
                              <img
                                src={admin.profile_photo}
                                alt={admin.fullname}
                                className="w-full h-full rounded-full object-cover"
                              />
                            ) : (
                              <DefaultAvatar />
                            )}
                          </div>
                          <h2 className="text-lg text-darkGreen3   font-medium title-font mb-2">
                            {admin.fullname || "Admin Name"}
                          </h2>
                          <p className="leading-relaxed text-base">
                            <span className="font-semibold text-darkGreen3">
                              Email:{" "}
                            </span>
                            {admin.email || "No email available"}
                            <br />
                            <span className="font-semibold text-darkGreen3">
                              Phone:
                            </span>
                            {admin.phone_number || "No phone number available"}
                          </p>
                          <div className="flex justify-between mt-4 ">
                            <div className="flex justify-between mt-4 space-x-4">
                              <div className="relative group">
                                <Link
                                  to="/admin/view/details"
                                  state={{ admin }}
                                  className="text-dash5 hover:text-dash4"
                                >
                                  <EyeFill className="w-7 h-7" />
                                </Link>

                                <div className="absolute top-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block  text-dash5 text-xs rounded py-1 px-2 whitespace-nowrap">
                                  View Details
                                </div>
                              </div>
                              <div className="relative group">
                                <Link
                                  to="/admin/update"
                                  state={{ admin }}
                                  className="text-darkGreen3 hover:text-darkGreen4"
                                >
                                  <PencilSquare className="w-7 h-7" />
                                </Link>

                                <div className="absolute top-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block  text-darkGreen3 text-xs rounded py-1 px-2 whitespace-nowrap">
                                  Edit Admin
                                </div>
                              </div>
                              <div className="relative group">
                                <Link
                                  to="/admin/permission/assign"
                                  state={{ admin }}
                                  className="text-orange1 hover:text-darkGreen4"
                                >
                                  <PersonAdd className="w-7 h-7" />
                                </Link>

                                <div className="absolute top-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block  text-darkGreen3 text-xs rounded py-1 px-2 whitespace-nowrap">
                                  Assign Permission
                                </div>
                              </div>
                              <div className="relative group">
                                <Link
                                  to="/admin/permission/view/permissions"
                                  state={{ admin }}
                                  className="text-black1 hover:text-darkGreen4"
                                >
                                  <CardList className="w-7 h-7" />
                                </Link>

                                <div className="absolute top-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block  text-black1 text-xs rounded py-1 px-2 whitespace-nowrap">
                                  View Permission
                                </div>
                              </div>

                              <div className="relative group">
                                <Link
                                  to="/admin/permission/remove"
                                  state={{ admin }}
                                  className="text-red1 hover:text-darkGreen4"
                                >
                                  <PersonDashFill className="w-7 h-7" />
                                </Link>

                                <div className="absolute top-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block  text-darkGreen3 text-xs rounded py-1 px-2 whitespace-nowrap">
                                  Remove Permission
                                </div>
                              </div>
                              <div className="relative group">
                                <button
                                  onClick={() =>
                                    handleAdminResetPaswwordConfirmation(
                                      admin.admin_id
                                    )
                                  }
                                  className="text-red2 hover:text-red2"
                                >
                                  <PersonFillLock className="w-7 h-7" />
                                </button>

                                <div className="absolute top-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block  text-red2 text-xs rounded py-1 px-2 whitespace-nowrap">
                                  Reset Password
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="w-full text-center text-gray-500">
                      No admins found
                    </div>
                  )}
                </div>
                {/* Pagination controls here */}
                <div className="flex justify-end mt-6">
                  <nav aria-label="Page navigation">
                    <ul className="pagination flex mx-auto">
                      <li
                        className={`page-item ${
                          !hasPrevPage() ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="text-dGrey3 bg-black1  hover:bg-darkGreen2 focus:outline-none font-medium rounded-full text-sm px-5 py-2 text-center inline-flex items-center gap-1 m-2"
                          onClick={() => paginate(currentPage - 1)}
                          disabled={!hasPrevPage()}
                        >
                          <span className="flex mx-2 mt-1">
                            <ArrowLeftCircleLeftFill className="w-5 h-5 mr-2" />
                            Prev
                          </span>
                        </button>
                      </li>
                      <li
                        className={`page-item ${
                          !hasNextPage() ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="text-dGrey3 bg-black1  hover:bg-darkGreen2 focus:outline-none font-medium rounded-full text-sm px-5 py-2 text-center inline-flex items-center gap-1 m-2"
                          onClick={() => paginate(currentPage + 1)}
                          disabled={!hasNextPage()}
                        >
                          <span className="flex mx-2 mt-1">
                            <ArrowRightCircleLeftFill className="w-5 h-5 mr-2" />
                            Next
                          </span>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </section>
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default AdminList;

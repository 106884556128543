import React, { useState, useEffect, useContext } from "react";
import NavBarLogo from "../../assets/navbar-logo.png";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { AuthContext } from "../../AuthProvider/AuthProvider";
import { toast } from "react-toastify";
import { ReactComponent as Person } from "bootstrap-icons/icons/person-fill.svg";
import { ReactComponent as LockFill } from "bootstrap-icons/icons/lock-fill.svg";
import { ReactComponent as UnLockFill } from "bootstrap-icons/icons/unlock-fill.svg";
import { handleError } from "../../components/utils/AuthenticationCheck";

const TokenLogin = () => {
  const location = useLocation();
  const navigateTo = useNavigate();
  const { setAuth } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);

  useEffect(() => {
    // Retrieve email from local storage
    const savedEmail = localStorage.getItem("AdminEmail");
    if (savedEmail) {
      setEmail(savedEmail);
    } else {
      //email is not found in local storage
      navigateTo("/");
      toast.error("Email is not found please log in again", {
        toastId: "admin-login-email-not-found",
      });
    }
  }, []);

  const toggleOtpVisibility = () => setShowOtp(!showOtp);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !otp) {
      toast.error("All fields are required.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        ApiServices.verifyOtp,
        { email, otp },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { status, message, data } = response.data;
      if (status === 200) {
        const {
          token,
          admin: { profile_photo, email },
        } = data;

        // Update local storage and context
        const authData = { email, token, photo: profile_photo };
        localStorage.setItem("auth", JSON.stringify(authData));
        setAuth(authData);

        // localStorage.setItem(
        //   "auth",
        //   JSON.stringify({ email, token, photo: profile_photo })
        // );
        setEmail("");
        localStorage.removeItem("AdminEmail");
        // console.log("Navigating to dashboard...");
        navigateTo("/admin/dashboard");
        toast.success(message, { toastId: "admin-log-in-successfully" });
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  // console.log("Current email value:", email);
  return (
    <div className="text-gray-600 body-font mt-7 ">
      <div className="container mx-auto flex flex-col px-5 py-24 justify-center items-center">
        <img
          className="mb-10 object-cover object-center rounded"
          alt="hero"
          src={NavBarLogo}
        />
        <div className="w-full md:w-2/3 flex flex-col mb-16 items-center">
          <h1 className="text-orange1">Authorization using OTP</h1>
          <div className="xl:w-2/4 md:w-2/2 p-1">
            <div
              className="border border-grey3 p-6 rounded-lg shadow-lg"
              style={{ backgroundColor: "white" }}
            >
              <form className="mx-auto" onSubmit={handleSubmit}>
                <div className="mb-5">
                  {/* <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Email
                  </label> */}
                  <div className="relative">
                    <input
                      id="email"
                      type="hidden"
                      value={email}
                      readOnly
                      className="pr-12 pl-4 shadow-sm bg-dGrey1 text-dash4 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    />
                    <Person className="absolute inset-y-0 right-0 flex items-center pr-3 text-dash4 h-8 w-8" />
                  </div>
                </div>
                <div className="mb-5">
                  {/* <label
                    htmlFor="otp"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Enter OTP
                  </label> */}
                  <div className="relative">
                    <input
                      id="otp"
                      type={showOtp ? "text" : "password"}
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      className="pr-12 pl-4 shadow-sm bg-dGrey1 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      placeholder="OTP"
                    />
                    <div
                      className="absolute inset-y-0 right-0 flex items-center pr-3 text-blue-500 cursor-pointer"
                      onClick={toggleOtpVisibility}
                    >
                      {showOtp ? <UnLockFill /> : <LockFill />}
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="text-dGrey3 bg-orange1 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full my-1"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Authorize"}
                </button>

                {/* <button
                  type="submit"
                  className="text-dGrey3 bg-dash4 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full my-1"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Authorize"}
                </button> */}
              </form>
            </div>
          </div>
          <p className="d-flex justify-end text-grey3">
            Click here to log out
            <span className="pl-4 text-decoration-underline text-orange1">
              <Link to="/">Log Out</Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TokenLogin;

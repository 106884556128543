import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as PlusSquareFill } from "bootstrap-icons/icons/plus-square-fill.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";
import { handleError } from "../../components/utils/AuthenticationCheck";
import formatDate from "../../components/utils/DateFormatter";

const GetAllOrders = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [confirmationLoading, setConfirmationLoading] = useState(false);

    const navigateTo = useNavigate();

    useEffect(() => {
        const fetchAllOrdersList = async () => {
            setLoading(true);
            try {
                const authData = JSON.parse(localStorage.getItem("auth"));
                const token = authData?.token;
                if (!token) {
                    throw new Error("No access token found.");
                }

                const response = await axios.get(ApiServices.getAllOrders, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                });

                const { status, message, data } = response.data;
                //  console.log("this is all order list", data.orders);

                if (status === 200 && Array.isArray(data.orders)) {
                    setOrders(data.orders);
                    toast.success(message, { toastId: "orders-fetch-success" });
                } else {
                    handleError({ response: { status, data: { message } } }, navigateTo);
                }
            } catch (error) {
                handleError(error, navigateTo);
            } finally {
                setLoading(false);
            }
        };

        fetchAllOrdersList();
    }, []);

    const handleFilter = (e) => {
        setFilterText(e.target.value);
        setResetPaginationToggle(!resetPaginationToggle);
    };

    const columns = [
        {
            name: "Actions",
            cell: (row) => (
                <div className="flex justify-content-start">
                    <Link
                        to="/admin/order/details"

                        // state={{ orders: row }}
                        state={{ orderId:row.id }}
                        className="mx-2 my-2 group"
                    >
                        <Eye className="w-5 h-5 text-darkGreen3" />
                        <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
                            Details
                        </div>
                    </Link>
                    <Link
                        to="/admin/order/update"
                        state={{ orders: row }}
                        // state={{ orderId: row.id }}
                        className="mx-2 my-2 group"
                    >
                        <PencilSquare className="w-5 h-5 text-dash4" />
                        <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
                            Edit
                        </div>
                    </Link>

                    {/* <Link
                        className="mx-2 my-2 group"
                        disabled={confirmationLoading}
                    >
                        <Trash className="w-5 h-5 text-dash3" />
                        <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
                            Delete orders
                        </div>
                    </Link> */}
                </div>
            ),
            width: "200px",
        },
        {
            name: "Customer Name",
            selector: (row) => row.user_name.toUpperCase() || "NOT AVAILABLE",
            sortable: true,
            width: "200px",
        },
        {
            name: "Total Amount",
            selector: (row) => row.total_amount || "NOT AVAILABLE",
            sortable: true,
            width: "200px",
        },
        {
            name: "Status",
            selector: (row) => row.status ? row.status.toUpperCase() : "NOT AVAILABLE",
            sortable: true,
            cell: (row) => {
                let bgColor = "#A0AEC0"; // Default background color
                let textColor = "#F7F7F7"; // Default text color

                if (row.status === "processing") {
                    bgColor = "#003FB9"; // Color for processing
                } else if (row.status === "cancelled") {
                    bgColor = "#CC0000"; // Color for cancelled
                } else if (row.status === "shipped") {
                    bgColor = "darkGreen3"; // Color for shipped
                } else if (row.status === "completed") {
                    bgColor = "#FFA439"; // Color for completed
                }

                return (
                    <span style={{ backgroundColor: bgColor, color: textColor, padding: '5px 10px', borderRadius: '4px' }}>
                        {row.status ? row.status.toUpperCase() : "NOT AVAILABLE"}
                    </span>
                );
            },
            width: "150px",
        },

        {
            name: "Order Items",
            selector: (row) =>
                row.order_items.map(item => item.stock).join(", ") || "NOT AVAILABLE",
            sortable: false,
            width: "300px",
        },
        {
            name: "Created At",
            selector: (row) => formatDate(row.created_at) || "NOT AVAILABLE",
            sortable: true,
            width: "250px",
        },
    ];

    const filteredItems = orders.filter(order => {
        const lowerFilterText = filterText.toLowerCase();

        const matchesUserName = order.user_name?.toLowerCase().includes(lowerFilterText);
        const matchesTotalAmount = order.total_amount?.toString().includes(filterText);
        const matchesStatus = order.status?.toString().includes(filterText);
        const matchesOrderItems = order.order_items.some(item =>
            item.stock?.toLowerCase().includes(lowerFilterText)
        );

        return matchesUserName || matchesTotalAmount || matchesOrderItems || matchesStatus;
    });

    const data = filteredItems.map((order) => ({
        user_name: order.user_name || "NOT AVAILABLE",
        total_amount: order.total_amount || "NOT AVAILABLE",
        status: order.status || "NOT AVAILABLE",
        created_at: order.created_at || "NOT AVAILABLE",
        order_items: order.order_items.map(item => ({
            stock: item.stock || "NOT AVAILABLE",
            quantity: item.quantity || 0,
            price: item.price || "0.00",
        })),
        id: order.id
    }));

    // Sort data based on creation date
    const sortedData = [...data].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    return (
        <div>
            <MainLayouts>
                <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
                    <section
                        className="w-full pr-[3%] pt-3 my-3"
                        style={{ backgroundColor: "#F7F7F7" }}
                    >
                        <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                            <ol className="list-none p-0 inline-flex mb-2">
                                <li className="flex items-center">
                                    <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                                        Orders
                                    </p>
                                    <ChevronRightIcon />
                                </li>
                                <li className="flex items-center">
                                    <p className="text-orange1">List</p>
                                </li>
                            </ol>
                        </nav>
                    </section>
                    <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
                        <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
                            <input
                                type="search"
                                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                placeholder="Search"
                                value={filterText}
                                onChange={handleFilter}
                            />
                        </div>
                    </div>
                    {loading ? (
                        <Spinner loading={loading} />
                    ) : (
                        <DataTable
                            columns={columns}
                            data={sortedData}
                            pagination
                            striped
                            responsive
                            highlightOnHover
                            noDataComponent={<div>No sales orders found</div>}
                            customStyles={CustomStyles}
                        />
                    )}
                </div>
            </MainLayouts>
        </div>
    );
};

export default GetAllOrders;

// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";

// export const handleUnauthorized = (navigateTo) => {
//   localStorage.removeItem("auth");
//   toast.error("Session expired. Please log in again.", {
//     toastId: "session-expired",
//   });
//   navigateTo("/");
// };

// export const handleError = (error, navigateTo) => {
//   const status = error.response?.status;
//   const message =
//     error.response?.data?.message || "An unexpected error occurred.";

//   if (status === 204) {
//     toast.error(`Empty List: ${message}`, { toastId: "notfound-error" });
//   } else if (status === 400) {
//     toast.error(`Unsuccessful: ${message}`, { toastId: "bad-request" });
//   } else if (status === 401) {
//     handleUnauthorized(navigateTo);
//   } else if (status === 403) {
//     toast.error(`Unauthorized Access: ${message}`, {
//       toastId: "unauthorized-access",
//     });
//   } else if (status === 404) {
//     toast.error(`Not Found: ${message}`, {
//       toastId: "unauthorized-access",
//     });
//   } else if (status === 500) {
//     toast.error(`Server Error: ${message}`, { toastId: "server-error" });
//   } else {
//     toast.error(message, { toastId: "default-error" });
//   }
// };

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const handleUnauthorized = (navigateTo) => {
  localStorage.removeItem("auth");
  toast.error("Session expired. Please log in again.", {
    toastId: "session-expired",
  });
  navigateTo("/");
};

export const handleError = (error, navigateTo) => {
  const status = error.response?.status;
  const message =
    error.response?.data?.message || "An unexpected error occurred.";

  if (status === 204) {
    toast.error(message, { toastId: "notfound-error" });
  } else if (status === 400) {
    toast.error(message, { toastId: "bad-request" });
  } else if (status === 401) {
    handleUnauthorized(navigateTo);
  } else if (status === 403) {
    toast.error(message, {
      toastId: "unauthorized-access",
    });
  } else if (status === 404) {
    toast.error(message, {
      toastId: "unauthorized-access",
    });
  } else if (status === 500) {
    toast.error(message, { toastId: "server-error" });
  } else {
    toast.error(message, { toastId: "default-error" });
  }
};

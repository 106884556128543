

// import React, { useState, useEffect } from "react";
// import MainLayouts from "../../../Layout/MainLayouts";
// import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
// import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
// import Spinner from "../../spinner/Spinner";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import { handleError } from "../../../components/utils/AuthenticationCheck";
// import axios from "axios";
// import ApiServices from "../../../api/ApiServices";
// import { toast } from "react-toastify";
// import DataTable from "react-data-table-component";
// import CustomStyles from "../../../components/utils/CustomStyles";
// import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
// import { ReactComponent as PlusSquareFill } from "bootstrap-icons/icons/plus-square-fill.svg";

// const GetBillersProduct = () => {
//     const [billersProducts, setBillersProducts] = useState([]);
//     // const [billerId, setBillerId] = useState("");
//     // const [billerName, setBillerName] = useState("");
//     const [loading, setLoading] = useState(true);
//     const [filterText, setFilterText] = useState("");
//     const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
//     const [currentMessagePage, setCurrentMessagePage] = useState(1);
//     const [messagesPerPage] = useState(3);

//     const navigateTo = useNavigate();
//     const location = useLocation();
//     const billers = location.state?.billers;
//     const billerName = billers.name;
//     const billerId = billers.id;



//     useEffect(() => {
//         const fetchBillerProducts = async () => {
//             setLoading(true);
//             try {
//                 const authData = JSON.parse(localStorage.getItem("auth"));
//                 const token = authData?.token;
//                 if (!token) {
//                     throw new Error("No access token found.");
//                 }
//                 const actualToken = token.split("|")[1];

//                 const response = await axios.post(
//                     ApiServices.getSwitchers,
//                     {},
//                     {
//                         headers: {
//                             Authorization: `Bearer ${actualToken}`,
//                             token: token,
//                             "Content-Type": "application/json",
//                         },
//                         withCredentials: true,
//                     }
//                 );

//                 // const { status, message, products } = response.data.data;
//                 // console.log("this is all billers products", products);

//                 const { status, message, data } = response.data;
//                 if (status === 200 && data && data.products) {
//                     const filteredProducts = data.products.filter(product => product.biller_id === billerId);
//                     setBillersProducts(filteredProducts);
//                     console.log("Filtered billers products:", filteredProducts);
//                     toast.success(message, { toastId: "flash-sales-fetch-success" });
//                 } else {
//                     handleError({ response: { status, data: { message } } }, navigateTo);
//                 }
//             } catch (error) {
//                 handleError(error, navigateTo);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchBillerProducts();
//     }, [billerId, filterText]);

//     // Filter data by name
//     const handleFilter = (e) => {
//         setFilterText(e.target.value);
//         setResetPaginationToggle(!resetPaginationToggle);

//     };



//     // Function to format permission text
//     const formatPermissionText = (text) => {
//         // Replace underscores with spaces
//         let formattedText = text.replace(/_/g, " ");

//         // Capitalize the first letter of each word
//         formattedText = formattedText
//             .toLowerCase()
//             .replace(/\b\w/g, (char) => char.toUpperCase());

//         return formattedText;
//     };

//     const columns = [
//         // {
//         //     name: "Actions",
//         //     cell: (row) => (
//         //         <div className="flex justify-content-start">
//         //             <Link to="/admin/billers/update" className="mx-2 my-2 group">
//         //                 <PencilSquare className="w-5 h-5 text-dash4" />
//         //                 <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
//         //                     Edit
//         //                 </div>
//         //             </Link>
//         //             <Link to="/admin/billers/update" className="mx-2 my-2 group">
//         //                 <Eye className="w-5 h-5 text-darkGreen3" />
//         //                 <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
//         //                     View product
//         //                 </div>
//         //             </Link>
//         //         </div>
//         //     ),
//         //     width: "200px",

//         // },
//         {
//             name: "Product Name",
//             selector: (row) => row.name ? formatPermissionText(row.name) : "NOT AVAILABLE",
//             sortable: true,
//             width: "200px",
//         },
//         {
//             name: "Commission",
//             selector: (row) => row.commission_type ? row.commission_type : "NOT AVAILABLE",
//             sortable: true,
//             width: "150px",
//         },
//         {
//             name: "Value",
//             selector: (row) => row.sp_value ? row.sp_value : "0.00",
//             sortable: true,
//             width: "150px",
//         },
//         {
//             name: "Agent Value",
//             selector: (row) => row.agent_value ? row.agent_value : "0.00",
//             sortable: true,
//             width: "150px",
//         },
//     ];

//     // const filteredItems = billersProducts.filter((item) => {
//     //     for (let key in item) {
//     //         if (
//     //             typeof item[key] === "string" &&
//     //             item[key].toLowerCase().includes(filterText.toLowerCase())
//     //         ) {
//     //             return true;
//     //         }
//     //     }
//     //     return false;
//     // });

//     const filteredItems = billersProducts.filter((item) => {
//         return Object.values(item).some(value =>
//             typeof value === "string" &&
//             value.toLowerCase().includes(filterText.toLowerCase())
//         );
//     });


//     const data = filteredItems.map((product) => ({
//         created_at: product.created_at || "NOT AVAILABLE",
//         name: product.name ? formatPermissionText(product.name) : "NOT AVAILABLE",
//         commission_type: product.commission_type || "NOT AVAILABLE",
//         sp_value: product.sp_value || "0.00",
//         id: product.id,
//     }));

//     // Sort data as needed
//     const sortedData = data; // or sort based on criteria

//     const paginateMessages = (pageNumber) => {
//         setCurrentMessagePage(pageNumber);
//     };

//     // Calculate indices for slicing the messages
//     const indexOfLastMessage = currentMessagePage * messagesPerPage;
//     const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
//     const currentMessages = billersProducts.slice(indexOfFirstMessage, indexOfLastMessage);

//     // Determine if there are more pages
//     const hasNextPageMessages = () => indexOfLastMessage < billersProducts.length;
//     const hasPrevPageMessages = () => currentMessagePage > 1;


//     return (
//         <div>
//             <MainLayouts>
//                 <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
//                     <section className="w-full pr-[3%] pt-3 my-3" style={{ backgroundColor: "#F7F7F7" }}>
//                         <nav className="bg-gray-200 py-0 px-4 rounded-lg">
//                             <ol className="list-none p-0 inline-flex mb-2">
//                                 <li className="flex items-center">
//                                     <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">Billers Product</p>
//                                     <ChevronRightIcon />
//                                 </li>
//                                 <li className="flex items-center">
//                                     <p className="text-orange1">List</p>
//                                 </li>
//                             </ol>
//                         </nav>
//                     </section>
//                     <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
//                         <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
//                             <input
//                                 type="search"
//                                 className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
//                                 placeholder="Search"
//                                 value={filterText}
//                                 onChange={handleFilter}
//                             />
//                         </div>
//                         <Link
//                             to="/admin/billers/list"
//                             // state={{ billers }}
//                             // onClick={() => goBack()}
//                             className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
//                         >
//                             <span className="flex justify-center sm:justify-start mx-2">
//                                 <PlusSquareFill className="mr-2" />
//                                 Go Back
//                             </span>
//                         </Link>
//                     </div>
//                     <h6 className="text-orange1">{billerName}</h6>
//                     {/* {loading ? (
//                         <Spinner loading={loading} />
//                     ) : (
//                         <DataTable
//                             columns={columns}
//                             data={data}
//                             pagination
//                             striped
//                             responsive
//                             highlightOnHover
//                             noDataComponent={<div>No billers product found</div>}
//                             customStyles={CustomStyles}
//                         />
//                     )}
//                 </div> */}

//                     {loading ? (
//                         <Spinner loading={loading} />
//                     ) : billersProducts.length > 0 ? (
//                         <div className="flex flex-wrap -m-2">
//                             {billersProducts.map((product) => (
//                                 <div className="p-2 w-1/4" key={product.id}> 
//                                     <div className="bg-darkGreen3 text-dGrey3 mt-2 rounded flex p-2 h-full items-center">
//                                         <span className="title-font font-medium">
//                                             {formatPermissionText(product.name)}
//                                         </span>
//                                     </div>
//                                 </div>
//                             ))}
//                         </div>
//                     ) : (
//                         <div className="p-4 text-center text-gray-500">
//                             No biller products found.
//                         </div>
//                     )}

//                     <div className="flex justify-end items-center  mt-4">
//                         <nav aria-label="Page navigation">
//                             <ul className="pagination flex mx-auto">
//                                 <li className={`page-item ${!hasPrevPageMessages() ? "disabled" : ""}`}>
//                                     <button
//                                         className="text-dGrey3 bg-black1 hover:bg-darkGreen2 focus:outline-none font-medium rounded-full text-sm px-5 py-2"
//                                         onClick={() => paginateMessages(currentMessagePage - 1)}
//                                         disabled={!hasPrevPageMessages()}
//                                     >
//                                         Prev
//                                     </button>
//                                 </li>
//                                 <li className={`page-item ${!hasNextPageMessages() ? "disabled" : ""}`}>
//                                     <button
//                                         className="text-dGrey3 bg-black1 hover:bg-darkGreen2 focus:outline-none font-medium rounded-full text-sm px-5 py-2"
//                                         onClick={() => paginateMessages(currentMessagePage + 1)}
//                                         disabled={!hasNextPageMessages()}
//                                     >
//                                         Next
//                                     </button>
//                                 </li>
//                             </ul>
//                         </nav>
//                     </div>
//                 </div>
//             </MainLayouts>
//         </div>
//     );
// };

// export default GetBillersProduct;

import React, { useState, useEffect } from "react";
import MainLayouts from "../../../Layout/MainLayouts";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../../spinner/Spinner";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { handleError } from "../../../components/utils/AuthenticationCheck";
import axios from "axios";
import ApiServices from "../../../api/ApiServices";
import { toast } from "react-toastify";

const GetBillersProduct = () => {
    const [billersProducts, setBillersProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [currentMessagePage, setCurrentMessagePage] = useState(1);
    const [messagesPerPage] = useState(20); // Number of items per page

    const navigateTo = useNavigate();
    const location = useLocation();
    const billers = location.state?.billers;
    const billerName = billers.name;
    const billerId = billers.id;

    useEffect(() => {
        const fetchBillerProducts = async () => {
            setLoading(true);
            try {
                const authData = JSON.parse(localStorage.getItem("auth"));
                const token = authData?.token;
                if (!token) {
                    throw new Error("No access token found.");
                }
                const actualToken = token.split("|")[1];

                const response = await axios.post(
                    ApiServices.getSwitchers,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${actualToken}`,
                            token: token,
                            "Content-Type": "application/json",
                        },
                        withCredentials: true,
                    }
                );

                const { status, message, data } = response.data;
                if (status === 200 && data && data.products) {
                    const filteredProducts = data.products.filter(product => product.biller_id === billerId);
                    setBillersProducts(filteredProducts);
                    toast.success(message, { toastId: "flash-sales-fetch-success" });
                } else {
                    handleError({ response: { status, data: { message } } }, navigateTo);
                }
            } catch (error) {
                handleError(error, navigateTo);
            } finally {
                setLoading(false);
            }
        };

        fetchBillerProducts();
    }, [billerId]);

    // Filter data by name
    const handleFilter = (e) => {
        setFilterText(e.target.value);
        setResetPaginationToggle(!resetPaginationToggle);
    };

    const filteredItems = billersProducts.filter((item) =>
        Object.values(item).some(value =>
            typeof value === "string" &&
            value.toLowerCase().includes(filterText.toLowerCase())
        )
    );

    // Pagination Logic
    const indexOfLastMessage = currentMessagePage * messagesPerPage;
    const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
    const currentMessages = filteredItems.slice(indexOfFirstMessage, indexOfLastMessage);

    const totalPages = Math.ceil(filteredItems.length / messagesPerPage);

    const paginateMessages = (pageNumber) => {
        setCurrentMessagePage(pageNumber);
    };

    // Function to format permission text
    const formatPermissionText = (text) => {
        // Replace underscores with spaces
        let formattedText = text.replace(/_/g, " ")
        // Capitalize the first letter of each word
        formattedText = formattedText
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase());

        return formattedText;
    };

    return (
        <div>
            <MainLayouts>
                <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
                    <section className="w-full pr-[3%] pt-3 my-3" style={{ backgroundColor: "#F7F7F7" }}>
                        <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                            <ol className="list-none p-0 inline-flex mb-2">
                                <li className="flex items-center">
                                    <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">Billers Product</p>
                                    <ChevronRightIcon />
                                </li>
                                <li className="flex items-center">
                                    <p className="text-orange1">List</p>
                                </li>
                            </ol>
                        </nav>
                    </section>

                    <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
                        <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
                            <input
                                type="search"
                                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                placeholder="Search"
                                value={filterText}
                                onChange={handleFilter}
                            />
                        </div>
                        <Link
                            to="/admin/billers/list"
                            className="w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                            <span className="flex justify-center sm:justify-start mx-2">
                                Go Back
                            </span>
                        </Link>
                    </div>

                    <h6 className="text-orange1">{billerName}</h6>

                    {loading ? (
                        <Spinner loading={loading} />
                    ) : filteredItems.length > 0 ? (
                        <div className="flex flex-wrap -m-2">
                            {currentMessages.map((product) => (
                                <div className="p-2 w-1/4" key={product.id}>
                                    <div className="bg-darkGreen3 text-dGrey3 mt-2 rounded flex p-2 h-full items-center">
                                        <span className="title-font font-medium">
                                            {formatPermissionText(product.name)}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="p-4 text-center text-gray-500">
                            No biller products found.
                        </div>
                    )}

                    {/* Pagination Controls */}
                    <div className="flex justify-end items-center mt-4">
                        <nav aria-label="Page navigation">
                            <ul className="pagination flex mx-auto">
                                <li className={`page-item ${currentMessagePage === 1 ? "disabled" : ""}`}>
                                    <button
                                        className="text-dGrey3 bg-black1 hover:bg-darkGreen2 focus:outline-none font-medium rounded-full text-sm px-5 py-2"
                                        onClick={() => paginateMessages(currentMessagePage - 1)}
                                        disabled={currentMessagePage === 1}
                                    >
                                        Prev
                                    </button>
                                </li>
                                <li className={`page-item ${currentMessagePage === totalPages ? "disabled" : ""}`}>
                                    <button
                                        className="text-dGrey3 bg-black1 hover:bg-darkGreen2 focus:outline-none font-medium rounded-full text-sm px-5 py-2"
                                        onClick={() => paginateMessages(currentMessagePage + 1)}
                                        disabled={currentMessagePage === totalPages}
                                    >
                                        Next
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </MainLayouts>
        </div>
    );
};

export default GetBillersProduct;



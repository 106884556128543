import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as PlusSquareFill } from "bootstrap-icons/icons/plus-square-fill.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";
import CurrencyFormatter from "../../components/utils/CurrencyFormatter";
import { handleError } from "../../components/utils/AuthenticationCheck";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const navigateTo = useNavigate();

  useEffect(() => {
    const fetchAllproductList = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }
        // console.log("i am token", token);
        const response = await axios.get(ApiServices.getProducts, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        console.log("this is all product", response.data);
        const { status, message, data } = response.data;
        // setProducts(data);
        if (status === 200) {
          setProducts(data);
          toast.success(message, { toastId: "product-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchAllproductList();
  }, []);

  // ====================================delete product =================================================

  // Define toastMixin
  const toastMixin = Swal.mixin({
    toast: true,
    icon: "success",
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  // Define function to handle delete confirmation
  const handleDeleteProduct = async (productId) => {
    const formData = {
      product_id: productId,
    };
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }
      // console.log("i am token",token);
      const response = await axios.delete(
        ApiServices.removeProducts,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: formData,
          withCredentials: true,
        }
      );

      const updatedProduct = products.filter(
        (products) => products.product_id !== productId
      );

      setProducts(updatedProduct);

      const { status, message } = response.data;
      // console.log("this is the deleted items",data);
      if (status === 200 && Array.isArray(data)) {
        toast.error(message);
        return;
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteConfirmation = (productId) => {
    setConfirmationLoading(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      setConfirmationLoading(false);
      if (result.isConfirmed) {
        handleDeleteProduct(productId);
      }
    });
  };

  // ==================================== end of delete product =================================================

  //filter data by name
  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const columns = [
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex justify-content-start  ">
          <Link
            to="/admin/product/details"
            // state={{ id: row.product_id }}
            state={{ product_id: row.product_id }}
            // state={{ productByRow: row }}
            className="mx-2 my-2 group"
          >
            <Eye className="w-5 h-5 text-darkGreen3 " />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs  whitespace-nowrap ">
              Details
            </div>
          </Link>
          <Link
            to="/admin/product/edit"
            state={{ productByRow: row }}
            className="mx-2 my-2 group"
          >
            <PencilSquare className="w-5 h-5 text-dash4 " />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs  whitespace-nowrap ">
              Edit
            </div>
          </Link>
          <Link
            to="/admin/addproduct/images"
            state={{ productByRow: row }}
            className="mx-2 my-2 group"
          >
            <PlusSquareFill className="w-5 h-5 text-orange1 " />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs  whitespace-nowrap ">
              Add Images
            </div>
          </Link>
          {/* <Link
            className="mx-2 my-2 group"
            onClick={() => handleDeleteConfirmation(row.product_id)}
            disabled={confirmationLoading}
          >
            <Trash className="w-5 h-5 text-dash3 " />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs  whitespace-nowrap ">
              Delete Products
            </div>
          </Link> */}
        </div>
      ),
      width: "200px",
    },

    {
      name: "Delete",
      cell: (row) => (
        <div className="d-flex justify-content-end buttons">
          <Link
            className="btn btn-info btn-sm"
            onClick={(e) => handleDeleteConfirmation(row.product_id)}
            disabled={confirmationLoading}
          >
            <Trash className="w-5 h-5 text-dash3 " />
          </Link>
        </div>
      ),
      width: "80px",
    },
    // {
    //   name: "Image",
    //   selector: (row) => {
    //     // console.log("Image URL:", row.image);
    //     return (
    //       <img
    //         src={row.image || "https://via.placeholder.com/50"}
    //         alt={row.name}
    //         style={{
    //           width: "50px",
    //           height: "50px",
    //           marginTop: "7px",
    //           marginBottom: "7px",
    //         }}
    //       />
    //     );
    //   },
    //   sortable: false,
    //   width: "80px",
    // },
    {
      name: "Product Name",
      selector: (row) => (row.name ? row.name.toUpperCase() : ""),
      sortable: true,
      width: "300px",
    },

    {
      name: "Sold",
      selector: (row) => row.sold_quantity,
      sortable: true,
      width: "100px",
    },
    {
      name: "Status",
      selector: (row) =>
        row.status ? row.status.toUpperCase() : "NOT AVAILABLE",
      sortable: true,
      cell: (row) => {
        return (
          <span
            className={row.status === "active" ? "text-dash" : "text-dash3"}
          >
            {row.status ? row.status.toUpperCase() : "NOT AVAILABLE"}
          </span>
        );
      },
      width: "100px",
    },

    {
      name: "Category",
      sortable: true,
      width: "300px",
      cell: (row) => (
        <div>
          <span className="block font-bold">
            {row.category_name
              ? row.category_name.toUpperCase()
              : "NOT AVAILABLE"}
          </span>
          <span className="px-3 py-0.5 rounded-md bg-orange1 text-lGrey2 text-xs">
            {row.sub_category_name
              ? row.sub_category_name.toLowerCase()
              : "NOT AVAILABLE"}
          </span>
        </div>
      ),
    },

    {
      name: "Brand",
      selector: (row) => row.product_category_name,
      sortable: true,
      width: "200px",
    },
  ];

  const filteredItems = products.filter((item) => {
    for (let key in item) {
      if (
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(filterText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  const data = filteredItems.map((products) => ({
    // created_at: formatDate(products.created_at),
    image: products.image,
    name: products.name ? products.name.toUpperCase() : "",
    sold_quantity: products.sold_quantity ? products.sold_quantity : "0",
    sub_category_name: products.sub_category_name
      ? products.sub_category_name.toUpperCase()
      : "NOT AVAILABLE",
    category_name: products.category_name
      ? products.category_name.toUpperCase()
      : "",
    product_category_name: products.product_category_name
      ? products.product_category_name.toUpperCase()
      : "NOT AVAILABLE",
    status: products.status ? products.status : "",
    product_id: products.product_id,
    sub_category_id: products.sub_category_id,
    product_category_id: products.product_category_id,
    description: products.description,
    category_id: products.category_id,
    // status_id: products.status_id,
    is_serialized: products.is_serialized
  }));

  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          {/* <h1>Product List</h1> */}
          <section
            className="w-full  pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Vendors Product
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1 ">List</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
            <Link
              to="/admin/addproduct"
              className="w-full sm:w-auto text-dGrey3 bg-orange1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className="flex justify-center sm:justify-start mx-2">
                <PlusSquareFill className="mr-2" />
                Create Product
              </span>
            </Link>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              data={sortedData}
              pagination
              striped
              responsive
              highlightOnHover
              noDataComponent={<div>No products found</div>}
              customStyles={CustomStyles}
              // expandableRows
              style={{ width: "100%" }}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default ProductList;

import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { AuthContext } from "./AuthProvider/AuthProvider.jsx";
import Login from "./pages/Auth/Login.jsx";
import TokenLogin from "./pages/Auth/TokenLogin";
import Dashboard from "./pages/dashboard/Dashboard.jsx";
import Customer from "./pages/customer/Customer";
import PageNotFound from "./pages/PageNotFound";
import AddProduct from "./pages/vendorProduct/AddProduct.jsx";
import ProductList from "./pages/vendorProduct/ProductList.jsx";
import CategoryList from "./pages/categories/CategoryList";
import AddCategories from "./pages/categories/AddCategories";
// import AddPayment from "./pages/payment/AddPayment";
import EditProduct from "./pages/vendorProduct/EditProduct.jsx";
import PaymentList from "./pages/payment/PaymentList";
import CustomerDetails from "./pages/customer/CustomerDetails";
import ProductDetails from "./pages/vendorProduct/ProductDetails.jsx";
import PaymentDetails from "./pages/payment/PaymentDetails";
import ListOfMessages from "./pages/messages/ListOfMessages";
import MessageDetails from "./pages/messages/MessageDetails";
import ReturnList from "./pages/return/ReturnList";
// import CategoryDetails from "./pages/categories/CategoryDetails";
import UpdateCategory from "./pages/categories/UpdateCategory";
import ReturnItemDetails from "./pages/return/ReturnItemDetails";
import FulfiledList from "./pages/fulfiled/FulfiledList";
import ErrorPage from "./pages/ErrorPage";
// import HelpPending from "./pages/HelpCenter/HelpPending";
// import HelpPendingDetails from "./pages/HelpCenter/HelpPendingDetails";
import Faq from "./pages/faq/Faq";
import ListOfFaq from "./pages/faq/ListOfFaq";
// import ChangePassword from "./pages/Auth/password/ChangePassword.jsx";
import HelpCenterList from "./pages/HelpCenter/HelpCenterList.jsx";
import CustomerTransactionHistory from "./pages/customer/CustomerTransactionHistory";
// import AddProductToStore from "./pages/storeOld/AddProductToStore.jsx";
// import StoreProductList from "./pages/storeOld/StoreProductList.jsx";
import UpdateSubCategory from "./pages/subcategories/UpdateSubCategory";
import AddSubCategories from "./pages/subcategories/AddSubCategories";
import SubCategoryList from "./pages/subcategories/SubCategoryList";
import ProductCategoryList from "./pages/productCategory/ProductCategoryList.jsx";
import AddProductCategory from "./pages/productCategory/AddProductCategory.jsx";
import EditProductCategory from "./pages/productCategory/EditProductCategory.jsx";
// import ProductCategoryDetails from "./pages/productCategory/ProductCategoryDetails.jsx";
import ProductStockList from "./pages/productStock/ProductStockList.jsx";
import AddProductStock from "./pages/productStock/AddProductStock.jsx";
import EditProductStock from "./pages/productStock/EditProductStock.jsx";
import ProductStockDetails from "./pages/productStock/ProductStockDetails";
import AddStore from "./pages/store/AddStore.jsx";
import StoreList from "./pages/store/StoretList.jsx";
import EditStore from "./pages/store/EditStore.jsx";
import StoreDetails from "./pages/store/StoreDetails.jsx";
import ForgetPassword from "./pages/Auth/password/ForgetPassword.jsx";
import VerifyOTP from "./pages/Auth/password/VerifyOTP.jsx";
import ChangePassword from "./pages/Auth/password/ChangePassword.jsx";
import AdminList from "./pages/Admin/AdminList.jsx";
import AddAdmin from "./pages/Admin/AddAdmin.jsx";
import NotificationStatus from "./pages/Admin/NotificationStatus.jsx";
import TwoFactorAuth from "./pages/Admin/TwoFactorAuth.jsx";
import AdminDetails from "./pages/Admin/AdminDetails.jsx";
import UpdateAdmin from "./pages/Admin/UpdateAdmin.jsx";
import RoleList from "./pages/Roles/RoleList.jsx";
import PermissionList from "./pages/Permissions/PermissionList.jsx";
import UpdatePermission from "./pages/Permissions/UpdatePermission.jsx";
import GetAdminPermissionById from "./pages/Permissions/GetAdminPermisionById.jsx";
import AssignPermissionToAdmin from "./pages/Permissions/AssigPermissionToAdmin.jsx";
import RemovePermissionFromAdmin from "./pages/Permissions/RemovePermissionFromAdmin.jsx";
import ViewRolesPermission from "./pages/Roles/ViewRolesPermission.jsx";
import VendorList from "./pages/vendor/VendorList.jsx";
import VendorDetails from "./pages/vendor/VendorDetails";
import UpdateVendor from "./pages/vendor/UpdateVendor";
import CreateRoles from "./pages/Roles/CreateRoles.jsx";
import UpdateRoles from "./pages/Roles/UpdateRole.jsx";
import GrantPermissionToRole from "./pages/Roles/GrantPermissionToRole.jsx";
import AssignRoleToAdmin from "./pages/Roles/AssignRoleToAdmin.jsx";
// import HelpCenterType from "./pages/HelpCenter/HelpCenterType.jsx";
import GetBillsProducts from "./pages/Bills/Airtime/GetBillsProducts.jsx";
import AddImagesToProduct from "./pages/vendorProduct/AddImagesToProduct.jsx";
import AddImagesToProductStock from "./pages/productStock/AddImagesToProductStock.jsx";
import AddProductStockSupply from "./pages/productStock/AddProductStockSupply.jsx";
import AddProductStockVariant from "./pages/productStock/AddProductStockVariant.jsx";
import UpdateProductStockVariant from "./pages/productStock/UpdateProductStockVariant.jsx";
import AddProductStockVariantSupply from "./pages/productStock/AddProductStockVariantSupply.jsx";
import ProductStockVariantImageUpload from "./pages/productStock/ViewProductStockVariantImageUpload.jsx";
import AddImagesToProductStockVariant from "./pages/productStock/AddImageToProductStockVariant.jsx";
import AddFlashSales from "./pages/flashSales/AddFlashSales.jsx";
import FlashSalesList from "./pages/flashSales/FlashSalestList.jsx";
import EditFlashSales from "./pages/flashSales/EditFlashSales.jsx";
import GetBillers from "./pages/Bills/Biller/GetBillers.jsx";
import AddBillers from "./pages/Bills/Biller/AddBiller.jsx";
import UpdateBillers from "./pages/Bills/Biller/UpdateBillers.jsx";
import SearchBills from "./pages/Bills/Biller/SearchBills.jsx";
import PrivateRoute from "./AuthProvider/PrivateRoute.jsx";
import ProductPropertyList from "./pages/ProductProperty/ProductPropertyList.jsx";
import AddProductProperty from "./pages/ProductProperty/AddProductProperty.jsx";
import EditProductProperty from "./pages/ProductProperty/EditProductProperty.jsx";
import HelpCenterDetails from "./pages/HelpCenter/HelpCenterDetail.jsx";
import HelpCenterUpdate from "./pages/HelpCenter/HelpCenterUpdate.jsx";
import AddMessageToCustomerTicket from "./pages/HelpCenter/AddMessageToCustomerTicket.jsx";
import AdminActLog from "./pages/Admin/AdminActLog.jsx";
import GetAllProducts from "./pages/Bills/Products/GetAllProducts.jsx";
import GetBillersProduct from "./pages/Bills/Biller/GetBillersProduct.jsx";
import Switcher from "./pages/Bills/Switcher/Switcher.jsx";
import GetAllOrders from "./pages/orders/GetAllOrders.jsx";
import UpdateOrders from "./pages/orders/UpdateOrders.jsx";
import OrderById from "./pages/orders/OrderById.jsx";
import GetCompletedOrders from "./pages/orders/GetCompletedOrders.jsx";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Login />} />
      {/* <Route element={<PrivateRoutes />}> */}
      <Route path="/login/token" element={<TokenLogin />} />
      {/* <Route path="/admin/dashboard" element={<Dashboard />} /> */}

      <Route
        path="/admin/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      {/* ======================= customer management=========================== */}
      <Route
        path="/admin/customer"
        element={
          <PrivateRoute>
            <Customer />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/customer/transactions/history"
        element={
          <PrivateRoute>
            <CustomerTransactionHistory />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/customer/details"
        element={
          <PrivateRoute>
            <CustomerDetails />
          </PrivateRoute>
        }
      />
      {/* =======================end of customer managemnt======================= */}
      {/* ========================== product management  =========================*/}
      <Route
        path="/admin/products"
        element={
          <PrivateRoute>
            <ProductList />
          </PrivateRoute>
        }
      />
      {/* <Route path="/admin/store/products" element={<StoreProductList />} /> */}
      <Route
        path="/admin/addproduct"
        element={
          <PrivateRoute>
            <AddProduct />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/addproduct/images"
        element={
          <PrivateRoute>
            <AddImagesToProduct />
          </PrivateRoute>
        }
      />
      {/* <Route path="/admin/store/addproduct" element={<AddProductToStore />} /> */}
      <Route
        path="/admin/product/details"
        element={
          <PrivateRoute>
            <ProductDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/edit"
        element={
          <PrivateRoute>
            <EditProduct />
          </PrivateRoute>
        }
      />
      {/*======================= end of product ======================*/}
      {/*=======================  product  property======================*/}
      <Route
        path="/admin/product/property/list"
        element={
          <PrivateRoute>
            <ProductPropertyList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/property/update"
        element={
          <PrivateRoute>
            <EditProductProperty />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/property/create"
        element={
          <PrivateRoute>
            <AddProductProperty />
          </PrivateRoute>
        }
      />
      {/*======================= end of product  property======================*/}
      {/*============================ product management =================*/}
      <Route
        path="/admin/products/category/list"
        element={
          <PrivateRoute>
            <ProductCategoryList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/products/category/create"
        element={
          <PrivateRoute>
            <AddProductCategory />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/category/edit"
        element={
          <PrivateRoute>
            <EditProductCategory />
          </PrivateRoute>
        }
      />
      {/* <Route
    path="/admin/product/category/details"
    element={<ProductCategoryDetails />}
  /> */}
      {/*========================== end of product management=================== */}
      {/*============================== product stock managememt ==================*/}
      <Route
        path="/admin/products/stock/list"
        element={
          <PrivateRoute>
            <ProductStockList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/products/stock/create"
        element={
          <PrivateRoute>
            <AddProductStock />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/stock/edit"
        element={
          <PrivateRoute>
            <EditProductStock />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/stock/details"
        element={
          <PrivateRoute>
            <ProductStockDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/stock/images"
        element={
          <PrivateRoute>
            <AddImagesToProductStock />
          </PrivateRoute>
        }
      />

      <Route
        path="/admin/products/stock/variant/update"
        element={
          <PrivateRoute>
            <UpdateProductStockVariant />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/stock/variant/images/uploads"
        element={
          <PrivateRoute>
            <ProductStockVariantImageUpload />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/stock/variant/add/supply"
        element={
          <PrivateRoute>
            <AddProductStockVariantSupply />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/stock/variant/images/uploads"
        element={
          <PrivateRoute>
            <AddImagesToProductStockVariant />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/stock/add/supply"
        element={
          <PrivateRoute>
            <AddProductStockSupply />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/products/stock/variant/create"
        element={
          <PrivateRoute>
            <AddProductStockVariant />
          </PrivateRoute>
        }
      />
      {/*===================== end of product stock managememt =============================== */}
      {/*=============================== store managemenet ===================================*/}
      {/* ============================== end of store management============================== */}
      <Route
        path="/admin/store/list"
        element={
          <PrivateRoute>
            <StoreList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/store/update"
        element={
          <PrivateRoute>
            <EditStore />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/store/details"
        element={
          <PrivateRoute>
            <StoreDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/store/create"
        element={
          <PrivateRoute>
            <AddStore />
          </PrivateRoute>
        }
      />
      {/* <Route path="/admin/store/delete" element={<RemoveStore />} /> */}
      {/*======================= category management ===========================================*/}
      <Route
        path="/admin/category/list"
        element={
          <PrivateRoute>
            <CategoryList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/category/update"
        element={
          <PrivateRoute>
            <UpdateCategory />
          </PrivateRoute>
        }
      />
      {/* <Route path="/admin/category/details" element={<CategoryDetails />} /> */}
      <Route
        path="/admin/addcategory"
        element={
          <PrivateRoute>
            <AddCategories />
          </PrivateRoute>
        }
      />
      {/*  ============================category management =======================================*/}
      {/*================================ sub category ===========================================*/}
      <Route
        path="/admin/subcategory/list"
        element={
          <PrivateRoute>
            <SubCategoryList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/subcategory/update"
        element={
          <PrivateRoute>
            <UpdateSubCategory />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/subcategory/create"
        element={
          <PrivateRoute>
            <AddSubCategories />
          </PrivateRoute>
        }
      />
      {/*==================================== end of sub category ============================== */}
      {/* ========================= flash sales ======================================== */}
      <Route
        path="/admin/flash/sales/list"
        element={
          <PrivateRoute>
            <FlashSalesList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/flash/sales/create"
        element={
          <PrivateRoute>
            <AddFlashSales />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/flash/sales/update"
        element={
          <PrivateRoute>
            <EditFlashSales />
          </PrivateRoute>
        }
      />

      {/* ========================= end flash sales ======================================== */}
      {/* ================================orders management ====================================*/}
      <Route
        path="/admin/fullfilled"
        element={
          <PrivateRoute>
            <FulfiledList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/payment"
        element={
          <PrivateRoute>
            <PaymentList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/payment/details"
        element={
          <PrivateRoute>
            <PaymentDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/order/list"
        element={
          <PrivateRoute>
            <GetAllOrders />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/order/details"
        element={
          <PrivateRoute>
            <OrderById />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/order/update"
        element={
          <PrivateRoute>
            <UpdateOrders />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/return/items"
        element={
          <PrivateRoute>
            <ReturnList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/return/items/details"
        element={
          <PrivateRoute>
            <ReturnItemDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/order/fulfiled"
        element={
          <PrivateRoute>
            <GetCompletedOrders />
          </PrivateRoute>
        }
      />
      {/* ========================= ticket ============================== */}
      <Route
        path="/admin/help/list"
        element={
          <PrivateRoute>
            <HelpCenterList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/help/details"
        element={
          <PrivateRoute>
            <HelpCenterDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/help/update"
        element={
          <PrivateRoute>
            <HelpCenterUpdate />
          </PrivateRoute>
        }
      />
      {/* ==================== message reply to ticket ================== */}
      <Route
        path="/admin/help/message/add"
        element={
          <PrivateRoute>
            <AddMessageToCustomerTicket />
          </PrivateRoute>
        }
      /> 
      {/*=============================- end of order management ==========================*/}
      {/* ===================== admin management=========================================== */}
      <Route
        path="/admin/view/list"
        element={
          <PrivateRoute>
            <AdminList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/view/details"
        element={
          <PrivateRoute>
            <AdminDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/create"
        element={
          <PrivateRoute>
            <AddAdmin />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/update"
        element={
          <PrivateRoute>
            <UpdateAdmin />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/activity/log"
        element={
          <PrivateRoute>
            <AdminActLog />
          </PrivateRoute>
        }
      />
      {/* ===================== end if admin management=========================================== */}
      {/* ====================== Roles and Permission ========================== */}
      <Route
        path="/admin/permission/list"
        element={
          <PrivateRoute>
            <PermissionList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/permission/view/permissions"
        element={
          <PrivateRoute>
            <GetAdminPermissionById />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/permission/assign"
        element={
          <PrivateRoute>
            <AssignPermissionToAdmin />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/permission/remove"
        element={
          <PrivateRoute>
            <RemovePermissionFromAdmin />
          </PrivateRoute>
        }
      />

      <Route
        path="/admin/permission/update"
        element={
          <PrivateRoute>
            <UpdatePermission />
          </PrivateRoute>
        }
      />
      {/* roles */}
      <Route
        path="/admin/roles/list"
        element={
          <PrivateRoute>
            <RoleList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/roles/view/permission"
        element={
          <PrivateRoute>
            <ViewRolesPermission />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/roles/create"
        element={
          <PrivateRoute>
            <CreateRoles />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/roles/update"
        element={
          <PrivateRoute>
            <UpdateRoles />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/roles/grant/permission"
        element={
          <PrivateRoute>
            <GrantPermissionToRole />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/roles/assign/permission"
        element={
          <PrivateRoute>
            <AssignRoleToAdmin />
          </PrivateRoute>
        }
      />
      {/* ======================end of  Roles and Permission =================== */}
      {/*======================== settings management ====================================*/}
      <Route
        path="/admin/faq/list"
        element={
          <PrivateRoute>
            <ListOfFaq />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/faq/create"
        element={
          <PrivateRoute>
            <Faq />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/account/2fa/update/status"
        element={
          <PrivateRoute>
            <TwoFactorAuth />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/account/notification/status"
        element={
          <PrivateRoute>
            <NotificationStatus />
          </PrivateRoute>
        }
      />
      {/* ====================== vendor  management====================================== */}
      <Route
        path="/admin/vendor/list"
        element={
          <PrivateRoute>
            <VendorList />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/vendor/details"
        element={
          <PrivateRoute>
            <VendorDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/vendor/update"
        element={
          <PrivateRoute>
            <UpdateVendor />
          </PrivateRoute>
        }
      />
      {/* ====================== end of vendor  management====================================== */}
      {/* ====================== end of settings management ===============================*/}
      <Route
        path="*"
        element={
          <PrivateRoute>
            <PageNotFound />
          </PrivateRoute>
        }
      />
      <Route
        path="/request/error"
        element={
          <PrivateRoute>
            <ErrorPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/password/change"
        element={
          <PrivateRoute>
            <ChangePassword />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/password/forget"
        element={
          <PrivateRoute>
            <ForgetPassword />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/otp/verification"
        element={
          <PrivateRoute>
            <VerifyOTP />
          </PrivateRoute>
        }
      />
      {/*============================ admin settings============================= */}
      {/*============================ bills management============================= */}
      <Route
        path="/admin/product/list"
        element={
          <PrivateRoute>
            <GetBillsProducts />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/billers/list"
        element={
          <PrivateRoute>
            <GetBillers />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/billers/create"
        element={
          <PrivateRoute>
            <AddBillers />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/billers/update"
        element={
          <PrivateRoute>
            <UpdateBillers />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/bills/search"
        element={
          <PrivateRoute>
            <SearchBills />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/product/all/list"
        element={
          <PrivateRoute>
            <GetAllProducts />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/billers/view/product"
        element={
          <PrivateRoute>
            <GetBillersProduct />
          </PrivateRoute>
        }
      />
      <Route
        path="/admin/switchers/list"
        element={
          <PrivateRoute>
            <Switcher />
          </PrivateRoute>
        }
      />
      {/*============================ bills management============================= */}
      {/* </Route> */}
    </Route>
  )
);

function App() {
  // const { auth } = useContext(AuthContext);
  return <RouterProvider router={router} />;
}

export default App;

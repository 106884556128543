

import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import Spinner from "../spinner/Spinner";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import CurrencyFormatter from "../../components/utils/CurrencyFormatter";

const OrderById = () => {
    const [orderDetails, setOrderDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    // Get the order ID from the location state
    const { orderId } = location.state || {};

    useEffect(() => {
        const fetchOrderDetails = async (orderId) => {
            setLoading(true);
            try {
                const authData = JSON.parse(localStorage.getItem("auth"));
                const token = authData?.token;

                if (!token) {
                    throw new Error("No access token found.");
                }

                const response = await axios.get(ApiServices.getOrdersById, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    params: { order_id: orderId }, 
                });

                const { status, message, data } = response.data;

                if (status === 200) {
                    setOrderDetails(data); 
                    toast.success(message);
                } else {
                    toast.error(message || "Failed to fetch order details");
                }
            } catch (error) {
                toast.error(error.message || "An error occurred while fetching order details");
            } finally {
                setLoading(false);
            }
        };

        if (orderId) {
            fetchOrderDetails(orderId);
        } else {
            toast.error("Order ID is missing.");
        }
    }, [orderId]);

    return (
        <div>
            <MainLayouts>
                <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
                    <section className="w-full pr-[3%] pt-3 my-3" style={{ backgroundColor: "#F7F7F7" }}>
                        <nav className="bg-gray-200 py-0 px-4 rounded-lg">
                            <ol className="list-none p-0 inline-flex mb-2">
                                <li className="flex items-center">
                                    <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                                        Orders
                                    </p>
                                    <ChevronRightIcon />
                                </li>
                                <li className="flex items-center">
                                    <p className="text-orange1">Details</p>
                                </li>
                            </ol>
                        </nav>
                    </section>

                    {loading ? (
                        <Spinner loading={loading} />
                    ) : (
                        <div className="p-2 w-full mt-4">
                            <h1 className="text-dash md:text-lg">Order Details</h1>
                            <div className="flex items-center border-dash p-1">
                                <div className="relative shadow-md sm:rounded-lg w-full overflow-x-auto">
                                    <table className="min-w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 px-2 py-5">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th className="px-6 py-3 border border-darkGreen3 bg-darkGreen3 text-lGrey dark:border-gray-700">
                                                    Details
                                                </th>
                                                <th className="px-6 py-3 border border-gray-300 bg-darkGreen3 text-lGrey dark:border-gray-700">
                                                    Value
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderDetails && (
                                                <>
                                                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                        <th scope="row" className="px-6 py-4 border border-darkGreen3">
                                                            Customer  Name
                                                        </th>
                                                        <td className="px-6 py-4 border border-darkGreen3">
                                                                {orderDetails.user_name || "NOT AVAILABLE"}
                                                        </td>
                                                    </tr>
                                                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                        <th scope="row" className="px-6 py-4 border border-darkGreen3">
                                                            Total Amount
                                                        </th>
                                                        <td className="px-6 py-4 border border-darkGreen3">
                                                                {CurrencyFormatter (orderDetails.total_amount || "NOT AVAILABLE")}
                                                        </td>
                                                    </tr>
                                                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                        <th scope="row" className="px-6 py-4 border border-darkGreen3">
                                                            Status
                                                        </th>
                                                        <td className="px-6 py-4 border border-darkGreen3">
                                                                {orderDetails.status || "NOT AVAILABLE"}
                                                        </td>
                                                    </tr>
                                                        <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                            <th scope="row" className="px-6 py-4 border border-darkGreen3">
                                                                Payment Method
                                                            </th>
                                                            <td className="px-6 py-4 border border-darkGreen3">
                                                                {orderDetails.payment_method || "NOT AVAILABLE"}
                                                            </td>
                                                        </tr>
                                                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                        <th scope="row" className="px-6 py-4 border border-darkGreen3">
                                                            Address
                                                        </th>
                                                        <td className="px-6 py-4 border border-darkGreen3">
                                                                {orderDetails.address || "NOT AVAILABLE"}
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/* Order Items Table */}
                            <h2 className="text-dash md:text-lg mt-6">Order Items</h2>
                            <div className="flex items-center border-dash p-1">
                                <div className="relative shadow-md sm:rounded-lg w-full overflow-x-auto">
                                    <table className="min-w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 px-2 py-5">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th className="px-6 py-3 border border-darkGreen3 bg-darkGreen3 text-lGrey dark:border-gray-700">
                                                    Item
                                                </th>
                                                <th className="px-6 py-3 border border-gray-300 bg-darkGreen3 text-lGrey dark:border-gray-700">
                                                    Quantity
                                                </th>
                                                <th className="px-6 py-3 border border-gray-300 bg-darkGreen3 text-lGrey dark:border-gray-700">
                                                    Price
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderDetails.order_items && orderDetails.order_items.length > 0 ? (
                                                orderDetails.order_items.map((item, index) => (
                                                    <tr key={index} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                                        <td className="px-6 py-4 border border-darkGreen3">
                                                            {item.stock || "NOT AVAILABLE"}
                                                        </td>
                                                        <td className="px-6 py-4 border border-darkGreen3">
                                                            {item.quantity || 0}
                                                        </td>
                                                        <td className="px-6 py-4 border border-darkGreen3">
                                                            {CurrencyFormatter(item.price || "NOT AVAILABLE")}
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="3" className="px-6 py-4 text-center">
                                                        No items available.
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </MainLayouts>
        </div>
    );
};

export default OrderById;


import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as PlusSquare } from "bootstrap-icons/icons/plus-square.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as PlusSquareFill } from "bootstrap-icons/icons/plus-square-fill.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { ReactComponent as BagPlusFill } from "bootstrap-icons/icons/bag-plus-fill.svg";
import { ReactComponent as LightningChargeFill } from "bootstrap-icons/icons/lightning-charge-fill.svg";
import { ReactComponent as DeleteFlashIcon } from "bootstrap-icons/icons/trash.svg";
import { ReactComponent as EditFlashIcon } from "bootstrap-icons/icons/pencil.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CustomStyles from "../../components/utils/CustomStyles";
import CurrencyFormatter from "../../components/utils/CurrencyFormatter";
import { handleError } from "../../components/utils/AuthenticationCheck";
// import DropDownMenu from "../flashSales/dropdownForProductStock/DropDownMenu";

const ProductStockList = ({ rows }) => {
  const [productStockList, setProductStockList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  // const [activeRowId, setActiveRowId] = useState(null);
  // const [selectedProductStock, setSelectedProductStock] = useState(null);
  const [flashSales, setFlashSales] = useState(null);

  const navigateTo = useNavigate();

  // Fetch product stock
  useEffect(() => {
    const fetchProductStockList = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }

        const response = await axios.get(ApiServices.getAllProductsStock, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        const { status, message, data } = response.data;

        if (status === 200 && Array.isArray(data)) {
          const activeProductStock = data.filter(
            (product) => product.product_status === "active"
          );
          console.log("this is all product stock list", activeProductStock);
          // console.log("Active Product Stock:", activeProductStock);
          setProductStockList(activeProductStock);
          toast.success(message, { toastId: "product-stock-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchProductStockList();
  }, [navigateTo]);

  // Handle delete flash sales
  const handleDeleteCategory = async (stock_id) => {
    const formData = { stock_id };
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.delete(ApiServices.removeProductStock, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: formData,
        withCredentials: true,
      });

      const { status, message } = response.data;
      if (status === 200) {
        const updatedProductStock = productStockList.filter(
          (product) => product.stock_id !== stock_id
        );
        setProductStockList(updatedProductStock);
        toast.error(message);
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  // Handle delete category
  const handleDeleteflash = async (flash_sale_id) => {
    const formData = { flash_sale_id };
    console.log("this is form data for flash sales delete", formData);
    setLoading(true);
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }

      const response = await axios.delete(ApiServices.removeFlashsales, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: formData,
        withCredentials: true,
      });

      const { status, message } = response.data;
      if (status === 200) {
        const updatedProductStock = productStockList.filter(
          // (product) => product.flash_sale.flash_sale_id !== flash_sale_id
          (product) => product.flash_sale?.flash_sale_id !== flash_sale_id
        );
        // console.log("name you update", updatedProductStock);
        setProductStockList(updatedProductStock);
        toast.error(message);
      } else {
        handleError({ response: { status, data: { message } } }, navigateTo);
      }
    } catch (error) {
      handleError(error, navigateTo);
    } finally {
      setLoading(false);
    }
  };

  // Confirm delete
  const handleDeleteConfirmation = (flash_sale_id) => {
    console.log("Flash Sale ID for deletion:", flash_sale_id);
    setConfirmationLoading(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      setConfirmationLoading(false);
      if (result.isConfirmed) {
        handleDeleteflash(flash_sale_id);
      }
    });
  };

  // Filter data by name
  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  // Function to format permission text
  const formatText = (text) => {
    let formattedText = text.replace(/_/g, " ");
    formattedText = formattedText
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
    return formattedText;
  };

  const columns = [
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex justify-content-start">
          {/* <LightningChargeFill className="w-5 h-5 text-red1" /> */}
          {/* <Link
            to="/admin/flash/sales/create"
            state={{ productStock: row }}
            className="mx-2 my-2 group"
          >
            <LightningChargeFill className="w-5 h-5 text-red1" />
            <div className="mr-3 absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Add Flash Sales
            </div>
          </Link>
          <Link
            to="/admin/flash/sales/update"
            state={{ productStock: row, flash_sale:row }}
            className="mx-2 my-2 group"
          >
            <EditFlashIcon className="w-5 h-5 text-dash5" />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Edit Flash
            </div>
          </Link> */}

          {row.status === "available" && (
            <>
              {row.flash_sale?.status === "active" ? (
                <Link
                  to="/admin/flash/sales/update"
                  state={{ productStock: row, flash_sale: row }}
                  className="mx-2 my-2 group"
                >
                  <EditFlashIcon className="w-5 h-5 text-dash5" />
                  <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
                    Edit Flash
                  </div>
                </Link>
              ) : (
                
                <Link
                  to="/admin/flash/sales/create"
                  state={{ productStock: row }}
                  className="mx-2 my-2 group"
                >
                  <LightningChargeFill className="w-5 h-5 text-red1" />
                  <div className="mr-3 absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
                    Add Flash Sales
                  </div>
                </Link>
              )}

              <button
                onClick={() =>
                  handleDeleteConfirmation(row.flash_sale?.flash_sale_id)
                }
                // state={{ flash_sale: row.flash_sale_id }}
                className="mx-2 my-2 group"
              >
                <DeleteFlashIcon className="w-5 h-5 text-red1" />
                <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
                  Delete Flash
                </div>
              </button>
            </>
          )}
          {/* {row.status === "available" && (
            <>
              {row.flash_sale?.status === "active" ? (
                <Link
                  to="/admin/flash/sales/update"
                  state={{ productStock: row, flash_sale: row }}
                  className="mx-2 my-2 group"
                >
                  <EditFlashIcon className="w-5 h-5 text-dash5" />
                  <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
                    Edit Flash
                  </div>
                </Link>
              ) : (
                row.avail_quantity > 0 && ( // Check if avail_quantity is greater than 0
                  <Link
                    to="/admin/flash/sales/create"
                    state={{ productStock: row }}
                    className="mx-2 my-2 group"
                  >
                    <LightningChargeFill className="w-5 h-5 text-red1" />
                    <div className="mr-3 absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
                      Add Flash Sales
                    </div>
                  </Link>
                )
              )}
            </>
          )} */}

          <Link
            to="/admin/product/stock/details"
            state={{ stock_id: row.stock_id, productStock: row }}
            className="mx-2 my-2 group"
          >
            <Eye className="w-5 h-5 text-darkGreen3" />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Details
            </div>
          </Link>
          <Link
            to="/admin/product/stock/edit"
            state={{ productStock: row }}
            className="mx-2 my-2 group"
          >
            <PencilSquare className="w-5 h-5 text-dash4" />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Edit
            </div>
          </Link>
          <Link
            to="/admin/product/stock/images"
            state={{ productStock: row }}
            className="mx-2 my-2 group"
          >
            <PlusSquareFill className="w-5 h-5 text-orange1" />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Add Images
            </div>
          </Link>
          <Link
            to="/admin/product/stock/add/supply"
            state={{ productStock: row }}
            className="mr-7 my-2 group "
          >
            <BagPlusFill className="w-5 h-5 text-darkGreen2" />
            <div className="absolute top-full left-2/2 transform -translate-x-1/2 mb-1 hidden group-hover:block text-dash5 text-xs whitespace-nowrap">
              Add Supply
            </div>
          </Link>
        </div>
      ),
      width: "250px",
    },
    {
      name: "Name",
      sortable: true,
      width: "200px",
      cell: (row) => (
        <div>
          <span className="block font-bold">
            {row.name ? row.name.toUpperCase() : "NOT AVAILABLE"}
          </span>
          <span className="px-3 py-0.5 rounded-md  text-xs bg-orange1 text-lGrey2">
            {row.sku ? row.sku.toLowerCase() : "NOT AVAILABLE"}
          </span>
        </div>
      ),
    },
    {
      name: "Price",
      selector: (row) => CurrencyFormatter(row.price),
      sortable: true,
      width: "180px",
    },
    {
      name: "Qty",
      selector: (row) => row.quantity,
      sortable: true,
      width: "100px",
    },
    {
      name: "Aval.Qty",
      selector: (row) => row.avail_quantity,
      sortable: true,
      width: "100px",
    },
    {
      name: "Sold Qty",
      selector: (row) => row.sold_quantity,
      sortable: true,
      width: "100px",
    },
    {
      name: "Status",
      selector: (row) =>
        row.status ? formatText(row.status).toUpperCase() : "NOT AVAILABLE",
      sortable: true,
      cell: (row) => {
        let color = "grey"; // Default color

        if (row.status === "available") {
          color = "green";
        } else if (row.status === "out_of_stock") {
          color = "red";
        } else if (row.status === "discontinued") {
          color = "blue";
        }

        return (
          <span style={{ color: color }}>
            {row.status
              ? formatText(row.status).toUpperCase()
              : "NOT AVAILABLE"}
          </span>
        );
      },
      width: "150px",
    },

    {
      name: "Product Status",
      selector: (row) =>
        row.product_status ? row.product_status.toUpperCase() : "NOT AVAILABLE",
      sortable: true,
      cell: (row) => {
        let color = "grey";

        if (row.product_status === "active") {
          color = "green";
        } else if (row.product_status === "inactive") {
          color = "red";
        }

        return (
          <span style={{ color: color }}>
            {row.product_status
              ? row.product_status.toUpperCase()
              : "NOT AVAILABLE"}
          </span>
        );
      },
      width: "150px",
    },
    {
      name: "Last Restock Date",
      selector: (row) => row.last_restocked_at.toUpperCase(),
      sortable: true,
      width: "200px",
    },

    // {
    //   name: "Delete",
    //   cell: (row) => (
    //     <div className="d-flex justify-content-end buttons">
    //       <button
    //         className="mt-5"
    //         onClick={() => handleDeleteConfirmation(row.stock_id)}
    //         style={{ marginLeft: "5px" }}
    //         disabled={confirmationLoading}
    //       >
    //         <Trash className="w-5 h-5 text-dash3 mr-3" />
    //       </button>
    //     </div>
    //   ),
    //   width: "80px",
    // },
  ];

  const filteredItems = productStockList.filter((item) => {
    for (let key in item) {
      if (
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(filterText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  const data = filteredItems.map((productStock) => ({
    last_restocked_at: productStock.last_restocked_at || "NOT AVAILABLE",
    price: productStock.price || "0.00",
    quantity: productStock.quantity || "0",
    product_category: productStock.product_category || "NOT AVAILABLE",
    sub_category: productStock.sub_category || "NOT AVAILABLE",
    status: productStock.status || "NOT AVAILABLE",
    category: productStock.category
      ? productStock.category.toUpperCase()
      : "NOT AVAILABLE",
    sub_category_id: productStock.sub_category_id,
    category_id: productStock.category_id,
    name: productStock.name,
    product_category_id: productStock.product_category_id,
    product_status: productStock.product_status,
    sku: productStock.sku,
    stock_id: productStock.stock_id,
    store_id: productStock.store_id,
    product_id: productStock.product_id,
    flash_sale: productStock.flash_sale,
    flash_sale_id: productStock.flash_sale_id,
    quantity: productStock.quantity,
    sold_quantity: productStock.sold_quantity,
    avail_quantity: productStock.avail_quantity,
    is_serialized: productStock.is_serialized
  }));

  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Product Stock
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">List</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="pb-4 bg-white dark:bg-gray-900 flex flex-wrap justify-between">
            <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0">
              <input
                type="search"
                className="block w-full px-3 py-2 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                placeholder="Search"
                value={filterText}
                onChange={handleFilter}
              />
            </div>
            <Link
              to="/admin/products/stock/create"
              className="m-1 w-full sm:w-auto text-dGrey3 bg-orange1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <span className="flex justify-center sm:justify-start mx-2">
                <PlusSquare className="mr-2" />
                Create Stock Product
              </span>
            </Link>
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              data={sortedData}
              pagination
              striped
              responsive
              highlightOnHover
              noDataComponent={<div>No Product Stock found</div>}
              customStyles={CustomStyles}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default ProductStockList;

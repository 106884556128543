// // import { useState, useEffect } from "react";
// // import MainLayouts from "../../Layout/MainLayouts";
// // import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
// // import Spinner from "../spinner/Spinner";
// // import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
// // import { ReactComponent as PlusSquareFill } from "bootstrap-icons/icons/plus-square-fill.svg";
// // import { Link, useNavigate, useLocation } from "react-router-dom";
// // import axios from "axios";
// // import ApiServices from "../../api/ApiServices";
// // import { toast } from "react-toastify";
// // import { handleError } from "../../components/utils/AuthenticationCheck";
// // import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
// // import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
// // import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
// // import CurrencyFormatter from "../../components/utils/CurrencyFormatter";
// // import Swal from "sweetalert2";
// // import ProductStockVariantTable from "./ProductStockVariantTable";

// // const ProductStockDetails = () => {
// //   const [productDetails, setProductDetails] = useState({});
// //   const [images, setImages] = useState([]);
// //   const [productStockId, setProductStockId] = useState("");
// //   const [loading, setLoading] = useState(true);
// //   const [confirmationLoading, setConfirmationLoading] = useState(false);
// //   const [currentPage, setCurrentPage] = useState(1);
// //   const [imagesPerPage] = useState(6);
// //   const [reloadAfterDelete, setReloadAfterDelete] = useState(false);

// //   const navigateTo = useNavigate();
// //   const location = useLocation();
// //   //get stoick id row data here
// //   const { stock_id } = location.state || {};
// //   // console.log("i am the stock id", stock_id);

// //   useEffect(() => {
// //     if (stock_id) {
// //       setProductStockId(stock_id.stock_id || "");
// //     }
// //   }, [stock_id]);

// //   useEffect(() => {
// //     const fetchProductStockDetails = async (stock_id) => {
// //       setLoading(true);
// //       try {
// //         const authData = JSON.parse(localStorage.getItem("auth"));
// //         const token = authData?.token;
// //         if (!token) {
// //           throw new Error("No access token found.");
// //         }

// //         const response = await axios.get(ApiServices.getProductsStockById, {
// //           headers: {
// //             Authorization: `Bearer ${token}`,
// //             "Content-Type": "application/json",
// //           },
// //           params: { stock_id },
// //           withCredentials: true,
// //         });

// //         const { status, message, data } = response.data;
// //         // console.log("stock product response", data);
// //         if (status === 200) {
// //           setProductDetails(data);
// //           setImages(data.images || []);
// //           toast.success(message, { toastId: "product-details-fetch-success" });
// //         } else {
// //           handleError({ response: { status, data: { message } } }, navigateTo);
// //         }
// //       } catch (error) {
// //         handleError(error, navigateTo);
// //       } finally {
// //         setLoading(false);
// //         setReloadAfterDelete(false);
// //       }
// //     };

// //     if (stock_id) {
// //       fetchProductStockDetails(stock_id);
// //     } else {
// //       toast.error("Stock is missing.");
// //       navigateTo("/admin/products/stock/list");
// //     }
// //   }, [stock_id, reloadAfterDelete]);

// //   const handleDeleteProduct = async (image_id, stock_id) => {
// //     // console.log("Attempting to delete:", image_id, stock_id);
// //     const formData = {
// //       image_id: image_id,
// //       stock_id: stock_id,
// //     };
// //     try {
// //       const authData = JSON.parse(localStorage.getItem("auth"));
// //       const token = authData?.token;
// //       if (!token) {
// //         throw new Error("No access token found.");
// //       }
// //       const response = await axios.delete(
// //         ApiServices.removeImagesProductStock,
// //         {
// //           headers: {
// //             Authorization: `Bearer ${token}`,
// //             "Content-Type": "application/json",
// //           },
// //           data: formData,
// //           withCredentials: true,
// //         }
// //       );

// //       const { status, message } = response.data;
// //       if (status === 200) {
// //         setImages((prevImages) =>
// //           prevImages.filter((img) => img.image_id !== image_id)
// //         );
// //         toast.success(message, {
// //           toastId: "product-stock-deleted-successfully",
// //         });
// //         setReloadAfterDelete(true);
// //       } else {
// //         toast.error(message);
// //       }
// //     } catch (error) {
// //       // toast.error("Error deleting product.");
// //       console.error(error);
// //     } finally {
// //       setConfirmationLoading(false);
// //     }
// //   };

// //   const handleDeleteConfirmation = (image) => {
// //     setConfirmationLoading(true);
// //     Swal.fire({
// //       title: "Are you sure?",
// //       text: "You won't be able to revert this!",
// //       icon: "warning",
// //       showCancelButton: true,
// //       confirmButtonColor: "#d33",
// //       cancelButtonColor: "#3085d6",
// //       confirmButtonText: "Yes, delete it!",
// //     }).then((result) => {
// //       setConfirmationLoading(false);
// //       if (result.isConfirmed) {
// //         // console.log("User confirmed deletion for:", image);
// //         handleDeleteProduct(image.image_id, image.stock_id);
// //       }
// //     });
// //   };

// //   const indexOfLastImage = currentPage * imagesPerPage;
// //   const indexOfFirstImage = indexOfLastImage - imagesPerPage;

// //   // Change: Correctly retrieve images
// //   const allImages = productDetails.images || []; // Updated
// //   const currentImages = allImages.slice(indexOfFirstImage, indexOfLastImage);
// //   // console.log("this are the curent images", currentImages);

// //   const hasNextPage = indexOfLastImage < allImages.length;
// //   const hasPrevPage = currentPage > 1;

// //   return (
// //     <div>
// //       <MainLayouts>
// //         <div className="relative sm:rounded-lg py-6 w-full">
// //           <section
// //             className="w-full pr-[3%] pt-3 my-3"
// //             style={{ backgroundColor: "#F7F7F7" }}
// //           >
// //             <nav className="bg-gray-200 py-0 px-4 rounded-lg">
// //               <ol className="list-none p-0 inline-flex mb-2">
// //                 <li className="flex items-center">
// //                   <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
// //                     Product
// //                   </p>
// //                   <ChevronRightIcon />
// //                 </li>

// //                 <li className="flex items-center">
// //                   <p className="text-blue-500 hover:text-blue-600 ">Stock</p>
// //                   <ChevronRightIcon />
// //                 </li>
// //                 <li className="flex items-center">
// //                   <p className="text-blue-500 hover:text-blue-600 ">Details</p>
// //                 </li>
// //               </ol>
// //             </nav>
// //           </section>

// //           {loading ? (
// //             <Spinner loading={loading} />
// //           ) : (
// //             <section className="text-gray-600 body-font">
// //               <div className="container">
// //                 <div className="flex justify-end mb-3">
// //                   <Link
// //                     to="/admin/products/stock/variant/create"
// //                     // state={{ productStockId }}
// //                     state={{
// //                       productDetails,
// //                     }}
// //                     className=" mx-2 w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
// //                   >
// //                     <span className="flex justify-center sm:justify-start mx-2">
// //                       <PlusSquareFill className="mr-2" />
// //                       Add Properties
// //                     </span>
// //                   </Link>
// //                   <Link
// //                     to="/admin/products/stock/variant/create"
// //                     // state={{ productStockId }}
// //                     state={{
// //                       productDetails,
// //                     }}
// //                     className=" mx-2 w-full sm:w-auto text-dGrey3 bg-orange1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
// //                   >
// //                     <span className="flex justify-center sm:justify-start mx-2">
// //                       <PlusSquareFill className="mr-2" />
// //                       Add Variant
// //                     </span>
// //                   </Link>
// //                   <Link
// //                     to="/admin/products/stock/list"
// //                     className=" mx-2 w-full sm:w-auto text-dGrey3 bg-black1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
// //                   >
// //                     <span className="flex justify-center sm:justify-start mx-2">
// //                       <ArrowLeftSquare className="mr-2" />
// //                       Back
// //                     </span>
// //                   </Link>
// //                 </div>
// //                 <div className="grid grid-cols-12 gap-4">
// //                   <div className="col-span-12 lg:col-span-12 p-2">
// //                     {/* Image display */}
// //                     <div className="grid grid-cols-2 sm:grid-cols-6 gap-4 mt-4">
// //                       {currentImages.length > 0 ? (
// //                         currentImages.map((image, index) => (
// //                           <div
// //                             key={index}
// //                             className="overflow-hidden shadow-md bg-dGrey3"
// //                           >
// //                             <div className="h-full flex items-center border-dGrey3 border p-1.5 rounded-lg ">
// //                               <div className="flex-grow ">
// //                                 <h2 className="flex items-center justify-between text-darkGreen3 title-font font-medium">
// //                                   <small className="text-darkGreen3">
// //                                     {image.alt_text}
// //                                   </small>
// //                                   <span className="flex items-center">
// //                                     <Link
// //                                       onClick={(e) => {
// //                                         e.preventDefault();
// //                                         handleDeleteConfirmation(image);
// //                                       }}
// //                                       disabled={confirmationLoading}
// //                                       className="text-dash5 mr-2 border border-dGrey3 rounded-md p-2"
// //                                     >
// //                                       <Trash className="w-5 h-5 text-dash3 " />
// //                                     </Link>
// //                                   </span>
// //                                 </h2>

// //                                 <p className="text-gray-500 flex items-center ">
// //                                   <img
// //                                     src={image.image_url}
// //                                     alt={image.alt_text}
// //                                     className="w-full h-32 object-cover p-4"
// //                                   />
// //                                 </p>
// //                               </div>
// //                             </div>
// //                           </div>
// //                         ))
// //                       ) : (
// //                         <div className="col-span-full text-center">
// //                           No images available.
// //                         </div>
// //                       )}
// //                     </div>

// //                     {/* Pagination Controls */}
// //                     <div className="flex justify-end mt-6">
// //                       <nav aria-label="Page navigation">
// //                         <ul className="pagination flex mx-auto">
// //                           <li
// //                             className={`page-item ${
// //                               !hasPrevPage ? "disabled" : ""
// //                             }`}
// //                           >
// //                             <button
// //                               className="text-dGrey3 bg-black1 hover:bg-darkGreen2 font-medium rounded-full text-sm px-5 py-2"
// //                               onClick={() =>
// //                                 setCurrentPage((prev) => Math.max(prev - 1, 1))
// //                               }
// //                               disabled={!hasPrevPage}
// //                             >
// //                               Prev
// //                             </button>
// //                           </li>
// //                           <li
// //                             className={`page-item ${
// //                               !hasNextPage ? "disabled" : ""
// //                             }`}
// //                           >
// //                             <button
// //                               className="text-dGrey3 bg-black1 hover:bg-darkGreen2 font-medium rounded-full text-sm px-5 py-2"
// //                               onClick={() => setCurrentPage((prev) => prev + 1)}
// //                               disabled={!hasNextPage}
// //                             >
// //                               Next
// //                             </button>
// //                           </li>
// //                         </ul>
// //                       </nav>
// //                     </div>
// //                   </div>

// //                   <div className="col-span-12 lg:col-span-12 p-2">
// //                     <div className="flex items-center border-dash border p-1 rounded-lg border-l-4">
// //                       <section className="body-font w-full">
// //                         <div className="container px-2">
// //                           <div className="w-full flex flex-wrap">
// //                             <div className="w-full px-4">
// //                               <h2 className="text-sm title-font mt-2">
// //                                 {productDetails.name
// //                                   ? productDetails.name.toUpperCase()
// //                                   : "Not Available"}
// //                               </h2>
// //                               <h1 className="text-dash text-3xl title-font font-medium mb-1">
// //                                 {productDetails.category
// //                                   ? productDetails.category.toUpperCase()
// //                                   : "Not Available"}
// //                               </h1>
// //                               <span className="bg-dash text-dGrey3 text-xs font-medium me-2 px-3 py-1 rounded-full ">
// //                                 {productDetails.status
// //                                   ? productDetails.status.toUpperCase()
// //                                   : "Not Available"}
// //                               </span>
// //                               <span className="bg-orange1 text-dGrey3 text-xs font-medium me-2 px-3 py-1 rounded-full  ">
// //                                 {productDetails.store_name
// //                                   ? productDetails.store_name.toUpperCase()
// //                                   : "Not Available"}
// //                               </span>
// //                               <p className="leading-relaxed mb-4 mt-3">
// //                                 {productDetails.description
// //                                   ? productDetails.description
// //                                   : "Not Available"}
// //                               </p>
// //                               <div className="flex border-t border-gray-200 py-2">
// //                                 <span className="text-gray-500">
// //                                   Product Category
// //                                 </span>
// //                                 <span className="ml-auto text-dash">
// //                                   {productDetails.product_category
// //                                     ? productDetails.product_category.toUpperCase()
// //                                     : "Not Available"}
// //                                 </span>
// //                               </div>
// //                               <div className="flex border-t border-gray-200 py-2">
// //                                 <span className="text-gray-500">
// //                                   Sub Category
// //                                 </span>
// //                                 <span className="ml-auto text-dash">
// //                                   {productDetails.sub_category
// //                                     ? productDetails.sub_category.toUpperCase()
// //                                     : "Not Available"}
// //                                 </span>
// //                               </div>
// //                             </div>
// //                           </div>
// //                         </div>
// //                       </section>
// //                     </div>
// //                   </div>
// //                 </div>

// //                 {/* table for properties */}
// //                 <div className="p-2 w-full mt-4">
// //                   <h1 className="text-dash md:text-lg">Product Properties</h1>
// //                   <div className="flex items-center border-dash p-1 ">
// //                     <div className="relative shadow-md sm:rounded-lg w-full overflow-x-auto">
// //                       {" "}
// //                       {/* Added overflow-x-auto */}
// //                       <table className="min-w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 px-2 py-5">
// //                         <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
// //                           <tr>
// //                             <th
// //                               scope="col"
// //                               className="px-6 py-3 border border-darkGreen3 bg-darkGreen3 text-lGrey dark:border-gray-700"
// //                             >
// //                               Name
// //                             </th>
// //                             <th
// //                               scope="col"
// //                               className="px-6 py-3 border border-gray-300 bg-darkGreen3 text-lGrey dark:border-gray-700"
// //                             >
// //                               Description
// //                             </th>
// //                             <th
// //                               scope="col"
// //                               className="px-6 py-3 border border-gray-300 bg-darkGreen3 text-lGrey dark:border-gray-700"
// //                             >
// //                               Unit
// //                             </th>
// //                             <th
// //                               scope="col"
// //                               className="px-6 py-3 border border-gray-300 bg-darkGreen3 text-lGrey dark:border-gray-700"
// //                             >
// //                               Options
// //                             </th>
// //                           </tr>
// //                         </thead>
// //                         <tbody>
// //                           {productDetails.properties &&
// //                           productDetails.properties.length > 0 ? (
// //                             productDetails.properties.map((property, index) => (
// //                               <tr
// //                                 key={index}
// //                                 className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
// //                               >
// //                                 <th
// //                                   scope="row"
// //                                   className="px-6 py-4 border border-darkGreen3 font-medium text-gray-900 dark:text-white"
// //                                 >
// //                                   {property.name}
// //                                 </th>
// //                                 <td className="px-6 py-4 border border-darkGreen3 whitespace-nowrap">
// //                                   {property.description}
// //                                 </td>
// //                                 <td className="px-6 py-4 border border-darkGreen3">
// //                                   {property.unit}
// //                                 </td>
// //                                 <td className="px-6 py-4 border border-darkGreen3">
// //                                   {property.options
// //                                     ? property.options.join(", ")
// //                                     : "N/A"}
// //                                 </td>
// //                               </tr>
// //                             ))
// //                           ) : (
// //                             <tr>
// //                               <td colSpan="4" className="px-6 py-4 text-center">
// //                                 No properties available.
// //                               </td>
// //                             </tr>
// //                           )}
// //                         </tbody>
// //                       </table>
// //                     </div>
// //                   </div>
// //                 </div>

// //                 <ProductStockVariantTable
// //                   productDetails={productDetails} // Passing product details here
// //                   setReloadAfterDelete={setReloadAfterDelete} // Optionally pass this to re-fetch after delete
// //                 />
// //               </div>
// //             </section>
// //           )}
// //         </div>
// //       </MainLayouts>
// //     </div>
// //   );
// // };

// // export default ProductStockDetails;

// import { useState, useEffect } from "react";
// import MainLayouts from "../../Layout/MainLayouts";
// import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
// import Spinner from "../spinner/Spinner";
// import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
// import { ReactComponent as PlusSquareFill } from "bootstrap-icons/icons/plus-square-fill.svg";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import axios from "axios";
// import ApiServices from "../../api/ApiServices";
// import { toast } from "react-toastify";
// import { handleError } from "../../components/utils/AuthenticationCheck";
// import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
// import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
// import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
// import CurrencyFormatter from "../../components/utils/CurrencyFormatter";
// import Swal from "sweetalert2";
// import ProductStockVariantTable from "./ProductStockVariantTable";

// const ProductStockDetails = () => {
//   const [productDetails, setProductDetails] = useState({});
//   const [images, setImages] = useState([]);
//   const [productStockId, setProductStockId] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [confirmationLoading, setConfirmationLoading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [imagesPerPage] = useState(6);
//   const [reloadAfterDelete, setReloadAfterDelete] = useState(false);

//   const navigateTo = useNavigate();
//   const location = useLocation();
//   //get stoick id row data here
//   const { stock_id } = location.state || {};
//   // console.log("i am the stock id", stock_id);

//   useEffect(() => {
//     if (stock_id) {
//       setProductStockId(stock_id.stock_id || "");
//     }
//   }, [stock_id]);

//   useEffect(() => {
//     const fetchProductStockDetails = async (stock_id) => {
//       setLoading(true);
//       try {
//         const authData = JSON.parse(localStorage.getItem("auth"));
//         const token = authData?.token;
//         if (!token) {
//           throw new Error("No access token found.");
//         }

//         const response = await axios.get(ApiServices.getProductsStockById, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//           params: { stock_id },
//           withCredentials: true,
//         });

//         const { status, message, data } = response.data;
//         // console.log("stock product response", data);
//         if (status === 200) {
//           setProductDetails(data);
//           setImages(data.images || []);
//           // toast.success(message, { toastId: "product-details-fetch-success" });
//         } else {
//           handleError({ response: { status, data: { message } } }, navigateTo);
//         }
//       } catch (error) {
//         handleError(error, navigateTo);
//       } finally {
//         setLoading(false);
//         setReloadAfterDelete(false);
//       }
//     };

//     if (stock_id) {
//       fetchProductStockDetails(stock_id);
//     } else {
//       toast.error("Stock is missing.");
//       navigateTo("/admin/products/stock/list");
//     }
//   }, [stock_id, reloadAfterDelete]);

//   const handleDeleteProduct = async (image_id, stock_id) => {
//     // console.log("Attempting to delete:", image_id, stock_id);
//     const formData = {
//       image_id: image_id,
//       stock_id: stock_id,
//     };
//     try {
//       const authData = JSON.parse(localStorage.getItem("auth"));
//       const token = authData?.token;
//       if (!token) {
//         throw new Error("No access token found.");
//       }
//       const response = await axios.delete(
//         ApiServices.removeImagesProductStock,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//           data: formData,
//           withCredentials: true,
//         }
//       );

//       const { status, message } = response.data;
//       if (status === 200) {
//         setImages((prevImages) =>
//           prevImages.filter((img) => img.image_id !== image_id)
//         );
//         toast.success(message, {
//           toastId: "product-stock-deleted-successfully",
//         });
//         setReloadAfterDelete(true);
//       } else {
//         toast.error(message);
//       }
//     } catch (error) {
//       // toast.error("Error deleting product.");
//       console.error(error);
//     } finally {
//       setConfirmationLoading(false);
//     }
//   };

//   const handleDeleteConfirmation = (image) => {
//     setConfirmationLoading(true);
//     Swal.fire({
//       title: "Are you sure?",
//       text: "You won't be able to revert this!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#d33",
//       cancelButtonColor: "#3085d6",
//       confirmButtonText: "Yes, delete it!",
//     }).then((result) => {
//       setConfirmationLoading(false);
//       if (result.isConfirmed) {
//         // console.log("User confirmed deletion for:", image);
//         handleDeleteProduct(image.image_id, image.stock_id);
//       }
//     });
//   };

//   const indexOfLastImage = currentPage * imagesPerPage;
//   const indexOfFirstImage = indexOfLastImage - imagesPerPage;

//   // Change: Correctly retrieve images
//   const allImages = productDetails.images || []; // Updated
//   const currentImages = allImages.slice(indexOfFirstImage, indexOfLastImage);
//   // console.log("this are the curent images", currentImages);

//   const hasNextPage = indexOfLastImage < allImages.length;
//   const hasPrevPage = currentPage > 1;

//   return (
//     <div>
//       <MainLayouts>
//         <div className="relative sm:rounded-lg py-6 w-full">
//           <section
//             className="w-full pr-[3%] pt-3 my-3"
//             style={{ backgroundColor: "#F7F7F7" }}
//           >
//             <nav className="bg-gray-200 py-0 px-4 rounded-lg">
//               <ol className="list-none p-0 inline-flex mb-2">
//                 <li className="flex items-center">
//                   <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
//                     Product
//                   </p>
//                   <ChevronRightIcon />
//                 </li>

//                 <li className="flex items-center">
//                   <p className="text-blue-500 hover:text-blue-600 ">Stock</p>
//                   <ChevronRightIcon />
//                 </li>
//                 <li className="flex items-center">
//                   <p className="text-blue-500 hover:text-blue-600 ">Details</p>
//                 </li>
//               </ol>
//             </nav>
//           </section>

//           {loading ? (
//             <Spinner loading={loading} />
//           ) : (
//             <section className="text-gray-600 body-font">
//               <div className="container">
//                 <div className="flex justify-end mb-3">
//                   <Link
//                     to="/admin/products/stock/variant/create"
//                     // state={{ productStockId }}
//                     state={{
//                       productDetails,
//                     }}
//                     className=" mx-2 w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
//                   >
//                     <span className="flex justify-center sm:justify-start mx-2">
//                       <PlusSquareFill className="mr-2" />
//                       Add Properties
//                     </span>
//                   </Link>
//                   <Link
//                     to="/admin/products/stock/variant/create"
//                     // state={{ productStockId }}
//                     state={{
//                       productDetails,
//                     }}
//                     className=" mx-2 w-full sm:w-auto text-dGrey3 bg-orange1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
//                   >
//                     <span className="flex justify-center sm:justify-start mx-2">
//                       <PlusSquareFill className="mr-2" />
//                       Add Variant
//                     </span>
//                   </Link>
//                   <Link
//                     to="/admin/products/stock/list"
//                     className=" mx-2 w-full sm:w-auto text-dGrey3 bg-black1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
//                   >
//                     <span className="flex justify-center sm:justify-start mx-2">
//                       <ArrowLeftSquare className="mr-2" />
//                       Back
//                     </span>
//                   </Link>
//                 </div>
//                 <div className="grid grid-cols-12 gap-4">
//                   <div className="col-span-12 lg:col-span-12 p-2">
//                     {/* Image display */}
//                     <div className="grid grid-cols-2 sm:grid-cols-6 gap-4 mt-4">
//                       {currentImages.length > 0 ? (
//                         currentImages.map((image, index) => (
//                           <div
//                             key={index}
//                             className="overflow-hidden shadow-md bg-dGrey3"
//                           >
//                             <div className="h-full flex items-center border-dGrey3 border p-1.5 rounded-lg ">
//                               <div className="flex-grow ">
//                                 <h2 className="flex items-center justify-between text-darkGreen3 title-font font-medium">
//                                   <small className="text-darkGreen3">
//                                     {image.alt_text}
//                                   </small>
//                                   <span className="flex items-center">
//                                     <Link
//                                       onClick={(e) => {
//                                         e.preventDefault();
//                                         handleDeleteConfirmation(image);
//                                       }}
//                                       disabled={confirmationLoading}
//                                       className="text-dash5 mr-2 border border-dGrey3 rounded-md p-2"
//                                     >
//                                       <Trash className="w-5 h-5 text-dash3 " />
//                                     </Link>
//                                   </span>
//                                 </h2>

//                                 <p className="text-gray-500 flex items-center ">
//                                   <img
//                                     src={image.image_url}
//                                     alt={image.alt_text}
//                                     className="w-full h-32 object-cover p-4"
//                                   />
//                                 </p>
//                               </div>
//                             </div>
//                           </div>
//                         ))
//                       ) : (
//                         <div className="col-span-full text-center">
//                           No images available.
//                         </div>
//                       )}
//                     </div>

//                     {/* Pagination Controls */}
//                     <div className="flex justify-end mt-6">
//                       <nav aria-label="Page navigation">
//                         <ul className="pagination flex mx-auto">
//                           <li
//                             className={`page-item ${
//                               !hasPrevPage ? "disabled" : ""
//                             }`}
//                           >
//                             <button
//                               className="text-dGrey3 bg-black1 hover:bg-darkGreen2 font-medium rounded-full text-sm px-5 py-2"
//                               onClick={() =>
//                                 setCurrentPage((prev) => Math.max(prev - 1, 1))
//                               }
//                               disabled={!hasPrevPage}
//                             >
//                               Prev
//                             </button>
//                           </li>
//                           <li
//                             className={`page-item ${
//                               !hasNextPage ? "disabled" : ""
//                             }`}
//                           >
//                             <button
//                               className="text-dGrey3 bg-black1 hover:bg-darkGreen2 font-medium rounded-full text-sm px-5 py-2"
//                               onClick={() => setCurrentPage((prev) => prev + 1)}
//                               disabled={!hasNextPage}
//                             >
//                               Next
//                             </button>
//                           </li>
//                         </ul>
//                       </nav>
//                     </div>
//                   </div>

//                   <div className="col-span-12 lg:col-span-12 p-2">
//                     <div className="flex items-center border-dash border p-1 rounded-lg border-l-4">
//                       <section className="body-font w-full">
//                         <div className="container px-2">
//                           <div className="w-full flex flex-wrap">
//                             <div className="w-full px-4">
//                               <h2 className="text-sm title-font mt-2">
//                                 {productDetails.name
//                                   ? productDetails.name.toUpperCase()
//                                   : "Not Available"}
//                               </h2>
//                               <h1 className="text-dash text-3xl title-font font-medium mb-1">
//                                 {productDetails.category
//                                   ? productDetails.category.toUpperCase()
//                                   : "Not Available"}
//                               </h1>
//                               <span className="bg-dash text-dGrey3 text-xs font-medium me-2 px-3 py-1 rounded-full ">
//                                 {productDetails.status
//                                   ? productDetails.status.toUpperCase()
//                                   : "Not Available"}
//                               </span>
//                               <span className="bg-orange1 text-dGrey3 text-xs font-medium me-2 px-3 py-1 rounded-full  ">
//                                 {productDetails.store_name
//                                   ? productDetails.store_name.toUpperCase()
//                                   : "Not Available"}
//                               </span>
//                               <p className="leading-relaxed mb-4 mt-3">
//                                 {productDetails.description
//                                   ? productDetails.description
//                                   : "Not Available"}
//                               </p>
//                               <div className="flex border-t border-gray-200 py-2">
//                                 <span className="text-gray-500">
//                                   Product Category
//                                 </span>
//                                 <span className="ml-auto text-dash">
//                                   {productDetails.product_category
//                                     ? productDetails.product_category.toUpperCase()
//                                     : "Not Available"}
//                                 </span>
//                               </div>
//                               <div className="flex border-t border-gray-200 py-2">
//                                 <span className="text-gray-500">
//                                   Sub Category
//                                 </span>
//                                 <span className="ml-auto text-dash">
//                                   {productDetails.sub_category
//                                     ? productDetails.sub_category.toUpperCase()
//                                     : "Not Available"}
//                                 </span>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </section>
//                     </div>
//                   </div>
//                 </div>

//                 {/* table for properties */}
//                 <div className="p-2 w-full mt-4">
//                   <h1 className="text-dash md:text-lg">Product Properties</h1>
//                   <div className="flex items-center border-dash p-1 ">
//                     <div className="relative shadow-md sm:rounded-lg w-full overflow-x-auto">
//                       {" "}
//                       {/* Added overflow-x-auto */}
//                       <table className="min-w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 px-2 py-5">
//                         <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
//                           <tr>
//                             <th
//                               scope="col"
//                               className="px-6 py-3 border border-darkGreen3 bg-darkGreen3 text-lGrey dark:border-gray-700"
//                             >
//                               Name
//                             </th>
//                             <th
//                               scope="col"
//                               className="px-6 py-3 border border-gray-300 bg-darkGreen3 text-lGrey dark:border-gray-700"
//                             >
//                               Description
//                             </th>
//                             <th
//                               scope="col"
//                               className="px-6 py-3 border border-gray-300 bg-darkGreen3 text-lGrey dark:border-gray-700"
//                             >
//                               Unit
//                             </th>
//                             <th
//                               scope="col"
//                               className="px-6 py-3 border border-gray-300 bg-darkGreen3 text-lGrey dark:border-gray-700"
//                             >
//                               Options
//                             </th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {productDetails.properties &&
//                           productDetails.properties.length > 0 ? (
//                             productDetails.properties.map((property, index) => (
//                               <tr
//                                 key={index}
//                                 className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
//                               >
//                                 <th
//                                   scope="row"
//                                   className="px-6 py-4 border border-darkGreen3 font-medium text-gray-900 dark:text-white"
//                                 >
//                                   {property.name}
//                                 </th>
//                                 <td className="px-6 py-4 border border-darkGreen3 whitespace-nowrap">
//                                   {property.description}
//                                 </td>
//                                 <td className="px-6 py-4 border border-darkGreen3">
//                                   {property.unit}
//                                 </td>
//                                 <td className="px-6 py-4 border border-darkGreen3">
//                                   {property.options
//                                     ? property.options.join(", ")
//                                     : "N/A"}
//                                 </td>
//                               </tr>
//                             ))
//                           ) : (
//                             <tr>
//                               <td colSpan="4" className="px-6 py-4 text-center">
//                                 No properties available.
//                               </td>
//                             </tr>
//                           )}
//                         </tbody>
//                       </table>
//                     </div>
//                   </div>
//                 </div>

//                 <ProductStockVariantTable
//                   productDetails={productDetails} // Passing product details here
//                   // setReloadAfterDelete={setReloadAfterDelete} // Optionally pass this to re-fetch after delete
//                 />
//               </div>
//             </section>
//           )}
//         </div>
//       </MainLayouts>
//     </div>
//   );
// };

// export default ProductStockDetails;

// import { useState, useEffect } from "react";
// import MainLayouts from "../../Layout/MainLayouts";
// import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
// import Spinner from "../spinner/Spinner";
// import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
// import { ReactComponent as PlusSquareFill } from "bootstrap-icons/icons/plus-square-fill.svg";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import axios from "axios";
// import ApiServices from "../../api/ApiServices";
// import { toast } from "react-toastify";
// import { handleError } from "../../components/utils/AuthenticationCheck";
// import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
// import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
// import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
// import CurrencyFormatter from "../../components/utils/CurrencyFormatter";
// import Swal from "sweetalert2";
// import ProductStockVariantTable from "./ProductStockVariantTable";

// const ProductStockDetails = () => {
//   const [productDetails, setProductDetails] = useState({});
//   const [images, setImages] = useState([]);
//   const [productStockId, setProductStockId] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [confirmationLoading, setConfirmationLoading] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [imagesPerPage] = useState(6);
//   const [reloadAfterDelete, setReloadAfterDelete] = useState(false);

//   const navigateTo = useNavigate();
//   const location = useLocation();
//   //get stoick id row data here
//   const { stock_id } = location.state || {};
//   // console.log("i am the stock id", stock_id);

//   useEffect(() => {
//     if (stock_id) {
//       setProductStockId(stock_id.stock_id || "");
//     }
//   }, [stock_id]);

//   useEffect(() => {
//     const fetchProductStockDetails = async (stock_id) => {
//       setLoading(true);
//       try {
//         const authData = JSON.parse(localStorage.getItem("auth"));
//         const token = authData?.token;
//         if (!token) {
//           throw new Error("No access token found.");
//         }

//         const response = await axios.get(ApiServices.getProductsStockById, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//           params: { stock_id },
//           withCredentials: true,
//         });

//         const { status, message, data } = response.data;
//         // console.log("stock product response", data);
//         if (status === 200) {
//           setProductDetails(data);
//           setImages(data.images || []);
//           toast.success(message, { toastId: "product-details-fetch-success" });
//         } else {
//           handleError({ response: { status, data: { message } } }, navigateTo);
//         }
//       } catch (error) {
//         handleError(error, navigateTo);
//       } finally {
//         setLoading(false);
//         setReloadAfterDelete(false);
//       }
//     };

//     if (stock_id) {
//       fetchProductStockDetails(stock_id);
//     } else {
//       toast.error("Stock is missing.");
//       navigateTo("/admin/products/stock/list");
//     }
//   }, [stock_id, reloadAfterDelete]);

//   const handleDeleteProduct = async (image_id, stock_id) => {
//     // console.log("Attempting to delete:", image_id, stock_id);
//     const formData = {
//       image_id: image_id,
//       stock_id: stock_id,
//     };
//     try {
//       const authData = JSON.parse(localStorage.getItem("auth"));
//       const token = authData?.token;
//       if (!token) {
//         throw new Error("No access token found.");
//       }
//       const response = await axios.delete(
//         ApiServices.removeImagesProductStock,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//           data: formData,
//           withCredentials: true,
//         }
//       );

//       const { status, message } = response.data;
//       if (status === 200) {
//         setImages((prevImages) =>
//           prevImages.filter((img) => img.image_id !== image_id)
//         );
//         toast.success(message, {
//           toastId: "product-stock-deleted-successfully",
//         });
//         setReloadAfterDelete(true);
//       } else {
//         toast.error(message);
//       }
//     } catch (error) {
//       // toast.error("Error deleting product.");
//       console.error(error);
//     } finally {
//       setConfirmationLoading(false);
//     }
//   };

//   const handleDeleteConfirmation = (image) => {
//     setConfirmationLoading(true);
//     Swal.fire({
//       title: "Are you sure?",
//       text: "You won't be able to revert this!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#d33",
//       cancelButtonColor: "#3085d6",
//       confirmButtonText: "Yes, delete it!",
//     }).then((result) => {
//       setConfirmationLoading(false);
//       if (result.isConfirmed) {
//         // console.log("User confirmed deletion for:", image);
//         handleDeleteProduct(image.image_id, image.stock_id);
//       }
//     });
//   };

//   const indexOfLastImage = currentPage * imagesPerPage;
//   const indexOfFirstImage = indexOfLastImage - imagesPerPage;

//   // Change: Correctly retrieve images
//   const allImages = productDetails.images || []; // Updated
//   const currentImages = allImages.slice(indexOfFirstImage, indexOfLastImage);
//   // console.log("this are the curent images", currentImages);

//   const hasNextPage = indexOfLastImage < allImages.length;
//   const hasPrevPage = currentPage > 1;

//   return (
//     <div>
//       <MainLayouts>
//         <div className="relative sm:rounded-lg py-6 w-full">
//           <section
//             className="w-full pr-[3%] pt-3 my-3"
//             style={{ backgroundColor: "#F7F7F7" }}
//           >
//             <nav className="bg-gray-200 py-0 px-4 rounded-lg">
//               <ol className="list-none p-0 inline-flex mb-2">
//                 <li className="flex items-center">
//                   <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
//                     Product
//                   </p>
//                   <ChevronRightIcon />
//                 </li>

//                 <li className="flex items-center">
//                   <p className="text-blue-500 hover:text-blue-600 ">Stock</p>
//                   <ChevronRightIcon />
//                 </li>
//                 <li className="flex items-center">
//                   <p className="text-blue-500 hover:text-blue-600 ">Details</p>
//                 </li>
//               </ol>
//             </nav>
//           </section>

//           {loading ? (
//             <Spinner loading={loading} />
//           ) : (
//             <section className="text-gray-600 body-font">
//               <div className="container">
//                 <div className="flex justify-end mb-3">
//                   <Link
//                     to="/admin/products/stock/variant/create"
//                     // state={{ productStockId }}
//                     state={{
//                       productDetails,
//                     }}
//                     className=" mx-2 w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
//                   >
//                     <span className="flex justify-center sm:justify-start mx-2">
//                       <PlusSquareFill className="mr-2" />
//                       Add Properties
//                     </span>
//                   </Link>
//                   <Link
//                     to="/admin/products/stock/variant/create"
//                     // state={{ productStockId }}
//                     state={{
//                       productDetails,
//                     }}
//                     className=" mx-2 w-full sm:w-auto text-dGrey3 bg-orange1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
//                   >
//                     <span className="flex justify-center sm:justify-start mx-2">
//                       <PlusSquareFill className="mr-2" />
//                       Add Variant
//                     </span>
//                   </Link>
//                   <Link
//                     to="/admin/products/stock/list"
//                     className=" mx-2 w-full sm:w-auto text-dGrey3 bg-black1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
//                   >
//                     <span className="flex justify-center sm:justify-start mx-2">
//                       <ArrowLeftSquare className="mr-2" />
//                       Back
//                     </span>
//                   </Link>
//                 </div>
//                 <div className="grid grid-cols-12 gap-4">
//                   <div className="col-span-12 lg:col-span-12 p-2">
//                     {/* Image display */}
//                     <div className="grid grid-cols-2 sm:grid-cols-6 gap-4 mt-4">
//                       {currentImages.length > 0 ? (
//                         currentImages.map((image, index) => (
//                           <div
//                             key={index}
//                             className="overflow-hidden shadow-md bg-dGrey3"
//                           >
//                             <div className="h-full flex items-center border-dGrey3 border p-1.5 rounded-lg ">
//                               <div className="flex-grow ">
//                                 <h2 className="flex items-center justify-between text-darkGreen3 title-font font-medium">
//                                   <small className="text-darkGreen3">
//                                     {image.alt_text}
//                                   </small>
//                                   <span className="flex items-center">
//                                     <Link
//                                       onClick={(e) => {
//                                         e.preventDefault();
//                                         handleDeleteConfirmation(image);
//                                       }}
//                                       disabled={confirmationLoading}
//                                       className="text-dash5 mr-2 border border-dGrey3 rounded-md p-2"
//                                     >
//                                       <Trash className="w-5 h-5 text-dash3 " />
//                                     </Link>
//                                   </span>
//                                 </h2>

//                                 <p className="text-gray-500 flex items-center ">
//                                   <img
//                                     src={image.image_url}
//                                     alt={image.alt_text}
//                                     className="w-full h-32 object-cover p-4"
//                                   />
//                                 </p>
//                               </div>
//                             </div>
//                           </div>
//                         ))
//                       ) : (
//                         <div className="col-span-full text-center">
//                           No images available.
//                         </div>
//                       )}
//                     </div>

//                     {/* Pagination Controls */}
//                     <div className="flex justify-end mt-6">
//                       <nav aria-label="Page navigation">
//                         <ul className="pagination flex mx-auto">
//                           <li
//                             className={`page-item ${
//                               !hasPrevPage ? "disabled" : ""
//                             }`}
//                           >
//                             <button
//                               className="text-dGrey3 bg-black1 hover:bg-darkGreen2 font-medium rounded-full text-sm px-5 py-2"
//                               onClick={() =>
//                                 setCurrentPage((prev) => Math.max(prev - 1, 1))
//                               }
//                               disabled={!hasPrevPage}
//                             >
//                               Prev
//                             </button>
//                           </li>
//                           <li
//                             className={`page-item ${
//                               !hasNextPage ? "disabled" : ""
//                             }`}
//                           >
//                             <button
//                               className="text-dGrey3 bg-black1 hover:bg-darkGreen2 font-medium rounded-full text-sm px-5 py-2"
//                               onClick={() => setCurrentPage((prev) => prev + 1)}
//                               disabled={!hasNextPage}
//                             >
//                               Next
//                             </button>
//                           </li>
//                         </ul>
//                       </nav>
//                     </div>
//                   </div>

//                   <div className="col-span-12 lg:col-span-12 p-2">
//                     <div className="flex items-center border-dash border p-1 rounded-lg border-l-4">
//                       <section className="body-font w-full">
//                         <div className="container px-2">
//                           <div className="w-full flex flex-wrap">
//                             <div className="w-full px-4">
//                               <h2 className="text-sm title-font mt-2">
//                                 {productDetails.name
//                                   ? productDetails.name.toUpperCase()
//                                   : "Not Available"}
//                               </h2>
//                               <h1 className="text-dash text-3xl title-font font-medium mb-1">
//                                 {productDetails.category
//                                   ? productDetails.category.toUpperCase()
//                                   : "Not Available"}
//                               </h1>
//                               <span className="bg-dash text-dGrey3 text-xs font-medium me-2 px-3 py-1 rounded-full ">
//                                 {productDetails.status
//                                   ? productDetails.status.toUpperCase()
//                                   : "Not Available"}
//                               </span>
//                               <span className="bg-orange1 text-dGrey3 text-xs font-medium me-2 px-3 py-1 rounded-full  ">
//                                 {productDetails.store_name
//                                   ? productDetails.store_name.toUpperCase()
//                                   : "Not Available"}
//                               </span>
//                               <p className="leading-relaxed mb-4 mt-3">
//                                 {productDetails.description
//                                   ? productDetails.description
//                                   : "Not Available"}
//                               </p>
//                               <div className="flex border-t border-gray-200 py-2">
//                                 <span className="text-gray-500">
//                                   Product Category
//                                 </span>
//                                 <span className="ml-auto text-dash">
//                                   {productDetails.product_category
//                                     ? productDetails.product_category.toUpperCase()
//                                     : "Not Available"}
//                                 </span>
//                               </div>
//                               <div className="flex border-t border-gray-200 py-2">
//                                 <span className="text-gray-500">
//                                   Sub Category
//                                 </span>
//                                 <span className="ml-auto text-dash">
//                                   {productDetails.sub_category
//                                     ? productDetails.sub_category.toUpperCase()
//                                     : "Not Available"}
//                                 </span>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </section>
//                     </div>
//                   </div>
//                 </div>

//                 {/* table for properties */}
//                 <div className="p-2 w-full mt-4">
//                   <h1 className="text-dash md:text-lg">Product Properties</h1>
//                   <div className="flex items-center border-dash p-1 ">
//                     <div className="relative shadow-md sm:rounded-lg w-full overflow-x-auto">
//                       {" "}
//                       {/* Added overflow-x-auto */}
//                       <table className="min-w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 px-2 py-5">
//                         <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
//                           <tr>
//                             <th
//                               scope="col"
//                               className="px-6 py-3 border border-darkGreen3 bg-darkGreen3 text-lGrey dark:border-gray-700"
//                             >
//                               Name
//                             </th>
//                             <th
//                               scope="col"
//                               className="px-6 py-3 border border-gray-300 bg-darkGreen3 text-lGrey dark:border-gray-700"
//                             >
//                               Description
//                             </th>
//                             <th
//                               scope="col"
//                               className="px-6 py-3 border border-gray-300 bg-darkGreen3 text-lGrey dark:border-gray-700"
//                             >
//                               Unit
//                             </th>
//                             <th
//                               scope="col"
//                               className="px-6 py-3 border border-gray-300 bg-darkGreen3 text-lGrey dark:border-gray-700"
//                             >
//                               Options
//                             </th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {productDetails.properties &&
//                           productDetails.properties.length > 0 ? (
//                             productDetails.properties.map((property, index) => (
//                               <tr
//                                 key={index}
//                                 className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
//                               >
//                                 <th
//                                   scope="row"
//                                   className="px-6 py-4 border border-darkGreen3 font-medium text-gray-900 dark:text-white"
//                                 >
//                                   {property.name}
//                                 </th>
//                                 <td className="px-6 py-4 border border-darkGreen3 whitespace-nowrap">
//                                   {property.description}
//                                 </td>
//                                 <td className="px-6 py-4 border border-darkGreen3">
//                                   {property.unit}
//                                 </td>
//                                 <td className="px-6 py-4 border border-darkGreen3">
//                                   {property.options
//                                     ? property.options.join(", ")
//                                     : "N/A"}
//                                 </td>
//                               </tr>
//                             ))
//                           ) : (
//                             <tr>
//                               <td colSpan="4" className="px-6 py-4 text-center">
//                                 No properties available.
//                               </td>
//                             </tr>
//                           )}
//                         </tbody>
//                       </table>
//                     </div>
//                   </div>
//                 </div>

//                 <ProductStockVariantTable
//                   productDetails={productDetails} // Passing product details here
//                   setReloadAfterDelete={setReloadAfterDelete} // Optionally pass this to re-fetch after delete
//                 />
//               </div>
//             </section>
//           )}
//         </div>
//       </MainLayouts>
//     </div>
//   );
// };

// export default ProductStockDetails;

import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as ArrowLeftSquare } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import Spinner from "../spinner/Spinner";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { ReactComponent as PlusSquareFill } from "bootstrap-icons/icons/plus-square-fill.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import { handleError } from "../../components/utils/AuthenticationCheck";
import { ReactComponent as Trash } from "bootstrap-icons/icons/trash3.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import CurrencyFormatter from "../../components/utils/CurrencyFormatter";
import Swal from "sweetalert2";
import ProductStockVariantTable from "./ProductStockVariantTable";

const ProductStockDetails = () => {
  const [productDetails, setProductDetails] = useState({});
  const [images, setImages] = useState([]);
  const [productStockId, setProductStockId] = useState("");
  const [loading, setLoading] = useState(true);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [imagesPerPage] = useState(6);
  const [reloadAfterDelete, setReloadAfterDelete] = useState(false);

  const navigateTo = useNavigate();
  const location = useLocation();
  //get stoick id row data here
  const { stock_id } = location.state || {};
  // console.log("i am the stock id", stock_id);

  useEffect(() => {
    if (stock_id) {
      setProductStockId(stock_id.stock_id || "");
    }
  }, [stock_id]);

  useEffect(() => {
    const fetchProductStockDetails = async (stock_id) => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }

        const response = await axios.get(ApiServices.getProductsStockById, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: { stock_id },
          withCredentials: true,
        });

        const { status, message, data } = response.data;
        // console.log("stock product response", data);
        if (status === 200) {
          setProductDetails(data);
          setImages(data.images || []);
          // toast.success(message, { toastId: "product-details-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
        setReloadAfterDelete(false);
      }
    };

    if (stock_id) {
      fetchProductStockDetails(stock_id);
    } else {
      toast.error("Stock is missing.");
      navigateTo("/admin/products/stock/list");
    }
  }, [stock_id, reloadAfterDelete]);

  const handleDeleteProduct = async (image_id, stock_id) => {
    // console.log("Attempting to delete:", image_id, stock_id);
    const formData = {
      image_id: image_id,
      stock_id: stock_id,
    };
    try {
      const authData = JSON.parse(localStorage.getItem("auth"));
      const token = authData?.token;
      if (!token) {
        throw new Error("No access token found.");
      }
      const response = await axios.delete(
        ApiServices.removeImagesProductStock,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: formData,
          withCredentials: true,
        }
      );

      const { status, message } = response.data;
      if (status === 200) {
        setImages((prevImages) =>
          prevImages.filter((img) => img.image_id !== image_id)
        );
        toast.success(message, {
          toastId: "product-stock-deleted-successfully",
        });
        setReloadAfterDelete(true);
      } else {
        toast.error(message);
      }
    } catch (error) {
      // toast.error("Error deleting product.");
      console.error(error);
    } finally {
      setConfirmationLoading(false);
    }
  };

  const handleDeleteConfirmation = (image) => {
    setConfirmationLoading(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      setConfirmationLoading(false);
      if (result.isConfirmed) {
        // console.log("User confirmed deletion for:", image);
        handleDeleteProduct(image.image_id, image.stock_id);
      }
    });
  };

  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;

  // Change: Correctly retrieve images
  const allImages = productDetails.images || []; // Updated
  const currentImages = allImages.slice(indexOfFirstImage, indexOfLastImage);
  // console.log("this are the curent images", currentImages);

  const hasNextPage = indexOfLastImage < allImages.length;
  const hasPrevPage = currentPage > 1;

  return (
    <div>
      <MainLayouts>
        <div className="relative sm:rounded-lg py-6 w-full">
          <section
            className="w-full pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-4 rounded-lg">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3 pr-2">
                    Product
                  </p>
                  <ChevronRightIcon />
                </li>

                <li className="flex items-center">
                  <p className="text-darkGreen3">Stock</p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">Details</p>
                </li>
              </ol>
            </nav>
          </section>

          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <section className="text-gray-600 body-font">
              <div className="container">
                <div className="flex justify-end mb-3">
                  {/* //will chwcheck this later */}
                  {/* <Link
                    to="/admin/products/stock/variant/create"
                    // state={{ productStockId }}
                    state={{
                      productDetails,
                    }}
                    className=" mx-2 w-full sm:w-auto text-dGrey3 bg-darkGreen3 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    <span className="flex justify-center sm:justify-start mx-2">
                      <PlusSquareFill className="mr-2" />
                      Add Properties
                    </span>
                  </Link> */}
                  <Link
                    to="/admin/products/stock/variant/create"
                    // state={{ productStockId }}
                    state={{
                      productDetails,
                    }}
                    className=" mx-2 w-full sm:w-auto text-dGrey3 bg-orange1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    <span className="flex justify-center sm:justify-start mx-2">
                      <PlusSquareFill className="mr-2" />
                      Add Variant
                    </span>
                  </Link>
                  <Link
                    to="/admin/products/stock/list"
                    className=" mx-2 w-full sm:w-auto text-dGrey3 bg-black1 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    <span className="flex justify-center sm:justify-start mx-2">
                      <ArrowLeftSquare className="mr-2" />
                      Back
                    </span>
                  </Link>
                </div>
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12 lg:col-span-12 p-2">
                    {/* Image display */}
                    <div className="grid grid-cols-2 sm:grid-cols-6 gap-4 mt-4">
                      {currentImages.length > 0 ? (
                        currentImages.map((image, index) => (
                          <div
                            key={index}
                            className="overflow-hidden shadow-md bg-dGrey3"
                          >
                            <div className="h-full flex items-center border-dGrey3 border p-1.5 rounded-lg ">
                              <div className="flex-grow ">
                                <h2 className="flex items-center justify-between text-darkGreen3 title-font font-medium">
                                  <small className="text-darkGreen3">
                                    {image.alt_text}
                                  </small>
                                  <span className="flex items-center">
                                    <Link
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleDeleteConfirmation(image);
                                      }}
                                      disabled={confirmationLoading}
                                      className="text-dash5 mr-2 border border-dGrey3 rounded-md p-2"
                                    >
                                      <Trash className="w-5 h-5 text-dash3 " />
                                    </Link>
                                  </span>
                                </h2>

                                <p className="text-gray-500 flex items-center ">
                                  <img
                                    src={image.image_url}
                                    alt={image.alt_text}
                                    className="w-full h-32 object-cover p-4"
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="col-span-full text-center">
                          No images available.
                        </div>
                      )}
                    </div>

                    {/* Pagination Controls */}
                    <div className="flex justify-end mt-6">
                      <nav aria-label="Page navigation">
                        <ul className="pagination flex mx-auto">
                          <li
                            className={`page-item ${
                              !hasPrevPage ? "disabled" : ""
                            }`}
                          >
                            <button
                              className="text-dGrey3 bg-black1 hover:bg-darkGreen2 font-medium rounded-full text-sm px-5 py-2"
                              onClick={() =>
                                setCurrentPage((prev) => Math.max(prev - 1, 1))
                              }
                              disabled={!hasPrevPage}
                            >
                              Prev
                            </button>
                          </li>
                          <li
                            className={`page-item ${
                              !hasNextPage ? "disabled" : ""
                            }`}
                          >
                            <button
                              className="text-dGrey3 bg-black1 hover:bg-darkGreen2 font-medium rounded-full text-sm px-5 py-2"
                              onClick={() => setCurrentPage((prev) => prev + 1)}
                              disabled={!hasNextPage}
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>

                  <div className="col-span-12 lg:col-span-12 p-2">
                    <div className="flex items-center border-dash border p-1 rounded-lg border-l-4">
                      <section className="body-font w-full">
                        <div className="container px-2">
                          <div className="w-full flex flex-wrap">
                            <div className="w-full px-4">
                              <h2 className="text-sm title-font mt-2">
                                {productDetails.name
                                  ? productDetails.name.toUpperCase()
                                  : "Not Available"}
                              </h2>
                              <h1 className="text-dash text-3xl title-font font-medium mb-1">
                                {productDetails.category
                                  ? productDetails.category.toUpperCase()
                                  : "Not Available"}
                              </h1>
                              <span className="bg-dash text-dGrey3 text-xs font-medium me-2 px-3 py-1 rounded-full ">
                                {productDetails.status
                                  ? productDetails.status.toUpperCase()
                                  : "Not Available"}
                              </span>
                              <span className="bg-orange1 text-dGrey3 text-xs font-medium me-2 px-3 py-1 rounded-full  ">
                                {productDetails.store_name
                                  ? productDetails.store_name.toUpperCase()
                                  : "Not Available"}
                              </span>
                              <p className="leading-relaxed mb-4 mt-3">
                                {productDetails.description
                                  ? productDetails.description
                                  : "Not Available"}
                              </p>
                              <div className="flex border-t border-gray-200 py-2">
                                <span className="text-gray-500">
                                  Product Category
                                </span>
                                <span className="ml-auto text-dash">
                                  {productDetails.product_category
                                    ? productDetails.product_category.toUpperCase()
                                    : "Not Available"}
                                </span>
                              </div>
                              <div className="flex border-t border-gray-200 py-2">
                                <span className="text-gray-500">
                                  Sub Category
                                </span>
                                <span className="ml-auto text-dash">
                                  {productDetails.sub_category
                                    ? productDetails.sub_category.toUpperCase()
                                    : "Not Available"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>

                {/* table for properties */}
                <div className="p-2 w-full mt-4">
                  <h1 className="text-dash md:text-lg">Product Properties</h1>
                  <div className="flex items-center border-dash p-1 ">
                    <div className="relative shadow-md sm:rounded-lg w-full overflow-x-auto">
                      {" "}
                      {/* Added overflow-x-auto */}
                      <table className="min-w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 px-2 py-5">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 border border-darkGreen3 bg-darkGreen3 text-lGrey dark:border-gray-700"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 border border-gray-300 bg-darkGreen3 text-lGrey dark:border-gray-700"
                            >
                              Description
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 border border-gray-300 bg-darkGreen3 text-lGrey dark:border-gray-700"
                            >
                              Unit
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 border border-gray-300 bg-darkGreen3 text-lGrey dark:border-gray-700"
                            >
                              Options
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {productDetails.properties &&
                          productDetails.properties.length > 0 ? (
                            productDetails.properties.map((property, index) => (
                              <tr
                                key={index}
                                className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                              >
                                <th
                                  scope="row"
                                  className="px-6 py-4 border border-darkGreen3 font-medium text-gray-900 dark:text-white"
                                >
                                  {property.name}
                                </th>
                                <td className="px-6 py-4 border border-darkGreen3 whitespace-nowrap">
                                  {property.description}
                                </td>
                                <td className="px-6 py-4 border border-darkGreen3">
                                  {property.unit}
                                </td>
                                <td className="px-6 py-4 border border-darkGreen3">
                                  {property.options
                                    ? property.options.join(", ")
                                    : "N/A"}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" className="px-6 py-4 text-center">
                                No properties available.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <ProductStockVariantTable
                  productDetails={productDetails} // Passing product details here
                  // setReloadAfterDelete={setReloadAfterDelete} // Optionally pass this to re-fetch after delete
                />
              </div>
            </section>
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default ProductStockDetails;

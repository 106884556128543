import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./AuthProvider";

const PrivateRoute = ({ children }) => {
  const { auth } = useContext(AuthContext);

  // console.log("Auth state in PrivateRoute:", auth); 

  return auth ? children : <Navigate to="/" replace />;
};

export default PrivateRoute;

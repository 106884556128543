import { useState, useEffect } from "react";
import MainLayouts from "../../Layout/MainLayouts";
import { ReactComponent as Eye } from "bootstrap-icons/icons/eye.svg";
import { ReactComponent as ChevronRightIcon } from "bootstrap-icons/icons/chevron-right.svg";
import { ReactComponent as PencilSquare } from "bootstrap-icons/icons/pencil-square.svg";
import Spinner from "../spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { handleError } from "../../components/utils/AuthenticationCheck";

import axios from "axios";
import ApiServices from "../../api/ApiServices";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import CustomStyles from "../../components/utils/CustomStyles";
import formatDate from "../../components/utils/DateFormatter";

const VendorList = () => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const navigateTo = useNavigate();

  // ==========================- fetch all vendors =================
  useEffect(() => {
    const fetchAllvendorList = async () => {
      setLoading(true);
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));
        const token = authData?.token;
        if (!token) {
          throw new Error("No access token found.");
        }
        // console.log("i am token", token);
        const response = await axios.get(ApiServices.getAllVendor, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        // console.log("this is all vendor", response.data);
        const { status, message, data } = response.data;
        if (status === 200) {
          setVendors(data);
          toast.success(message, { toastId: "vendor-fetch-success" });
        } else {
          handleError({ response: { status, data: { message } } }, navigateTo);
        }
      } catch (error) {
        handleError(error, navigateTo);
      } finally {
        setLoading(false);
      }
    };

    fetchAllvendorList();
  }, [navigateTo]);
  // ================================== end of list of vendor ==========================================

  //filter data by name
  const handleFilter = (e) => {
    setFilterText(e.target.value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const columns = [
    {
      name: "Details",
      cell: (row) => (
        <div className="d-flex justify-content-end ">
          <Link
            to="/admin/vendor/details"
            state={{ vendor_id: row.vendor_id }}
            className="btn btn-info btn-sm"
          >
            <Eye className="w-5 h-5 text-dash4 " />
          </Link>
        </div>
      ),
      width: "80px",
    },
    {
      name: "Edit",
      cell: (row) => (
        <div className="d-flex justify-content-end buttons">
          <Link
            to="/admin/vendor/update"
            state={{ vendorId: row }}
            className="btn btn-info btn-sm"
          >
            <PencilSquare className="w-5 h-5 text-dash4 mr-3" />
          </Link>
        </div>
      ),
      width: "80px",
    },

    {
      name: "Full Name",
      sortable: true,
      width: "200px",
      cell: (row) => (
        <div>
          <span className="block font-bold">
            {row.firstname && row.lastname
              ? `${row.firstname.toUpperCase()} ${row.lastname.toUpperCase()}`
              : "NOT AVAILABLE"}
          </span>
        </div>
      ),
    },

    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "300px",
    },

    {
      name: "Phone",
      selector: (row) => row.phone_number,
      sortable: true,
      width: "120px",
    },
  ];

  const filteredItems = vendors.filter((item) => {
    for (let key in item) {
      if (
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(filterText.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });

  const data = filteredItems.map((vendor) => ({
    created_at: formatDate(vendor.created_at),
    firstname: vendor.firstname
      ? vendor.firstname.toUpperCase()
      : "NOT AVAILABLE",
    lastname: vendor.lastname ? vendor.lastname.toUpperCase() : "NOT AVAILABLE",
    email: vendor.email ? vendor.email.toUpperCase() : "NOT AVAILABLE",
    phone_number: vendor.phone_number ? vendor.phone_number : "NOT AVAILABLE",
    status: vendor.status ? vendor.status.toUpperCase() : "NOT AVAILABLE",
    vendor_id: vendor.vendor_id,
  }));

  // Sort data based on creation date
  const sortedData = [...data].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <div>
      <MainLayouts>
        <div className="relative overflow-x-auto sm:rounded-lg py-6 w-full">
          <section
            className="w-full pl-[3%] pr-[3%] pt-3 my-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <nav className="bg-gray-200 py-0 px-0 rounded-lg ">
              <ol className="list-none p-0 inline-flex mb-2">
                <li className="flex items-center">
                  <p className="text-blue-500 hover:text-blue-600 text-darkGreen3">
                    Vendor
                  </p>
                  <ChevronRightIcon />
                </li>
                <li className="flex items-center">
                  <p className="text-orange1">List</p>
                </li>
              </ol>
            </nav>
          </section>
          <div className="relative w-full sm:w-1/2 mb-4 sm:mb-0 ">
            <input
              type="search"
              className="block w-full px-3 py-2 mb-4 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="Search"
              value={filterText}
              onChange={handleFilter}
            />
          </div>
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DataTable
              columns={columns}
              data={sortedData}
              pagination
              striped
              responsive
              highlightOnHover
              noDataComponent={<div>No vendor found</div>}
              customStyles={CustomStyles}
            />
          )}
        </div>
      </MainLayouts>
    </div>
  );
};

export default VendorList;
